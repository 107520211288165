/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps } from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_SEN_xxx
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

export type ProductName = 'Accumulation' | 'Pension';

export interface RetrieveAnnualStatementResponse {
	data?: RetrieveAnnStmtDataType;
	meta?: Meta;
}

export interface RetrieveAnnStmtDataType {
	annual_statements?: RetrieveAnnStmtResponseDataType[];
}

export interface RetrieveAnnStmtResponseDataType {
	document_id: string;
	description: string;
	/**
	 * Format in yyyy-MM-dd.
	 */
	date?: string;
}

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Forbidden
 */
export type Response403 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Conflict
 */
export type Response409 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetAnnualStatementsQueryParams {
	/**
	 *
	 * The date at which to end the search for annual statements.This field represents the from date.
	 */
	to_date?: string;
	/**
	 *
	 * The date at which to start the search for annual statements.This field represents the from date.
	 */
	from_date?: string;
	/**
	 *
	 * The page number requested, starting from 1
	 */
	offset?: number;
	/**
	 *
	 * The number of records per page
	 */
	limit?: number;
}

export interface GetAnnualStatementsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension
	 */
	product_name: ProductName;
	/**
	 * The member account identifier in LINK
	 */
	account_number: string;
}

export type GetAnnualStatementsProps = Omit<
	GetProps<
		RetrieveAnnualStatementResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAnnualStatementsQueryParams,
		GetAnnualStatementsPathParams
	>,
	'path'
> &
	GetAnnualStatementsPathParams;

/**
 * Retrieve a collection of annual statments of an account
 */
export const GetAnnualStatements = ({
	product_name,
	account_number,
	...props
}: GetAnnualStatementsProps) => (
	<Get<
		RetrieveAnnualStatementResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAnnualStatementsQueryParams,
		GetAnnualStatementsPathParams
	>
		path={`/annual-statements/${product_name}/${account_number}`}
		{...props}
	/>
);

export type UseGetAnnualStatementsProps = Omit<
	UseGetProps<
		RetrieveAnnualStatementResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAnnualStatementsQueryParams,
		GetAnnualStatementsPathParams
	>,
	'path'
> &
	GetAnnualStatementsPathParams;

/**
 * Retrieve a collection of annual statments of an account
 */
export const useGetAnnualStatements = ({
	product_name,
	account_number,
	...props
}: UseGetAnnualStatementsProps) =>
	useGet<
		RetrieveAnnualStatementResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAnnualStatementsQueryParams,
		GetAnnualStatementsPathParams
	>(
		(paramsInPath: GetAnnualStatementsPathParams) =>
			`/annual-statements/${paramsInPath.product_name}/${paramsInPath.account_number}`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetAnnualStatementDocumentPathParams {
	/**
	 * Product type. i.e Accumulation or Pension
	 */
	product_name: ProductName;
	/**
	 * The member account identifier in LINK
	 */
	account_number: string;
	/**
	 * Identitfer of the annual statements
	 */
	id: string;
}

export type GetAnnualStatementDocumentProps = Omit<
	GetProps<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetAnnualStatementDocumentPathParams
	>,
	'path'
> &
	GetAnnualStatementDocumentPathParams;

/**
 * Retrieve the annual statements document
 */
export const GetAnnualStatementDocument = ({
	product_name,
	account_number,
	id,
	...props
}: GetAnnualStatementDocumentProps) => (
	<Get<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetAnnualStatementDocumentPathParams
	>
		path={`/documents/${product_name}/${account_number}/${id}`}
		{...props}
	/>
);

export type UseGetAnnualStatementDocumentProps = Omit<
	UseGetProps<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetAnnualStatementDocumentPathParams
	>,
	'path'
> &
	GetAnnualStatementDocumentPathParams;

/**
 * Retrieve the annual statements document
 */
export const useGetAnnualStatementDocument = ({
	product_name,
	account_number,
	id,
	...props
}: UseGetAnnualStatementDocumentProps) =>
	useGet<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetAnnualStatementDocumentPathParams
	>(
		(paramsInPath: GetAnnualStatementDocumentPathParams) =>
			`/documents/${paramsInPath.product_name}/${paramsInPath.account_number}/${paramsInPath.id}`,
		{ pathParams: { product_name, account_number, id }, ...props },
	);
