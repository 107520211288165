import React from 'react';

// This should be the ONLY file that imports the 'true' JSS Placeholder,
// as it acts as a centralised wrapper for it, with non-Sitecore rendering
// support and errorComponent/missingComponent default props
// eslint-disable-next-line no-restricted-imports
import {
	Placeholder as SitecorePlaceholder,
	isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';

import _ErrorComponent from './_ErrorComponent';
import _MissingComponent from './_MissingComponent';
import EmptyPlaceholder from './_EmptyPlaceholder';

const ErrorComponent = React.memo(_ErrorComponent);
const MissingComponent = React.memo(_MissingComponent);

interface IProps {
	rendering: any;
	name: string;
}

/**
 * Proxy for default Sitecore JSS <Placeholder/>, with custom 'error', 'missing'
 * and 'empty' rendering variants. JPlaceholders can only render in the context
 * of Sitecore - in the Experience Editor or in local "connected mode".
 *
 * This retains existing Experience Editor and "connected mode" functionality
 * (missing/error components) - but also allows "connected mode" to visualize empty
 * placeholders, and for components that contain placeholders to be rendered
 * outside of Sitecore. Note that empty placeholder visualisation is a development
 * mode feature, and thus depends on NODE_ENV === 'development'.
 */
const JssPlaceholder = (props: IProps) => {
	const { rendering, name } = props;

	// 'Sitecore powered' could refer to the Experience Editor,
	// "connected mode", or the live/preview website itself
	const isSitecorePowered = !!rendering;
	const isExperienceEditor = isExperienceEditorActive();
	const isConnectedMode =
		!isExperienceEditor && process.env.NODE_ENV === 'development';

	// Contents referring to any controls authored within it
	// Note this falls back to an empty array so we dont get length of undefined errors below
	const placeholderContents =
		(isConnectedMode && rendering?.placeholders[name]) ?? [];
	const isNotEmptyPlaceholder =
		isConnectedMode && placeholderContents.length > 0;

	const shouldShowJSSPlaceholder =
		isSitecorePowered && (!isConnectedMode || isNotEmptyPlaceholder);

	return shouldShowJSSPlaceholder ? (
		<SitecorePlaceholder
			errorComponent={ErrorComponent}
			missingComponentComponent={MissingComponent}
			{...props}
		/>
	) : (
		<EmptyPlaceholder {...{ name }} />
	);
};

export default JssPlaceholder;
