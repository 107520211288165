/* eslint-disable react/prop-types */
// TODOs:
// - change url params when page number changes if required

import React from 'react';
import template from 'lodash/template';

import * as Typo from 'core/styles/Typography';

import PaginationBar from '../Pagination';
import * as S from '../Pagination.style';

interface IPagination extends React.HTMLAttributes<any> {
	dataTarget?: string;
}

interface IStatus extends React.HTMLAttributes<any> {
	children?: React.ReactNode | React.ReactNodeArray;
	hasBorderBottom?: boolean;
	withoutPadding?: boolean;
}

/**
 * A hook for a controlled Pagination component.
 *
 * @param data 			     - an array of data that should be displayed page by page
 * @param limit					 - the maximum number of item to display per page
 * @param defaultPage 	 - the default page that is currently active
 * @param statusTemplate - a template string for the page status
 * @example 'Showing ${numOfCurrentItems} of ${totalItems}'
 */
export const usePagination = <T extends any[]>(
	data: T,
	limit = 10,
	defaultPage = 1,
	statusTemplate = 'Showing ${numOfCurrentItems} of ${totalItems}',
) => {
	const [current, setCurrent] = React.useState(defaultPage);
	// total number of pages
	const total = Math.ceil(data.length / limit);
	// offset of the items show in current page
	const offset = (current - 1) * limit;
	const currentItems = data.slice(offset, offset + limit);
	// NOTE: this string is return by the hook too for situation of having a custom Status component
	const status = template(statusTemplate)({
		numOfCurrentItems:
			// Checking if the total items left is equal to current page and current item is only 1 remaining
			total === current && currentItems.length === 1
				? data.length
				: `${1 + offset}-${
						currentItems.length === limit ? offset + limit : data.length
				  }`, //Displays Showing n of n if the current items is equal to limit
		totalItems: data.length,
	});
	const handleForward = React.useCallback(() => {
		setCurrent(current => current + 1);
	}, []);

	const handleBackward = React.useCallback(() => {
		setCurrent(current => current - 1);
	}, []);
	const isLastPage = total === current;
	/**
	 * Pagination bar component with its container.
	 */
	const Pagination = ({ dataTarget, ...restProps }: IPagination) => (
		<S.PaginationBarContainer {...restProps}>
			<PaginationBar
				{...{
					total,
					current,
					setCurrent,
					dataTarget,
					handleBackward,
					handleForward,
				}}
			/>
		</S.PaginationBarContainer>
	);

	/**
	 * Status component with its container.
	 *
	 * @note for a custom Status component, use the return `status` string to build your own.
	 */
	const Status = ({
		children,
		hasBorderBottom = true,
		withoutPadding = false,
		...restProps
	}: IStatus) => (
		<S.PaginationStatusContainer
			hasBorderBottom={hasBorderBottom}
			withoutPadding={withoutPadding}
			{...restProps}
		>
			<Typo.BodySmall margin="0" color="subdued">
				{status}
			</Typo.BodySmall>
			{children}
		</S.PaginationStatusContainer>
	);

	return { Pagination, Status, status, currentItems, isLastPage };
};
