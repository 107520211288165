/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import {
	useGetBPayReference,
	useGetTfnStatus,
} from 'core/api/client/AccountApi';

import GetBPayReferenceMock from './mocks/GetBPayReference.mock.json';

import GetTfnSuppliedStatus from './mocks/GetTfnSuppliedStatus.mock.json';

export default smartMockApi({
	useGetBPayReference: {
		live: useGetBPayReference,
		mock: params => useGetMock({ ...params, payload: GetBPayReferenceMock }),
	},
	useGetTfnStatus: {
		live: useGetTfnStatus,
		mock: params => useGetMock({ ...params, payload: GetTfnSuppliedStatus }),
	},
});
