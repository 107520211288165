import React from 'react';
import T from 'prop-types';
import Helmet from 'react-helmet';
import { VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import AppPlaceholders from 'src/_AppPlaceholders';
import AppContainer from 'src/_AppContainer';
import { getIsFirstPageLoad } from 'core/browserStorage';

import { recursiveFilterPlaceholders } from 'core/utils/sitecore-utils';
import { findAllByKey } from 'core/utils/global-utils';

import { DbAPIComponents } from 'jss-components/t06-dashboard/constants/Dashboard.const';

const Layout = ({ route, context: sitecoreContext }) => {
	const { fields } = route;
	route.placeholders = recursiveFilterPlaceholders(route.placeholders);
	const [
		dbIntersectionComponents,
		setDbIntersectionComponents,
	] = React.useState([]);

	React.useEffect(() => {
		if (route.name === 'Home' && getIsFirstPageLoad() === '') {
			if (!!route?.placeholders[`jss-main`][0]?.placeholders) {
				const componentNameArray = findAllByKey(
					route?.placeholders[`jss-main`][0]?.placeholders,
					'componentName',
				);
				const uniqueComponentNameArray = [...new Set(componentNameArray)];
				const dbAPIComponents = Object.values(DbAPIComponents);
				const intersection = dbAPIComponents.filter(elem =>
					uniqueComponentNameArray.includes(elem),
				);
				setDbIntersectionComponents(intersection);
			}
		}
	}, [route]);

	return (
		<AppContainer
			{...{
				sitecoreContext,
				routeFields: route.fields,
				dbIntersectionComponents,
			}}
		>
			{/* React-helmet enables setting <head> contents, like title and OG meta tags */}
			<Helmet>
				<title>
					{fields?.navigationTitle?.value ??
						fields?.pageTitle?.value ??
						'Australian Super'}
				</title>
			</Helmet>

			{/*
				VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
				If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
				For XM (CMS-only) apps, this should be removed.

				VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
			*/}
			{/* TODO - do we need this...? Currently errors */}
			{/* <VisitorIdentification /> */}

			<AppPlaceholders rendering={route} />
		</AppContainer>
	);
};

Layout.propTypes = {
	context: T.object.isRequired,
	route: T.object.isRequired,
};

export default Layout;
