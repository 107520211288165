/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/camelcase */
import { useGetMock, usePostMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import {
	useGetSens,
	useUpdateForm,
	useGetSenDocument,
} from 'core/api/client/SignificantEventNoticeApi';

import GetSensMock from './mocks/GetSens.mock.json';

export default smartMockApi({
	useGetSens: {
		live: useGetSens,
		mock: () => useGetMock({ payload: GetSensMock }),
	},
	useUpdateForm: {
		live: useUpdateForm,
		mock: () =>
			usePostMock({
				payload: {
					data: {
						is_read: true,
					},
				},
			}),
	},
	useGetSenDocument: {
		live: useGetSenDocument,
		mock: () => useGetMock({ payload: GetSensMock }),
	},
});
