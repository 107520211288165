import {
	ApplicationInsights,
	IPageViewPerformanceTelemetry,
	IExceptionTelemetry,
	IEventTelemetry,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import config from 'jss-boilerplate/temp/config';

class AppInsights {
	reactPlugin: any;
	enabled: boolean;
	appInsights?: ApplicationInsights;

	constructor() {
		this.enabled = false;
		this.reactPlugin = new ReactPlugin();
	}

	/**
	 * Initialize App Insights
	 */
	initialize(reactPluginConfig: any) {
		const instrumentationKey =
			config.externalServices.azureAppInsightsInstrumentationKey;

		// disable app insights if key is not provided
		if (!instrumentationKey || instrumentationKey.length === 0) {
			this.enabled = false;

			return;
		}

		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey,
				disableFetchTracking: false,
				extensions: [this.reactPlugin],
				extensionConfig: {
					[this.reactPlugin.identifier]: reactPluginConfig,
				},
			},
		});

		// NOTE: temporarily remove this loadstate until AppInsight issue will be resolve.
		// this.appInsights.loadAppInsights();
		this.enabled = true;
	}

	/**
	 * Log a bag of performance information via the customProperties field.
	 */
	trackPageViewPerformance(pageViewPerformance: IPageViewPerformanceTelemetry) {
		if (this.enabled && this.appInsights) {
			this.appInsights.trackPageViewPerformance(pageViewPerformance);
		}
	}

	/**
	 * Log a user action or other occurrence.
	 */
	trackEvent(event: IEventTelemetry, customProperties: object) {
		if (this.enabled && this.appInsights) {
			this.appInsights.trackEvent(event, customProperties);
		}
	}

	/**
	 * Log an exception that you have caught, as a handled exception
	 */
	trackException(exception: IExceptionTelemetry) {
		if (this.enabled && this.appInsights) {
			const handledException = {
				...exception,
				properties: { ...exception.properties, handled: true },
			};
			this.appInsights.trackException(handledException);
		}
	}
}

export const AI = new AppInsights();
