/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VALIDATOR_xxx
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

export interface ValidatorAddressResponse {
	data?: ValidatorAddressDataType;
	meta?: Meta;
}

export interface ValidatorPhoneResponse {
	data?: ValidatorPhoneDataType;
	meta?: Meta;
}

export interface ValidatorAddressDataType {
	addresses?: RetrieveAddressResponseDataType[];
}

export interface ValidatorPhoneDataType {
	phones?: RetrievePhoneResponseDataType[];
}

export interface RetrievePhoneResponseDataType {
	phone_number: string;
	country: string;
	area_code: string;
	local_number: string;
	operator_name?: string;
	status?: string;
}

export interface RetrieveAddressResponseDataType {
	full_address: string;
	address: AddressDataType;
}

export interface AddressDataType {
	postal?: string;
	building?: string;
	subdwelling_name?: string;
	unit_number?: string;
	unit_type?: string;
	floor_level_number?: string;
	floor_level_type?: string;
	lot?: string;
	street_number?: string;
	street_name?: string;
	street_type?: string;
	street_suffix?: string;
	suburb?: string;
	state?: string;
	postcode?: string;
	country?: string;
	barcode?: string;
	bsp?: string;
	dpid?: string;
	postal_delivery_type?: string;
	postal_service_number?: string;
}

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Forbidden
 */
export type Response403 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Conflict
 */
export type Response409 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetAddressQueryParams {
	/**
	 * full address query parameter
	 */
	full_address?: string;
}

export type GetAddressProps = Omit<
	GetProps<
		ValidatorAddressResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAddressQueryParams,
		void
	>,
	'path'
>;

/**
 * This endpoint will be used for retrieving list of Australian addresses based on a possible match for a supplied (partial) address.
 */
export const GetAddress = (props: GetAddressProps) => (
	<Get<
		ValidatorAddressResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAddressQueryParams,
		void
	>
		path={`/validators/address-lookup`}
		{...props}
	/>
);

export type UseGetAddressProps = Omit<
	UseGetProps<
		ValidatorAddressResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAddressQueryParams,
		void
	>,
	'path'
>;

/**
 * This endpoint will be used for retrieving list of Australian addresses based on a possible match for a supplied (partial) address.
 */
export const useGetAddress = (props: UseGetAddressProps) =>
	useGet<
		ValidatorAddressResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetAddressQueryParams,
		void
	>(`/validators/address-lookup`, props);

export type PostAddressProps = Omit<
	MutateProps<
		void,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void,
		void
	>,
	'path' | 'verb'
>;

/**
 * This endpoint will be used to indicate to Mastersoft system that address-lookup transaction is complete.
 */
export const PostAddress = (props: PostAddressProps) => (
	<Mutate<
		void,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void,
		void
	>
		verb="POST"
		path={`/validators/address-lookup/completed`}
		{...props}
	/>
);

export type UsePostAddressProps = Omit<
	UseMutateProps<
		void,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void,
		void
	>,
	'path' | 'verb'
>;

/**
 * This endpoint will be used to indicate to Mastersoft system that address-lookup transaction is complete.
 */
export const usePostAddress = (props: UsePostAddressProps) =>
	useMutate<
		void,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void,
		void
	>('POST', `/validators/address-lookup/completed`, props);

export interface GetParsedAddressQueryParams {
	/**
	 * full address query parameter
	 */
	full_address?: string;
}

export type GetParsedAddressProps = Omit<
	GetProps<
		ValidatorAddressResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		GetParsedAddressQueryParams,
		void
	>,
	'path'
>;

/**
 * This endpoint will be used for parsing an unstructured address in standard format.
 */
export const GetParsedAddress = (props: GetParsedAddressProps) => (
	<Get<
		ValidatorAddressResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		GetParsedAddressQueryParams,
		void
	>
		path={`/validators/address-parser`}
		{...props}
	/>
);

export type UseGetParsedAddressProps = Omit<
	UseGetProps<
		ValidatorAddressResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		GetParsedAddressQueryParams,
		void
	>,
	'path'
>;

/**
 * This endpoint will be used for parsing an unstructured address in standard format.
 */
export const useGetParsedAddress = (props: UseGetParsedAddressProps) =>
	useGet<
		ValidatorAddressResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		GetParsedAddressQueryParams,
		void
	>(`/validators/address-parser`, props);
