import React from 'react';

import { MemberContext, fallbackMember } from 'core/sitecore/member';
import { Member } from 'core/api/client/MemberApi';

/**
 * Detects whether the current environment is within Storybook,
 * based on custom node environment variable set at both run/build time.
 */
export const isStorybook = (): boolean => !!process.env.STORYBOOK_ACTIVE;

interface IMemberContextProvider extends Partial<Member> {
	children: React.ReactNode | React.ReactNodeArray;
}

/**
 * A mock member context to provide component access to member object in storybook.
 *   Member object is default to the 'ashley' mock data, but can be override.
 */
export const MemberContextProvider = ({
	children,
	...restProps
}: IMemberContextProvider) => {
	const member = { ...fallbackMember, ...restProps };

	return (
		<MemberContext.Provider value={member}>{children}</MemberContext.Provider>
	);
};
