import moment from 'moment';
import {
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from './localStorage';

const annualStatementNotificationKeyName = 'AnnualStatementNotificationToken';

export type AnnualStatementNotification = {
	id: string;
	accessToken?: string;
	isDownLoaded?: boolean;
	isDismissed?: boolean;
	publishEndDate?: string;
	expired?: boolean;
};

export const AnnualStatementNotificationStore = {
	get(serverDateTime: Date): AnnualStatementNotification[] {
		const item = getLocalStorageItem(annualStatementNotificationKeyName);

		if (item) {
			try {
				// This will cleanup the past dates versus the current date to avoid storing a lot of data in localStorage.
				// Will check for past dates and tag it as expired.
				const itemArray = JSON.parse(item)?.map(
					(i: AnnualStatementNotification) => ({
						...i,
						expired: moment(i.publishEndDate).isBefore(
							moment(new Date(serverDateTime)),
						),
					}),
				);
				// Filter those data which are not yet expired.
				const cleanUpArray =
					itemArray.filter(
						(c: AnnualStatementNotification) => c.expired === false,
					) ?? [];

				// Update localStorage
				setLocalStorageItem(
					annualStatementNotificationKeyName,
					JSON.stringify(cleanUpArray),
				);

				return cleanUpArray;
			} catch (error) {
				return [];
			}
		}

		return [];
	},

	set(annualStatementNotification: AnnualStatementNotification) {
		const item = getLocalStorageItem(annualStatementNotificationKeyName);
		const notifArray = [];

		if (item) {
			notifArray.push(...item, annualStatementNotification);
		} else {
			notifArray.push(annualStatementNotification);
		}

		setLocalStorageItem(
			annualStatementNotificationKeyName,
			JSON.stringify(notifArray),
		);
	},

	remove() {
		removeLocalStorageItem(annualStatementNotificationKeyName);
	},
};
