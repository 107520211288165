import { LOGOUT_STATUS } from 'core/constants';

import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

type TLogoutStatus = typeof LOGOUT_STATUS;
type KLogoutStatus = keyof TLogoutStatus;
type LogoutTypes = TLogoutStatus[KLogoutStatus];

const displayLogoutSuccessFlagKeyName = 'DisplayLogoutSuccessFlag';

export const getDisplayLogoutSuccessFlag = (): string => {
	const accessToken = getLocalStorageItem(displayLogoutSuccessFlagKeyName);

	return accessToken;
};

export const setDisplayLogoutSuccessFlag = (tokenValue: LogoutTypes) => {
	setLocalStorageItem(displayLogoutSuccessFlagKeyName, tokenValue);
};

export const removeDisplayLogoutSuccessFlag = () => {
	removeLocalStorageItem(displayLogoutSuccessFlagKeyName);
};
