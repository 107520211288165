import React from 'react';
import { withTheme } from 'styled-components';

import { IThemeObject } from 'pods/theme';
import LayoutPadding from 'core/components/LayoutPadding';
import {
	JssLinkType,
	JssImageType,
	getCleanImageSrc,
} from 'core/utils/sitecore-utils';

import * as S from './UnauthenticatedHeader.style';
import CONTENT from '../../Header.content';

interface IProps {
	theme: IThemeObject;
	logoUrl?: JssLinkType;
	logoSrc?: JssImageType;
}

const UnauthenticatedHeader = ({ logoUrl, logoSrc }: IProps) => (
	<S.Wrapper data-content-id="header--unauthenticated">
		<LayoutPadding>
			{(logoSrc && (
				<S.Content>
					<a href={logoUrl?.value?.href}>
						<img
							src={getCleanImageSrc(logoSrc?.value?.src)}
							alt={CONTENT.LOGO_ALT}
						/>
					</a>
				</S.Content>
			)) || <></>}
		</LayoutPadding>
	</S.Wrapper>
);

export default withTheme(UnauthenticatedHeader);
