export const LIVE_ASSIST_BUTTON_TEXT = 'Live assist';

// TBD
export enum LIVE_PERSON_MODAL_FORM {
	PLACEHOLDER = 'Service number',
	REQUIRED = 'This field is required',
	ERROR_MESSAGE = 'The service number is invalid',
	DESCRIPTION = 'Start a co-browse session with one of our customer service officers.',
	HEADING = 'Live Assist',
	LOADING_TEXT = 'Requesting co-browse session...',
}
