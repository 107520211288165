import React from 'react';

// ------------------------------------

export const FONT_NAMES = {
	GOTHAM_ROUNDED_MEDIUM:
		'GothamRoundedMedium, GothamRoundedBook, GothamRoundedLight, sans-serif',
	GOTHAM_ROUNDED_BOOKED:
		'GothamRoundedBook, GothamRoundedLight, GothamRoundedMedium, sans-serif',
	GOTHAM_ROUNDED_LIGHT:
		'GothamRoundedLight, GothamRoundedBook, GothamRoundedMedium, sans-serif',
	GOTHAM: 'Gotham Rounded A, Gotham Rounded B, sans-serif',
	OPEN_SANS: 'Open Sans, sans-serif',
};

export const FONT_WEIGHT = {
	// --------------------
	// Gotham Rounded
	LIGHT: 300,
	SEMI_BOOK: 350,
	BOOK: 400,

	// --------------------
	// Open Sans
	MEDIUM: 500,
	REGULAR: 400,
	SEMI_BOLD: 600,
};

// ------------------------------------

const Fonts = () => (
	<>
		{/* Gotham Rounded */}
		<link rel="preconnect" href="https://cdn.fonts.net" />
		<link
			href="https://cdn.fonts.net/kit/7d40249c-2337-4db3-bcdc-642f03938aed/7d40249c-2337-4db3-bcdc-642f03938aed.css"
			rel="stylesheet"
		/>

		{/* Open Sans */}
		<link
			href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i&display=swap"
			rel="stylesheet"
		/>
	</>
);

export default Fonts;
