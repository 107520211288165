import styled from 'styled-components';

export const Wrapper = styled.div`
	margin: 176px 0;
`;

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: column wrap;
	margin: 60px 0;

	> * {
		margin: 12px 0;
	}

	img {
		width: 40px;
		height: 40px;
	}

	animation: fadeIn ease 1s;
	@-webkit-animation: fadeIn ease 1s;
	@-moz-animation: fadeIn ease 1s;
	@-o-animation: fadeIn ease 1s;
	@-ms-animation: fadeIn ease 1s;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-moz-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-webkit-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-o-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-ms-keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
`;
