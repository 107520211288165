import React from 'react';

export const useSetStates = <T extends {}>(
	initialState: T = {} as T,
): [T, (newState: Partial<T>) => void] => {
	const [state, _setState] = React.useState(initialState);

	const setState = React.useCallback((newState: Partial<T>) => {
		_setState(prevState => Object.assign({}, prevState, newState));
	}, []);

	return [state, setState];
};
