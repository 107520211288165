/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import config from 'jss-boilerplate/temp/config.js';

import { useMemberContext } from 'core/hooks';
import { JssTextType } from 'core/utils/sitecore-utils';
import { getSelectedMemberAccount } from 'core/sitecore/member';
import { ProductNameValues } from 'core/api/client/AccountApi';

import api from './api';

const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathAccount}`;

export const useGetBPAYInfo = (
	noTfnMessage: JssTextType,
	over75Message: JssTextType,
	sitecoreErrMessage?: string,
) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [hasModuleError, setHasModuleError] = React.useState(false);

	const { age, accounts = [] } = useMemberContext();

	const selectedAccount = getSelectedMemberAccount(accounts);
	const accountNumber = selectedAccount?.account_number ?? '';
	const productName = selectedAccount?.product_name ?? '';

	const {
		data: apiDataTfnStatus,
		error: apiErrorTfnStatus,
		loading: apiLoadingTfnStatus,
		refetch: apiRefetchTfnStatus,
	} = api.useGetTfnStatus({
		base,
		account_number: accountNumber as string,
		product_name: productName as ProductNameValues,
	});

	const {
		data: apiDataBPAY,
		error: apiErrorBPAY,
		loading: apiLoadingBPAY,
		refetch: apiRefetchBPAY,
	} = api.useGetBPayReference({
		base,
		account_number: accountNumber as string,
		product_name: productName as ProductNameValues,
		queryParams: {
			contribution_code: 'MEMBER',
		},
	});

	/**
	 * Refetch callback to only refetch failed requests.
	 */
	const refetch = React.useCallback(() => {
		apiErrorTfnStatus && apiRefetchTfnStatus();
		apiErrorBPAY && apiRefetchBPAY();
	}, [apiErrorTfnStatus, apiErrorBPAY, apiRefetchBPAY, apiRefetchTfnStatus]);

	// --------------------------------------------------
	// Hook states

	React.useEffect(() => {
		setIsLoading(apiLoadingTfnStatus || apiLoadingBPAY);
	}, [apiLoadingTfnStatus, apiLoadingBPAY]);

	React.useEffect(() => {
		setHasModuleError(!!apiErrorTfnStatus || !!apiErrorBPAY);
	}, [apiErrorTfnStatus, apiErrorBPAY]);

	const bPAYInfo = () => {
		if (!isLoading && !hasModuleError && apiDataTfnStatus) {
			const tfn_status = apiDataTfnStatus.data?.tfn_status;
			const noTfn = tfn_status !== 'SUPPLIED';
			const over75 = Number(age) >= 75;
			const hasMessage = over75 || noTfn;

			if (hasMessage) {
				return {
					// NOTE: always prioritize the over 75 message than the ano tfn message.
					type: over75 ? 'error' : 'warning',
					message: over75 ? over75Message : noTfnMessage,
					showBPAYInfo: false,
					noTfn,
				};
			}
		}
		// show BPAY info when there is no any message

		return { showBPAYInfo: true };
	};

	const { showBPAYInfo, type, message, noTfn } = bPAYInfo();

	return {
		showBPAYInfo,
		type,
		message,
		dataBPAY: apiDataBPAY?.data,
		loading: isLoading,
		error: apiErrorTfnStatus ?? apiErrorBPAY,
		errorMessage:
			sitecoreErrMessage ?? apiErrorTfnStatus?.message ?? apiErrorBPAY?.message,
		refetch,
		noTfn,
	};
};
