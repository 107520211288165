import styled, { css } from 'styled-components';

import * as mx from 'core/styles/mixins';
import { JustifyContent } from './Modal';

export const Wrapper = styled.section`
	min-width: 250px;
`;

export const BtnWrapper = styled.button`
	${mx.buttonReset};
	${mx.tabFocus};
	position: absolute;
	top: 25px;
	right: 25px;

	padding: 10;
	z-index: 1;
	cursor: pointer;
`;

export const ModalContentWrapper = styled.div<{
	withPadding?: boolean;
}>`
	${({ withPadding }) =>
		withPadding &&
		css`
			padding: 58px 40px 40px;
		`}
`;

export const FooterContainer = styled.div<{
	footerJustifyContent?: JustifyContent;
}>`
	border-top: 1px solid ${({ theme }) => theme.global.borders.module};
	padding: 21px 40px;
	display: flex;
	justify-content: ${({ footerJustifyContent }) =>
		footerJustifyContent ? footerJustifyContent : 'space-between'};

	${({ footerJustifyContent }) =>
		footerJustifyContent === 'flex-start' &&
		css`
			> div:first-child {
				margin-right: 10px;
			}
		`}
`;
