// TODO
// - hyperlink styles; more RTE examples in Storybook (bold, underline, strikethrough, hyperlinks, class driven CTAs (hyperlinks)
// - vanilla tables
// - Theme compatibility with `color` prop
// - JSS theme compatibility with `color` prop too
// - better way to consolidate typography class/style/name definitions? Between .style.ts and .tsx files? Separate schema file?
// - JSS story example(s) for Caption is a little off

import React from 'react';

import cx from 'classnames';
import styled, {
	FlattenSimpleInterpolation as CSS,
	StyledComponent,
	withTheme,
} from 'styled-components';

import RichTextParser from 'core/components/RichText';
import { isJssField, JssTextType } from 'core/utils/sitecore-utils';
import { IThemeObject } from 'pods/theme';
import { TypographyColorType } from 'pods/theme/local';
/* eslint-disable-next-line no-restricted-imports */
import {
	RichText as JssRichText,
	Text,
} from '@sitecore-jss/sitecore-jss-react';

import { FONT_WEIGHT } from '../fonts';
import * as S from './Typography.style';

// ------------------------------------
// JSS

interface IProps extends Omit<React.HTMLAttributes<HTMLElement>, 'style'> {
	// ---------------------
	// JSS related props

	/* Proxy for the `field` prop on JSS <Text/> component */
	jss?: JssTextType;
	isRichText?: boolean;

	// ---------------------
	// Generaly available (e.g. HOC/inherent); not explicitly passed

	theme: IThemeObject;
	/* Children only passed through if not a JSS component. */
	children?: string | React.ReactNode;

	// ---------------------
	// These two props are normalised; `tag` prop takes precedent

	/* Semantic tag prop override - Styled Components convention.
	(This is used by to set defaults and pass down to the underlying Styled Component.) */
	as?: string;
	/* Semantic tag prop override - JSS convention.
	(This is used to retain semantic tag overrides at the Typography component export level.) */
	tag?: string;

	// ---------------------
	// CSS related props

	/* Theme-driven typography CSS color. Note the 'default' is set in GlobalStyles. */
	color?: TypographyColorType;
	/* Margin CSS string. */
	margin?: string;
	/* CSS `display: inline-block`. */
	inline?: boolean;
	/* Text Alignment */
	textAlign?:
		| 'center'
		| 'end'
		| 'justify'
		| 'left'
		| 'match-parent'
		| 'right'
		| 'start';
	/* CSS `font-weight: bold. */
	isBold?: boolean;
	/* CSS string template to pass into Styled Component. */
	style?: CSS;

	// ---------------------
	// Custom Typo component (BYO StyledComponent)
	StyledComponent?: StyledComponent<any, any, {}, never>;

	// ---------------------
	// Other props pertaining to attributes applied to the final tag

	/* CSS className selector as means to apply styles to JSS text field. */
	classNameIdentifier: string;
	/* Generic className prop passthrough */
	className?: string;
}

/**
 * Dynamically returns either a JSS <Text/> field for the Experience Editor
 * compatibility, or a styled component for standard/default rendering.
 * (Notably these will be visually indistinguishable).
 */
const DynamicTypographyComponent = withTheme(
	({
		// DynamicTypographyComponent proxy component
		as: asProp,
		style,
		className,
		classNameIdentifier,

		// ITypoProps
		jss: field,
		isRichText,
		tag: tagProp,
		color,
		margin,
		textAlign,
		inline,
		isBold,
		children,

		// 'Custom' Typo component
		StyledComponent,

		// HOC
		theme,

		...props
	}: IProps) => {
		// Rich text tag wrappers should be divs
		const tag = isRichText ? 'div' : tagProp ?? asProp;
		const JssTag = isRichText ? JssRichText : Text;
		// 'div' by default but always overriden by `tag` prop, below
		const DefaultTypography =
			StyledComponent ??
			styled.div`
				${style}
			`;

		className = cx(
			className,
			`${S.TYPOGRAPHY_CLASS_PREFIX}${classNameIdentifier}`,
		);

		const inlineStyles = {
			color: !!color ? theme.global.typography.colors[color] : undefined,
			display: inline ? 'inline-block' : 'block',
			fontWeight: isBold ? FONT_WEIGHT.SEMI_BOLD : undefined,
			margin,
			textAlign,
		};

		return !!field && isJssField(field) ? (
			<DefaultTypography
				style={inlineStyles}
				as={tag as any}
				{...{ className, ...props }}
			>
				{/* Enforce span tag here, as semantically the correct/intended tag is applied above */}
				<JssTag tag="span" {...{ field }} />
			</DefaultTypography>
		) : (
			<DefaultTypography
				as={tag as any}
				style={inlineStyles}
				{...{ className, ...props }}
			>
				{isRichText ? (
					<RichTextParser content={children as string} />
				) : (
					children
				)}
			</DefaultTypography>
		);
	},
);

// ------------------------------------
// Core typography exports

export interface ITypoProps
	extends Omit<React.HTMLAttributes<HTMLElement>, 'style' | 'color'>,
		Partial<
			Pick<IProps, 'color' | 'margin' | 'inline' | 'textAlign' | 'isBold'>
		> {
	jss?: JssTextType;
	isRichText?: boolean;
	tag?: string;
	className?: string;
	children?: string | React.ReactNode;
	as?: string;
}

export const DisplayXLarge = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h1"
		classNameIdentifier="displayXLarge"
		style={S.displayXLarge}
		{...props}
	/>
);

export const DisplayLarge = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h2"
		classNameIdentifier="displayLarge"
		style={S.displayLarge}
		{...props}
	/>
);

export const DisplayMedium = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h2"
		classNameIdentifier="displayMedium"
		style={S.displayMedium}
		{...props}
	/>
);

export const DisplayMediumLight = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="displayMediumLight"
		style={S.displayMediumLight}
		{...props}
	/>
);

export const DisplaySmall = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h4"
		classNameIdentifier="displaySmall"
		style={S.displaySmall}
		{...props}
	/>
);
export const HeadingXLarge = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h1"
		classNameIdentifier="headingXLarge"
		style={S.headingXLarge}
		{...props}
	/>
);
export const HeadingLarge = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h2"
		classNameIdentifier="headingLarge"
		style={S.headingLarge}
		{...props}
	/>
);

export const HeadingSmall = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="headingSmall"
		style={S.headingSmall}
		{...props}
	/>
);
export const Heading = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="heading"
		style={S.heading}
		{...props}
	/>
);

export const SubHeading = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="subHeading"
		style={S.subHeading}
		{...props}
	/>
);

export const SubHeadingMedium = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="subHeadingMedium"
		style={S.heading}
		{...props}
	/>
);

export const SubHeadingSmall = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="h3"
		classNameIdentifier="subHeadingSmall"
		style={S.subHeadingSmall}
		{...props}
	/>
);

export const BodyMedium = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="p"
		classNameIdentifier="bodyMedium"
		style={S.bodyMedium}
		{...props}
	/>
);

export const Body = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="p"
		classNameIdentifier="body"
		style={S.body}
		{...props}
	/>
);

export const BodySmall = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="p"
		classNameIdentifier="bodySmall"
		style={S.bodySmall}
		{...props}
	/>
);

export const Caption = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="caption"
		classNameIdentifier="caption"
		style={S.caption}
		{...props}
	/>
);

export const Footnote = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="caption"
		classNameIdentifier="caption"
		style={S.footnote}
		{...props}
	/>
);

export const Inline = (props: ITypoProps) => (
	<DynamicTypographyComponent
		as="span"
		classNameIdentifier="span"
		style={S.inline}
		{...props}
	/>
);

// -------------------------------------------

// Dont allow them to override `tag` prop, as RichText should define
// its own tags (being contained in a wrapper <div/>) as specified here
export const RichText = (props: Omit<ITypoProps, 'tag' | 'isRichText'>) => (
	<DynamicTypographyComponent
		as="div"
		isRichText
		classNameIdentifier="richText"
		margin={S.UNIFORM_MARGIN}
		{...props}
	/>
);

// -------------------------------------------

// BYO StyledComponent
export const Custom = ({
	classNameIdentifier,
	...props
}: Omit<ITypoProps, 'StyledComponent'> & {
	/** Required: BYO StyledComponent for custom styles. */
	StyledComponent?: StyledComponent<any, any, {}, never>;
	/** Require a manually specified identifier, as not all Custom Typo components will be equal. */
	classNameIdentifier: string;
	/** CSS style to pass to styled-component */
	style?: CSS;
}) => (
	<DynamicTypographyComponent
		classNameIdentifier={`custom__${classNameIdentifier}`}
		{...props}
	/>
);
