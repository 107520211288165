import { PhoneNumber } from 'core/api/client/MemberApi';

/**
 * Format member's phone number
 *
 * @description Format phone number based on the following format rules:
 * Local: <area code> 9876 9876
 * International: +<country code> <area code> 56785678
 * Standard: +<country code> 432 432 4321
 *
 * @param phone - One of member's phone numbers from MemberContext
 * @param phoneType - phone type values m - mobile, h - home, w - work
 */
export const getFormattedPhoneNumber = (
	phone?: PhoneNumber,
	phoneType = 'm',
): string => {
	if (!phone?.number) {
		return '';
	}

	const phoneNumberStrip = (phone?.number).replace(/\s+/g, '');
	const numericPhoneNumber = phoneNumberStrip.replace(/[+]/g, '');
	const phoneNumberLength = numericPhoneNumber.length;

	const hasPlusSign = phoneNumberStrip.substring(0, 1);
	// To verify if it is equal to 61
	const firstTwoDigits = numericPhoneNumber.substring(0, 2);
	let formattedNumber;

	// If number has plus sign, it is in Standard format already.
	if (hasPlusSign === '+') {
		return phone?.number;
	}

	if (phoneNumberLength === 10 && phoneType === 'm') {
		// Mobile number having 10 digits : XXXX XXX XXX
		const match = phoneNumberStrip.match(/(\S{0,4})(\S{0,3})(\S{0,3})/);
		formattedNumber = `+${match?.[1] ?? ''} ${match?.[2] ?? ''} ${match?.[3] ??
			''}`.trim();
	} else if (phoneNumberLength === 10 && phoneType !== 'm') {
		// Home/Work number having 10 digits : XX XXXX XXXX
		const match = phoneNumberStrip.match(/(\S{0,2})(\S{0,4})(\S{0,4})/);
		formattedNumber = `+${match?.[1] ?? ''} ${match?.[2] ?? ''} ${match?.[3] ??
			''}`.trim();
	} else if (phoneNumberLength === 11 && firstTwoDigits === '61') {
		// Mobile/Home/Work number having 11 digits : XX XXX XXX XXX
		const match = phoneNumberStrip.match(
			/(\S{0,2})(\S{0,3})(\S{0,3})(\S{0,3})/,
		);
		formattedNumber = `+${match?.[1] ?? ''} ${match?.[2] ?? ''} ${match?.[3] ??
			''} ${match?.[4] ?? ''}`.trim();
	} else if (phoneNumberLength === 12 && firstTwoDigits === '61') {
		// Mobile/Home/Work number having 12 digits : XX XX XXXX XXXX
		const match = phoneNumberStrip.match(
			/(\S{0,2})(\S{0,2})(\S{0,4})(\S{0,4})/,
		);
		formattedNumber = `+${match?.[1] ?? ''} ${match?.[2] ?? ''} ${match?.[3] ??
			''} ${match?.[4] ?? ''}`.trim();
	} else {
		// Just return whatever the LINK gives.
		formattedNumber = phone?.number;
	}

	return formattedNumber;
};
