/**
 * TODO: Update all property values and use the global variables
 * Also add reusable values to global
 * e.g font-weight/font-size
 */
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FONT_NAMES, FONT_WEIGHT } from 'core/styles/fonts';
import * as vr from 'core/styles/variables';

export const BaseContainer = styled.div`
	min-height: 200px;
	border-radius: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: 'Arial';
	font-size: ${vr.componentErrorTitleFontSize};
	color: #4a4a4a;
	text-align: center;
	padding: 0 20px;
	border: 1px solid;
	border-color: inherit;
`;

const BaseText = styled.p`
	width: 100%;
	max-width: 500px;
	color: inherit;
	padding: 0 10px;
`;

export const Wrapper = styled.div<{
	color: string;
}>`
	width: 100%;
	padding: 10px;

	${({ color }) => css`
		border: 1px dashed ${color};
	`}
`;

export const Title = styled(BaseText)`
	font-size: 24px;
	margin: 0;
	line-height: 1.2;

	strong {
		font-weight: 500;
		margin-bottom: 10px;
	}

	span {
		font-size: 20px;
	}
`;

export const Description = styled(BaseText)`
	font-size: 18px;
	margin: 30px auto 0;
`;

export const EmptyPlaceholder = styled(BaseContainer)`
	border-width: 2px;
	border-style: dashed;
	background-color: #e7e7e7;
	padding: 2px;
	background-clip: padding-box;
`;

export const ErrorComponentWrapper = styled(BaseContainer)`
	background-color: ${vr.componentErrortBackground};
	color: ${vr.brandBlack};
	border-color: ${vr.componentErrortBackground};
`;

export const ErrorComponent = styled.div`
	max-width: 375px;
`;

export const MissingComponent = styled(BaseContainer)`
	background-color: gold;
	color: brown;
`;

export const UnauthoredComponent = styled(BaseContainer)`
	background-color: ${rgba('cornflowerblue', 0.75)};
	color: darkblue;
`;

export const ErrorTitle = styled.h5`
	font-size: ${vr.componentErrorTitleFontSize};
	line-height: 28px;
	font-weight: ${FONT_WEIGHT.SEMI_BOOK};
	margin-bottom: 12px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_MEDIUM};
`;

export const ErrorDescription = styled.p`
	font-weight: ${FONT_WEIGHT.BOOK};
	font-size: ${vr.formfieldFontSize};
	line-height: 24px;
	margin: 0;
	font-family: ${FONT_NAMES.OPEN_SANS};
`;
