import styled from 'styled-components';

import * as mx from 'core/styles/mixins';
import * as vr from 'core/styles/variables';

let Content = styled.div`
	width: ${vr.mobileLayoutWidth};
	padding: 0 ${vr.mobileLayoutPadding};
	margin: 0 auto;

	${mx.bp('m')`
		padding: 0 ${vr.desktopLayoutPadding};
		max-width: ${vr.desktopLayoutWidth};
	`}
`;

// If layout paddings are ever somehow nested,
// the padding shouldn't double up (potential edge case)
Content = styled(Content)`
	${Content} {
		padding: 0;
	}
`;

// Can't export a mutable 'let' binding; so assign to a new const
export const Padding = Content;
