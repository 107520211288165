/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';

import { useGetBsb } from 'core/api/client/ReferenceDataApi';

import GetBsb from './mocks/GetBsb.mock.json';

export default smartMockApi({
	// BSB validation
	useGetBsb: {
		live: useGetBsb,
		mock: () => useGetMock({ payload: GetBsb }),
	},
});
