export enum PLAN_CODE {
	ACCUMULATION = 'BF',
	PENSION = 'BG',
}
export enum PRODUCT_CLASSIFICATION {
	ACCUMULATION = 'Accumulation',
	TTR = 'TTR',
	PENSION = 'Pension',
	CI = 'Choice Income',
}

export enum TFN_STATUS {
	SUPPLIED = 'SUPPLIED',
	NOT_SUPPLIED = 'NOT_SUPPLIED',
}

export enum TFN_STATUS_READABLE {
	SUPPLIED = 'Supplied',
	NOT_SUPPLIED = 'Not supplied',
}
