/* eslint-disable @typescript-eslint/camelcase */

import { ITrackMemberProps } from 'core/analytics';
import { Member } from 'core/interface/MemberAccount';
import { getSelectedMemberAccount } from './getSelectedMemberAccount';

/**
 * Get member info for GTM tracking purposes
 *
 * @description Return ITrackMemberProps object to include in GTM tracking
 * @param member - current member object from MemberContext
 */
export const getMemberTrackingProps = (member?: Member): ITrackMemberProps => {
	let memberData: ITrackMemberProps = {};

	if (!member) {
		return memberData;
	}

	const selectedAccount = getSelectedMemberAccount(member.accounts);

	memberData = {
		party_type_id: member.party_type_id,
		member_status: member.member_status?.toLowerCase(),
		user_phone_number: member.phone_numbers,
		user_email_address: member.email_address,
		accounts_account_number: selectedAccount?.account_number ?? '',
		accounts_product_name: selectedAccount?.product_name ?? '',
		accounts_account_status: selectedAccount?.account_status ?? '',
		accounts_joined_date: selectedAccount?.joined_date ?? '',
		classification_id: member?.classification_id ?? undefined,
		life_cycle_profile: member?.lifeCycleProfile ?? undefined,
	};

	return memberData;
};
