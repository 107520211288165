/* eslint-disable @typescript-eslint/camelcase */

import { useEffect, useState } from 'react';
import moment from 'moment';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import config from 'jss-boilerplate/temp/config.js';
import { useMemberContext, useAppContext } from 'core/hooks';
import {
	RetrieveAnnStmtResponseDataType,
	ProductName,
} from 'core/api/client/AnnualStatementsApi';
import {
	AnnualStatementNotificationStore,
	getAccessToken,
} from 'core/browserStorage';
import { doRefreshToken } from 'core/utils/api-utils';
import { getSelectedMemberAccount } from 'core/sitecore/member';
import { AKAMAI_BOT_FLAG, X_CORRELATION_APP } from 'core/constants';
import { getFormattedCurrentFY } from 'jss-components/t06-dashboard/helpers/get-financial-year';
import { isStorybook } from 'stories/utils';

import api from './api';

const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathDocumentAnnualStatement}`;

/**
 * Hook that's responsible for preparing and managing API data for AnnualStatements Component
 */
export function useAnnualStatements(publishEndDate: string, id?: string) {
	// TODO: This might be impacted when account switcher integration is done.
	const {
		sitecoreContext,
		backEndRequest,
		handleBackEndRequest,
		fyResponseData,
	} = useAppContext();
	const { accounts = [] } = useMemberContext();
	const selectedAccount = getSelectedMemberAccount(accounts);
	const accountNumber = selectedAccount?.account_number ?? '';
	const productName = selectedAccount?.product_name ?? '';

	// Get access token and annual statement notification flags from local storage
	const [currentAccessToken] = useState(getAccessToken());
	const annualStatementNotification = AnnualStatementNotificationStore.get(
		sitecoreContext?.serverDateTime,
	);

	const [isNotificationDismissed, setIsNotificationDismissed] = useState(false);
	const [downloading, setLoading] = useState(false);
	const [latestAnnualStatement, setLatestAnnualStatement] = useState<
		RetrieveAnnStmtResponseDataType | undefined
	>();

	const {
		data: response,
		loading,
		error,
		refetch,
	} = api.useGetAnnualStatements({
		base,
		product_name: productName as ProductName,
		account_number: accountNumber,
	});

	const statements = response?.data?.annual_statements ?? [];

	const currentFYObj = getFormattedCurrentFY(fyResponseData?.data?.values);

	const currentYear = moment(currentFYObj?.from_date).year();

	const checkIfDismissedAlready = (docsId: string) => {
		// Get annual statement local storage - this contains only dismissed or downloaded annual statement.
		const dismissedAS = annualStatementNotification;
		const filteredAS = dismissedAS?.filter(d => d.id === docsId) ?? [];

		return filteredAS[0];
	};

	const download = async (docId?: string, docDate?: string) => {
		setLoading(true);

		// Check if we need to do a refreshtoken for the case when the user has been
		// staying in the form for too long
		if (!isStorybook() || !isExperienceEditorActive()) await doRefreshToken();

		axios({
			url: `${base}/documents/${productName}/${accountNumber}/${
				!!docId ? docId : latestAnnualStatement?.document_id ?? ''
			}`,
			method: 'GET',
			responseType: 'blob',
			headers: {
				'Authorization': `Bearer ${currentAccessToken}`,
				'Accept': 'application/pdf',
				'X-API-Key': config?.externalServices?.coarseGrainApiKey, // API Coarse grain
				'X-Correlation-Id': uuidv4(), // FED initiated correlationID
				'X-Correlation-App': X_CORRELATION_APP, // Link detection of request origin
			},
			withCredentials: AKAMAI_BOT_FLAG, // akamai bot detection
		})
			.then(response => {
				// Logout
				// This is to reset the session timeout as per:
				// [BR01] https://confluence.australiansuper.net.au/display/MemberPortalV5/T07.E05.P01+-+Display+Logout+Success
				handleBackEndRequest(!backEndRequest);

				const filename =
					response.headers['content-disposition']
						.split('filename=')[1]
						.split(';')[0] ?? '';
				const formattedDocDate = moment(docDate)
					.format('YYYYMMM')
					.toUpperCase();
				const customFileName = `${accountNumber}${formattedDocDate}ST.pdf`;
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: 'application/pdf',
					}),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', filename ?? customFileName);
				document.body.appendChild(link);
				link.click();

				// Mark the annual statement as downloaded by storing an isDownloaded flag in the local store.
				// We also need a way to determine if the user has re-logged in, hence we're storing current access token
				if (latestAnnualStatement) {
					setLoading(false);

					AnnualStatementNotificationStore.set({
						...annualStatementNotification,
						accessToken: currentAccessToken,
						id: latestAnnualStatement.document_id,
						isDownLoaded: true,
						publishEndDate,
					});
				}
			})
			.catch(error => {
				console.log('annual statement err: ', error);
			});
	};

	useEffect(() => {
		// Get the latest annual statement
		const statementInternal = statements.find(
			statement => moment(statement.date).year() === currentYear,
		);

		if (statementInternal) {
			setLatestAnnualStatement(statementInternal);
			const documentCheckResult = checkIfDismissedAlready(
				statementInternal?.document_id,
			);
			if (!!documentCheckResult) {
				// Check from the local store if the annual statement has already been dismissed.
				// If it is, don't display the annual statement notification until the next statement period
				if (!!documentCheckResult?.isDismissed) {
					setIsNotificationDismissed(true);
				}

				// Check from the local store if the annual statement has already been downloaded and the user has already re-logged.
				// If it is, don't display the annual statement notification until the next statement period
				if (
					documentCheckResult?.accessToken !== currentAccessToken &&
					!!documentCheckResult?.isDownLoaded
				) {
					setIsNotificationDismissed(true);
				}
			}
		} else {
			setLatestAnnualStatement(undefined);
		}
	}, [annualStatementNotification, currentAccessToken, statements, id]);

	const dismiss = () => {
		if (latestAnnualStatement) {
			// Mark the annual statement as dismissed by storing an isDismissed flag in the local store
			AnnualStatementNotificationStore.set({
				...annualStatementNotification,
				id: latestAnnualStatement.document_id,
				isDismissed: true,
				publishEndDate,
			});
			setIsNotificationDismissed(true);
		}
	};

	return {
		statements,
		loading,
		error,
		refetch,
		latestAnnualStatement,
		download,
		downloading,
		dismiss,
		isNotificationDismissed,
		currentYear,
	};
}
