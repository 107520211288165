import { css } from 'styled-components';

import { FONT_NAMES, FONT_WEIGHT } from 'core/styles/fonts';
import * as mx from 'core/styles/mixins';

// ------------------------------------

export const UNIFORM_MARGIN = '0 0 1em 0';

const uniformMarginCss: any = css`
	margin: ${UNIFORM_MARGIN};

	&:first-of-type {
		margin-top: 0;
	}
`;

// ------------------------------------
// Core typography styles

export const displayXLarge: any = css`
	font-size: 44px;
	line-height: 40px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_LIGHT};
	font-weight: ${FONT_WEIGHT.LIGHT};

	/* No explicit margin-top set here as it should always be first child */
	margin: 0 0 32px;
`;

export const displayLarge: any = css`
	font-size: 28px;
	line-height: 36px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_LIGHT};
	font-weight: ${FONT_WEIGHT.LIGHT};
	margin: 36px 0 24px;

	${mx.bp('s')`
		font-size: 32px;
		line-height: 40px;
	`}
`;

export const displayMedium: any = css`
	font-size: 24px;
	line-height: 32px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.BOOK};
	margin: 24px 0 12px;
`;

export const displaySmall: any = css`
	font-size: 20px;
	line-height: 28px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.BOOK};
	margin: 24px 0 12px;
`;

export const displayMediumLight: any = css`
	font-size: 24px;
	line-height: 40px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.LIGHT};
	margin: 24px 0 12px;
`;

export const headingXLarge: any = css`
	font-size: 32px;
	line-height: 40px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_LIGHT};
	font-weight: ${FONT_WEIGHT.LIGHT};
	${mx.bp('m')`
		font-size: 44px;
		line-height: 52px;
	`}
`;

export const headingLarge: any = css`
	font-size: 28px;
	line-height: 36px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_LIGHT};
	font-weight: ${FONT_WEIGHT.LIGHT};
	${mx.bp('m')`
		font-size: 32px;
		line-height: 40px;
	`}
`;

export const headingSmall: any = css`
	font-size: 24px;
	line-height: 32px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.BOOK};
`;
export const heading: any = css`
	font-size: 18px;
	line-height: 28px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.MEDIUM};
	margin: 24px 0 18px;
`;

export const h5Heading: any = css`
	font-size: 18px;
	line-height: 28px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_MEDIUM};
	font-weight: ${FONT_WEIGHT.MEDIUM};
	margin: 24px 0 18px;
`;

export const subHeading: any = css`
	font-size: 16px;
	line-height: 24px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.SEMI_BOLD};
`;

export const subHeadingMedium: any = css`
	font-size: 18px;
	line-height: 28px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.MEDIUM};
	margin: 24px 0 18px;
`;

export const subHeadingSmall: any = css`
	font-size: 12px;
	line-height: 16px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_BOOKED};
	font-weight: ${FONT_WEIGHT.MEDIUM};
	text-transform: uppercase;
`;

export const bodyMedium = css`
	font-size: 16px;
	line-height: 24px;
	font-family: ${FONT_NAMES.GOTHAM_ROUNDED_MEDIUM};
	font-weight: ${FONT_WEIGHT.MEDIUM};
`;

export const body = css`
	font-size: 16px;
	line-height: 24px;
	font-family: ${FONT_NAMES.OPEN_SANS};
	font-weight: ${FONT_WEIGHT.REGULAR};
`;

export const bodySmall: any = css`
	font-size: 14px;
	line-height: 20px;
	font-family: ${FONT_NAMES.OPEN_SANS};
	font-weight: ${FONT_WEIGHT.REGULAR};
`;

export const caption: any = css`
	font-size: 12px;
	line-height: 20px;
	font-family: ${FONT_NAMES.OPEN_SANS};
	font-weight: ${FONT_WEIGHT.REGULAR};
`;

export const footnote: any = css`
	font-size: 12px;
	line-height: 20px;
	color: rgba(0, 0, 0, 0.6);
	font-family: ${FONT_NAMES.OPEN_SANS};
	font-weight: ${FONT_WEIGHT.REGULAR};
`;

export const inline: any = css`
	display: inline;
	margin: 0;
	font: inherit;
`;

// ------------------------------------
// Class-driven typography (for Sitecore's rich text editor).
// Makes all typography styles accessible via class name.
//
// NOTE: Don't use these in your components! Use the styled component
// exports available in the adjacent 'Typography.tsx' file

export const TYPOGRAPHY_CLASS_PREFIX = 'typography--';

// NOTE: Changing or adding classNames here would also entail
// like changes in the adjacent Typography.tsx file
export const classNameTypography: any = css`
	.${TYPOGRAPHY_CLASS_PREFIX}displayXLarge {
		${displayXLarge}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}displayLarge {
		${displayLarge}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}displayMedium {
		${displayMedium}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}displayMediumLight {
		${displayMediumLight}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}displaySmall {
		${uniformMarginCss}
		${displaySmall}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}heading {
		${uniformMarginCss}
		${heading}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}subHeading {
		${uniformMarginCss}
		${subHeading}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}subHeadingMedium {
		${uniformMarginCss}
		${subHeadingMedium}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}subHeadingSmall {
		${uniformMarginCss}
		${subHeadingSmall}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}bodyMedium {
		${uniformMarginCss}
		${bodyMedium}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}body {
		${uniformMarginCss}
		${body}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}bodySmall {
		${uniformMarginCss}
		${bodySmall}
	}

	.${TYPOGRAPHY_CLASS_PREFIX}caption {
		${uniformMarginCss}
		${caption}
	}
`;

// ------------------------------------
// Global styles

export const globalTypography: any = css`
	/* ------------------------------------
	Default site-wide fonts and semantic typography */

	html {
		color: ${({ theme }) => theme.global.typography.colors.default};
		${body}
	}

	h1 {
		${displayXLarge}
	}

	h2 {
		${displayLarge}
	}

	h3 {
		${displayMedium}
	}

	h4 {
		${displaySmall}
	}

	h5 {
		${h5Heading}
	}

	h6 {
		${subHeading}
	}

	caption {
		${caption}
	}

	/* ------------------------------------
	Lists */

	/* Shared logic between ordered/unordered lists */
	ul,
	ol {
		padding-left: 17px;

		li {
			/* Additional padding is applied here for a total
			of 40px so that <ol/> list items are offset
			by the additional 10px for nicer alignment. */
			padding-left: 10px;
			position: relative;
			margin-top: 8px;
			line-height: 24px;

			&:first-of-type {
				margin-top: 0;
			}
		}

		/* For nested list elements, have their margins a little
		tighter so they are visually a little more grouped with
		the list item they are nested within. */
		ul,
		ol {
			> li:first-of-type {
				margin-top: 4px;
			}
		}
	}

	ul {
		/* Fully replacing the default bullet styles so they can
		visually be much tighter (smaller) and spaced differently. */
		list-style: none;

		> li {
			&::before {
				${mx.pseudo}
				content: '·';
				font-size: 50px;
				left: -18px;
				top: 11px;
				line-height: 0;

				/* So the content in any given pseudo element are
				not selectable with the cursor. */
				pointer-events: none;
			}

			ul > li {
				&::before {
					content: 'o';
					left: -12px;
					font-size: 10px;
				}

				/* Note the selector isn't 'ul > li' here, as nested
				levels 3 and below will share the same ligature. */
				ul li::before {
					content: '■';
					font-size: 8px;
				}
			}
		}
	}

	/* ------------------------------------
	Margins */

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		${uniformMarginCss}
	}
`;
