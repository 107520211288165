/* eslint-disable @typescript-eslint/camelcase */
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { PhoneNumber } from 'core/api/client/MemberApi';
import {
	isWebBrowser,
	isInSitecorePreviewOrEditMode,
} from 'core/utils/global-utils';

import { EventSectionType, EventTriggerType } from './analytics.types';
import CONTENT from './analytics.content';

// --------------------------------
// Variables

const TRACKING_EVENT_ID = 'member_portal';

// --------------------------------
// Event tracking

/**
 * Props for per-instance-per-component event tracking,
 * fed into object that's ultimately pushed into the `dataLayer`
 * along with some other constants in `IAnalyticsEventProps`.
 */
export interface ITrackEventProps {
	event_section: EventSectionType;
	event_type: string;
	event_title: string;
	event_label: string;
	event_trigger: EventTriggerType;
	event_url?: string;
	event_message?: string;
}

/**
 * Props for loggged in member info tracking
 */
export interface ITrackMemberProps {
	party_type_id?: string;
	member_status?: string;
	accounts_account_number?: string;
	accounts_product_name?: string;
	accounts_account_status?: string;
	accounts_joined_date?: string;
	user_phone_number?: PhoneNumber[];
	user_email_address?: string;
	classification_id?: string | undefined;
	life_cycle_profile?: string | undefined;
}

/**
 * The props required for every tracking event object
 * pushed to the `dataLayer` object.
 */
interface IAnalyticsEventProps extends ITrackEventProps, ITrackMemberProps {
	/** Universal identifier for `dataLayer` tracking events. Should be uniform across all tracking events sent to GTM. */
	event: string;
	/** Page location pathname. */
	event_bread: string;
}

/**
 * Push an new event object to the GTM `dataLayer`.
 * For per-component manual tracking events as per specs.
 */
export const trackEvent = (props: ITrackEventProps) => {
	const eventData: IAnalyticsEventProps = {
		event: TRACKING_EVENT_ID,
		event_bread: isWebBrowser ? window.location.pathname : '',

		// These are technically optional, but the analytics team
		// always want them to exist in the event object, even if undefined
		event_url: undefined,
		event_message: undefined,
		party_type_id: undefined,
		member_status: undefined,
		accounts_account_number: undefined,
		accounts_product_name: undefined,
		accounts_account_status: undefined,
		accounts_joined_date: undefined,
		user_phone_number: undefined,
		user_email_address: undefined,
		classification_id: undefined,
		life_cycle_profile: undefined,

		...props,
	};

	const { DATA_LAYER, SITECORE } = CONTENT;

	if (isExperienceEditorActive() || isInSitecorePreviewOrEditMode()) {
		console.warn('-------------------------------');
		console.warn(SITECORE.INACTIVE);
		console.warn(DATA_LAYER.EVENT, props);
		console.warn('-------------------------------');
	} else if (isWebBrowser && !window?.dataLayer) {
		console.warn('-------------------------------');
		console.warn(DATA_LAYER.DOESNT_EXIST);
		console.warn(DATA_LAYER.EVENT, props);
		console.warn('-------------------------------');
	} else {
		if (isWebBrowser) {
			window.dataLayer.push(eventData);
		}
	}
};

// --------------------------------
