import React from 'react';
import isBoolean from 'lodash/isBoolean';

/**
 * A hook to handle the toggle of a state.
 *
 * @description Toggle the state if no argument is provided, otherwise change the state of the hook to be the specified boolean value.
 * @note if passes a none boolean value, for example when the handleToggle is called with MouseEvent, then simply toggle the state.
 * @param initialState - The initial state of the hook
 */
export const useToggle = (
	initialState = false,
): [boolean, (shouldOpen?: any) => void] => {
	const [isOpen, setIsOpen] = React.useState(initialState);

	const handleToggle = React.useCallback(
		shouldOpen => {
			if (!isBoolean(shouldOpen)) {
				// When not specify what the boolean value isOpen should be, toggle the current value of isValue
				setIsOpen(prev => !prev);
			} else {
				shouldOpen !== isOpen && setIsOpen(shouldOpen);
			}
		},
		[isOpen],
	);

	return [isOpen, handleToggle];
};
