import uniqBy from 'lodash/uniqBy';

import {
	removeLocalStorageItem,
	getLocalStorageItem,
	setLocalStorageItem,
} from './localStorage';

const accountBalanceKeyName = 'AccountBalanceKeyName';

export type IAccountBalance = {
	accountNumber: string;
	accountBalance: string;
};

export const removeAccountBalance = () => {
	removeLocalStorageItem(accountBalanceKeyName);
};

export const setAccountBalance = (newItem: IAccountBalance) => {
	const item = getLocalStorageItem(accountBalanceKeyName);
	const accountBalanceArray = [];

	if (item) {
		const itemArray = JSON.parse(item);
		accountBalanceArray.push(...itemArray, newItem);
	} else {
		accountBalanceArray.push(newItem);
	}

	setLocalStorageItem(
		accountBalanceKeyName,
		JSON.stringify(uniqBy(accountBalanceArray, 'accountNumber')),
	);
};

export const getAccountBalance = (accountNumber: string) => {
	const item = getLocalStorageItem(accountBalanceKeyName);
	if (item) {
		try {
			const itemArray =
				JSON.parse(item)?.filter(
					(c: IAccountBalance) => c.accountNumber === accountNumber,
				) ?? [];

			return itemArray[0].accountBalance;
		} catch (error) {
			return '';
		}
	}

	return '';
};

export const checkAccountBalance = () => {
	const item = getLocalStorageItem(accountBalanceKeyName);
	if (item) {
		try {
			return JSON.parse(item);
		} catch (error) {
			return [];
		}
	}

	return [];
};
