import { getAccessToken } from 'core/browserStorage';
import { Member } from 'core/api/client/MemberApi';
import { MEMBER_STATUS } from 'core/constants';

/**
 * Checks if user is logged in
 *
 * @description Return true if Member context contains a valid member oject and CAT token is present in browser
 * @param member - current member object from MemberContext
 */
export const isAuthenticated = (member?: Member): boolean => {
	if (!member) {
		return false;
	}

	const accessToken = getAccessToken();
	// Note: Active = Dashboard page | Provisional = Pending profile page
	return (
		!!accessToken &&
		[MEMBER_STATUS.CREATED, MEMBER_STATUS.ACTIVE]
			.toString()
			.includes(member?.member_status?.toLowerCase() as string)
	);
};
