/* eslint-disable react/prop-types */

import React from 'react';
import T from 'prop-types';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';

import Placeholder from 'core/sitecore/jss-proxy-components/JssPlaceholder';
import { NAMES } from 'core/sitecore/definitions/placeholder-definitions.ts';

// ------------------------------
// Styled components

// For reference in S.FlexLayout
const MainPlaceholder = styled.div``;

const MainTag = styled.main`
	/* Otherwise <main/> is clickable/outlined; this is due to the
	'tabIndex: -1;' which in-turn is necessary for ScrollElement
	functionality and focus being re-set back as part of that */
	outline: none;
`;

// Fullscreen page layout; i.e. sticky footer
const FlexLayout = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	${MainPlaceholder} {
		flex: 1 0 0;
	}
`;

// Mirror Styled Components `S.*` tag namespacing convention
const S = { MainPlaceholder, MainTag, FlexLayout };

// ------------------------------

/**
 * Centralised <main/> tag, as it is applied per page-layout
 * so as not to also encapsulate <aside/> tags in some circumstances.
 *
 * @param {React.ReactNode} children
 */
export const Main = ({ children }) => (
	<ScrollElement name="MainScroll">
		<S.MainTag tabIndex="-1">{children}</S.MainTag>
	</ScrollElement>
);

/**
 * High-level global page layout; achieves sticky footer.
 *
 * @param {Object} props
 */
const AppPlaceholders = ({
	rendering,
	TopContent,
	MainContent,
	BottomContent,
}) => (
	<S.FlexLayout>
		{!!TopContent ? (
			<TopContent />
		) : (
			<Placeholder name={NAMES.ROOT.TOP} {...{ rendering }} />
		)}
		<S.MainPlaceholder>
			{!!MainContent ? (
				<MainContent />
			) : (
				<>
					{/* Empty div prevents the S.MainPlaceholder `flex: 1` from
					affecting any modules authored in the main Placeholder */}
					<div>
						<Placeholder name={NAMES.ROOT.MAIN} {...{ rendering }} />
					</div>
				</>
			)}
		</S.MainPlaceholder>
		{!!BottomContent ? (
			<BottomContent />
		) : (
			<Placeholder name={NAMES.ROOT.BOTTOM} {...{ rendering }} />
		)}
	</S.FlexLayout>
);

AppPlaceholders.propTypes = {
	rendering: T.object,
	TopContent: T.node,
	MainContent: T.node,
	BottomContent: T.node,
};

AppPlaceholders.defaultProps = {
	rendering: null,
	TopContent: null,
	MainContent: null,
	BottomContent: null,
};

export default AppPlaceholders;
