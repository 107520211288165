import styled, { css } from 'styled-components';

import * as vr from 'core/styles/variables';
import * as mx from 'core/styles/mixins';

export const Label = styled.label`
	display: flex;
	align-items: center;
`;

export const Content = styled.span<{
	readonly: boolean;
	withoutTextWrap: boolean;
}>`
	padding: 6px 0;
	font-weight: 400;
	font-size: 16px;
	max-width: 500px;

	${({ withoutTextWrap }) =>
		!withoutTextWrap &&
		css`
			${mx.ellipsis}
		`}

	${({ readonly }) =>
		readonly &&
		css`
			padding: 2px 0 0 0;
		`}
`;

export const Optional = styled.span`
	color: ${vr.formfieldLabelOptionalColor};
`;
