/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VAL_00000001
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

export interface Account {
	account_number: string;
	product_name: ProductNameValues;
	product_classification: string;
	account_status: 'ACTIVE' | 'INACTIVE' | 'PROVISIONAL' | 'HOLDING' | 'PENDING';
	application_date: string;
	application_type: 'DEEMED' | 'DIRECT';
	join_date: string;
}

export interface AccountSearchResult {
	data: Accounts;
	meta: Meta;
}

export interface AccountResult {
	data: Account;
}

export interface AdviserResponse {
	data?: Adviser;
}

export interface Adviser {
	first_name?: string;
	last_name?: string;
	asic_number?: string;
}

export interface Accounts {
	accounts: Account[];
}

export interface BenefitQuote {
	data: BenefitQuoteData;
}

export interface BenefitQuoteData {
	type: BenefitTypeValues;
	estimate_date: string;
	total_amount: number;
	preservation_components: PreservationComponent[];
	tax_components: TaxComponent[];
}

export interface BPayReferenceResponse {
	data: BPayReference;
}

export interface BPayReference {
	biller_code: string;
	biller_reference: string;
}

export interface TfnConsentRequest {
	data: TfnConsent;
}

export interface TfnConsent {
	tfn_consent: boolean;
}

export interface TfnRequest {
	data: Tfn;
}

export interface Tfn {
	tfn: string;
}

export interface TfnStatusResponse {
	data: TfnStatus;
}

export interface TfnStatus {
	tfn_status: 'SUPPLIED' | 'NOT_SUPPLIED';
	tfn_consent: boolean;
}

export interface BalancesResponse {
	data: Balances;
	meta: Meta;
}

export interface Balances {
	balances: BalanceFyYear[];
}

export interface BalanceFyYear {
	fy_end_date: string;
	fy_totals: BalanceFYTotals[];
}

export interface BalanceFYTotals {
	amount: number;
	type: BalanceTypeValues;
}

export interface TaxComponent {
	type: 'TAXED' | 'TAXFREE' | 'TOTAL';
	amount: number;
}

export interface MemberDirectEligibilityResponse {
	data: MemberDirect;
}

export interface MemberDirect {
	eligibility: MemberDirectEligibility;
	is_registered: boolean;
}

export interface MemberDirectEligibility {
	is_eligible: boolean;
	not_eligible_reason?: string;
}

export interface PreservationComponent {
	type: 'PRESERVED' | 'RESTRICTED' | 'UNRESTRICTED' | 'TOTAL';
	amount: number;
}

export interface Contributions {
	data: ContributionsData;
}

export interface ContributionsData {
	contribution: Contribution;
}

export interface Contribution {
	date: string;
	amount: number;
	concessional_amount?: number;
	non_concessional_amount?: number;
	concessional_cap?: number;
	non_concessional_cap?: number;
}

export interface IncomePayments {
	data: IncomePaymentsData;
}

export interface IncomePaymentsData {
	income_payment: IncomePayment;
}

export interface IncomePayment {
	date: string;
	amount: number;
}

export interface EmploymentDetail {
	data: EmploymentDetails;
}

export interface EmploymentDetails {
	division: string;
	occupation: string;
	insurance_category: string;
	employment: MemberEmployment[];
}

export interface MemberEmployment {
	employer_number: string;
	registered_name: string;
	employee_salary?: number;
	type: EmploymentTypeValues;
}

export interface BankAccounts {
	data: BankAccount[];
}

export interface BankAccount {
	bsb: string;
	account_number: string;
	account_name: string;
	bank_name: string;
	account_status: string;
	id: number;
}

export interface UpdateBankAccountRequest {
	data: UpdateBankAccountRequestData;
}

export interface UpdateBankAccountRequestData {
	auth_code: string;
	bank_account_details: UpdateBankAccountDetails;
	verification_details: UpdateBankAccountVerificationDets;
	retry_count?: number;
	retry_form_id?: string;
}

export interface UpdateBankAccountDetails {
	bsb: string;
	account_number: string;
	account_name: string;
}

export interface UpdateBankAccountVerificationDets {
	application_number: string;
	verification_token: string;
	identity_checked: boolean;
	identity_verification_date: string;
	identity_verification_status: string;
	documents?: UpdateBankAccountDocumentList[];
}

export interface UpdateBankAccountDocumentList {
	id?: string;
}

export interface UpdateBankAccountResponse {
	data: UpdateBankAccountResponseData;
}

export interface UpdateBankAccountResponseData {
	form_id: string;
	form_status: string;
	receipt_number: string;
}

/**
 * Summary type value from transaction summary api.
 */
export type SummaryTypeValues = 'ACCOUNT_BALANCES_ESTIMATED_EARNINGS';

/**
 * Benefit quote type. This is based on member's preservation age.
 */
export type BenefitTypeValues = 'ROLLOVER' | 'RETIREMENT';

/**
 * Source of contributions.
 */
export type BpaySourceContributionValues = 'MEMBER' | 'SPOUSE';

/**
 * Employment types
 */
export type EmploymentTypeValues =
	| 'FULLTIME'
	| 'PARTTIME'
	| 'CONTRACTOR'
	| 'CASUAL'
	| 'FIXED TERM'
	| 'NOTADVISED';

export type BalanceTypeValues =
	| 'CLOSING'
	| 'OPENING'
	| 'ESTIMATED_EARNINGS'
	| 'CURRENT';

export type ProductNameValues = 'Accumulation' | 'Pension';

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

/**
 * No Content
 */
export type Response204 = void;

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetAccountQueryParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name?: ProductNameValues;
}

export type GetAccountProps = Omit<
	GetProps<
		AccountSearchResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAccountQueryParams,
		void
	>,
	'path'
>;

/**
 * all accounts of the same type for a member. E.g all accum accounts for a given member
 */
export const GetAccount = (props: GetAccountProps) => (
	<Get<
		AccountSearchResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAccountQueryParams,
		void
	>
		path={`/accounts`}
		{...props}
	/>
);

export type UseGetAccountProps = Omit<
	UseGetProps<
		AccountSearchResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAccountQueryParams,
		void
	>,
	'path'
>;

/**
 * all accounts of the same type for a member. E.g all accum accounts for a given member
 */
export const useGetAccount = (props: UseGetAccountProps) =>
	useGet<
		AccountSearchResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAccountQueryParams,
		void
	>(`/accounts`, props);

export interface GetAccountByProductNameAndAccountNumberPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetAccountByProductNameAndAccountNumberProps = Omit<
	GetProps<
		AccountResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetAccountByProductNameAndAccountNumberPathParams
	>,
	'path'
> &
	GetAccountByProductNameAndAccountNumberPathParams;

/**
 * Get account
 */
export const GetAccountByProductNameAndAccountNumber = ({
	product_name,
	account_number,
	...props
}: GetAccountByProductNameAndAccountNumberProps) => (
	<Get<
		AccountResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetAccountByProductNameAndAccountNumberPathParams
	>
		path={`/accounts/${product_name}/${account_number}`}
		{...props}
	/>
);

export type UseGetAccountByProductNameAndAccountNumberProps = Omit<
	UseGetProps<
		AccountResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetAccountByProductNameAndAccountNumberPathParams
	>,
	'path'
> &
	GetAccountByProductNameAndAccountNumberPathParams;

/**
 * Get account
 */
export const useGetAccountByProductNameAndAccountNumber = ({
	product_name,
	account_number,
	...props
}: UseGetAccountByProductNameAndAccountNumberProps) =>
	useGet<
		AccountResult,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetAccountByProductNameAndAccountNumberPathParams
	>(
		(paramsInPath: GetAccountByProductNameAndAccountNumberPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetBalancesQueryParams {
	/**
	 * Comma separated Financial year end dates to get the balances for the financial year. If no dates are specified, current date is assumed by default to return balances for the current date.
	 *
	 */
	fy_end_dates?: string[];
	/**
	 * Balance type. i.e ESTIMATED_EARNINGS
	 */
	type?: BalanceTypeValues;
	/**
	 * Flag to exclude Member Direct in the balances
	 */
	exclude_member_direct?: boolean;
}

export interface GetBalancesPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetBalancesProps = Omit<
	GetProps<
		BalancesResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBalancesQueryParams,
		GetBalancesPathParams
	>,
	'path'
> &
	GetBalancesPathParams;

/**
 * Get account balances for financial years.
 *
 * Get account balances for different financial years. By default this API returns estimated balance for current date. This API optionally accepts a list of financial years end dates and returns closing balance for the respective FY end dates.
 *
 */
export const GetBalances = ({
	product_name,
	account_number,
	...props
}: GetBalancesProps) => (
	<Get<
		BalancesResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBalancesQueryParams,
		GetBalancesPathParams
	>
		path={`/accounts/${product_name}/${account_number}/balances`}
		{...props}
	/>
);

export type UseGetBalancesProps = Omit<
	UseGetProps<
		BalancesResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBalancesQueryParams,
		GetBalancesPathParams
	>,
	'path'
> &
	GetBalancesPathParams;

/**
 * Get account balances for financial years.
 *
 * Get account balances for different financial years. By default this API returns estimated balance for current date. This API optionally accepts a list of financial years end dates and returns closing balance for the respective FY end dates.
 *
 */
export const useGetBalances = ({
	product_name,
	account_number,
	...props
}: UseGetBalancesProps) =>
	useGet<
		BalancesResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBalancesQueryParams,
		GetBalancesPathParams
	>(
		(paramsInPath: GetBalancesPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/balances`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetBankAccountsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetBankAccountsProps = Omit<
	GetProps<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountsPathParams
	>,
	'path'
> &
	GetBankAccountsPathParams;

/**
 * Get bank accounts for an existing member.
 */
export const GetBankAccounts = ({
	product_name,
	account_number,
	...props
}: GetBankAccountsProps) => (
	<Get<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountsPathParams
	>
		path={`/accounts/${product_name}/${account_number}/bank-accounts`}
		{...props}
	/>
);

export type UseGetBankAccountsProps = Omit<
	UseGetProps<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountsPathParams
	>,
	'path'
> &
	GetBankAccountsPathParams;

/**
 * Get bank accounts for an existing member.
 */
export const useGetBankAccounts = ({
	product_name,
	account_number,
	...props
}: UseGetBankAccountsProps) =>
	useGet<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountsPathParams
	>(
		(paramsInPath: GetBankAccountsPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/bank-accounts`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetBankAccountPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
	/**
	 * The member bank ID
	 */
	bank_account_id: number;
}

export type GetBankAccountProps = Omit<
	GetProps<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountPathParams
	>,
	'path'
> &
	GetBankAccountPathParams;

/**
 * Get bank accounts for an existing member, if bank id is specified, it will only return details of that bank account.
 */
export const GetBankAccount = ({
	product_name,
	account_number,
	bank_account_id,
	...props
}: GetBankAccountProps) => (
	<Get<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountPathParams
	>
		path={`/accounts/${product_name}/${account_number}/bank-accounts/${bank_account_id}`}
		{...props}
	/>
);

export type UseGetBankAccountProps = Omit<
	UseGetProps<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountPathParams
	>,
	'path'
> &
	GetBankAccountPathParams;

/**
 * Get bank accounts for an existing member, if bank id is specified, it will only return details of that bank account.
 */
export const useGetBankAccount = ({
	product_name,
	account_number,
	bank_account_id,
	...props
}: UseGetBankAccountProps) =>
	useGet<
		BankAccounts,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetBankAccountPathParams
	>(
		(paramsInPath: GetBankAccountPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/bank-accounts/${paramsInPath.bank_account_id}`,
		{ pathParams: { product_name, account_number, bank_account_id }, ...props },
	);

export interface UpdateBankAccountDetailsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
	/**
	 * The member bank ID
	 */
	bank_account_id: number;
}

export type UpdateBankAccountDetailsProps = Omit<
	MutateProps<
		UpdateBankAccountResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		UpdateBankAccountRequest,
		UpdateBankAccountDetailsPathParams
	>,
	'path' | 'verb'
> &
	UpdateBankAccountDetailsPathParams;

/**
 * update bank account details of a member's Pension account only.
 */
export const UpdateBankAccountDetails = ({
	product_name,
	account_number,
	bank_account_id,
	...props
}: UpdateBankAccountDetailsProps) => (
	<Mutate<
		UpdateBankAccountResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		UpdateBankAccountRequest,
		UpdateBankAccountDetailsPathParams
	>
		verb="PUT"
		path={`/accounts/${product_name}/${account_number}/bank-accounts/${bank_account_id}`}
		{...props}
	/>
);

export type UseUpdateBankAccountDetailsProps = Omit<
	UseMutateProps<
		UpdateBankAccountResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		UpdateBankAccountRequest,
		UpdateBankAccountDetailsPathParams
	>,
	'path' | 'verb'
> &
	UpdateBankAccountDetailsPathParams;

/**
 * update bank account details of a member's Pension account only.
 */
export const useUpdateBankAccountDetails = ({
	product_name,
	account_number,
	bank_account_id,
	...props
}: UseUpdateBankAccountDetailsProps) =>
	useMutate<
		UpdateBankAccountResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		UpdateBankAccountRequest,
		UpdateBankAccountDetailsPathParams
	>(
		'PUT',
		(paramsInPath: UpdateBankAccountDetailsPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/bank-accounts/${paramsInPath.bank_account_id}`,
		{ pathParams: { product_name, account_number, bank_account_id }, ...props },
	);

export interface GetBPayReferenceQueryParams {
	/**
	 * Source of contribution
	 */
	contribution_code: BpaySourceContributionValues;
}

export interface GetBPayReferencePathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetBPayReferenceProps = Omit<
	GetProps<
		BPayReferenceResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBPayReferenceQueryParams,
		GetBPayReferencePathParams
	>,
	'path'
> &
	GetBPayReferencePathParams;

/**
 * Get BPay reference
 */
export const GetBPayReference = ({
	product_name,
	account_number,
	...props
}: GetBPayReferenceProps) => (
	<Get<
		BPayReferenceResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBPayReferenceQueryParams,
		GetBPayReferencePathParams
	>
		path={`/accounts/${product_name}/${account_number}/bpay-reference`}
		{...props}
	/>
);

export type UseGetBPayReferenceProps = Omit<
	UseGetProps<
		BPayReferenceResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBPayReferenceQueryParams,
		GetBPayReferencePathParams
	>,
	'path'
> &
	GetBPayReferencePathParams;

/**
 * Get BPay reference
 */
export const useGetBPayReference = ({
	product_name,
	account_number,
	...props
}: UseGetBPayReferenceProps) =>
	useGet<
		BPayReferenceResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBPayReferenceQueryParams,
		GetBPayReferencePathParams
	>(
		(paramsInPath: GetBPayReferencePathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/bpay-reference`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetBenefitEstimateQueryParams {
	/**
	 * Benefit type based on the member preservation age.
	 */
	benefit_type: BenefitTypeValues;
}

export interface GetBenefitEstimatePathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetBenefitEstimateProps = Omit<
	GetProps<
		BenefitQuote,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBenefitEstimateQueryParams,
		GetBenefitEstimatePathParams
	>,
	'path'
> &
	GetBenefitEstimatePathParams;

/**
 * Get benefit quote for the member account.
 */
export const GetBenefitEstimate = ({
	product_name,
	account_number,
	...props
}: GetBenefitEstimateProps) => (
	<Get<
		BenefitQuote,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBenefitEstimateQueryParams,
		GetBenefitEstimatePathParams
	>
		path={`/accounts/${product_name}/${account_number}/benefit-estimate`}
		{...props}
	/>
);

export type UseGetBenefitEstimateProps = Omit<
	UseGetProps<
		BenefitQuote,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBenefitEstimateQueryParams,
		GetBenefitEstimatePathParams
	>,
	'path'
> &
	GetBenefitEstimatePathParams;

/**
 * Get benefit quote for the member account.
 */
export const useGetBenefitEstimate = ({
	product_name,
	account_number,
	...props
}: UseGetBenefitEstimateProps) =>
	useGet<
		BenefitQuote,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetBenefitEstimateQueryParams,
		GetBenefitEstimatePathParams
	>(
		(paramsInPath: GetBenefitEstimatePathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/benefit-estimate`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetContributionsQueryParams {
	/**
	 * Financial year end date to get contributions for the period of financial year. If no dates are specified, current date is assumed by default to return contributions for the current date's period.
	 *
	 */
	fy_end_date?: string;
}

export interface GetContributionsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetContributionsProps = Omit<
	GetProps<
		Contributions,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetContributionsQueryParams,
		GetContributionsPathParams
	>,
	'path'
> &
	GetContributionsPathParams;

/**
 * Get account contributions for financial year.
 *
 * Get the value of accumulation fund contributions for a financial year. By default this API returns contributions for current date. This API optionally accepts financial year end date and returns contributions for the respective period for FY end date.
 *
 */
export const GetContributions = ({
	product_name,
	account_number,
	...props
}: GetContributionsProps) => (
	<Get<
		Contributions,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetContributionsQueryParams,
		GetContributionsPathParams
	>
		path={`/accounts/${product_name}/${account_number}/contributions`}
		{...props}
	/>
);

export type UseGetContributionsProps = Omit<
	UseGetProps<
		Contributions,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetContributionsQueryParams,
		GetContributionsPathParams
	>,
	'path'
> &
	GetContributionsPathParams;

/**
 * Get account contributions for financial year.
 *
 * Get the value of accumulation fund contributions for a financial year. By default this API returns contributions for current date. This API optionally accepts financial year end date and returns contributions for the respective period for FY end date.
 *
 */
export const useGetContributions = ({
	product_name,
	account_number,
	...props
}: UseGetContributionsProps) =>
	useGet<
		Contributions,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetContributionsQueryParams,
		GetContributionsPathParams
	>(
		(paramsInPath: GetContributionsPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/contributions`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetEmploymentPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetEmploymentProps = Omit<
	GetProps<
		EmploymentDetail,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetEmploymentPathParams
	>,
	'path'
> &
	GetEmploymentPathParams;

/**
 * Get current employment details for a specific account
 *
 * Retrieves current employment details for a member, at account level
 *
 */
export const GetEmployment = ({
	product_name,
	account_number,
	...props
}: GetEmploymentProps) => (
	<Get<
		EmploymentDetail,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetEmploymentPathParams
	>
		path={`/accounts/${product_name}/${account_number}/employment`}
		{...props}
	/>
);

export type UseGetEmploymentProps = Omit<
	UseGetProps<
		EmploymentDetail,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetEmploymentPathParams
	>,
	'path'
> &
	GetEmploymentPathParams;

/**
 * Get current employment details for a specific account
 *
 * Retrieves current employment details for a member, at account level
 *
 */
export const useGetEmployment = ({
	product_name,
	account_number,
	...props
}: UseGetEmploymentProps) =>
	useGet<
		EmploymentDetail,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetEmploymentPathParams
	>(
		(paramsInPath: GetEmploymentPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/employment`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetIncomePaymentsQueryParams {
	/**
	 * Financial year start date.
	 */
	fy_start_date?: string;
	/**
	 * Financial year end date to get contributions for the period of financial year. If no dates are specified, current date is assumed by default to return contributions for the current date's period.
	 *
	 */
	fy_end_date?: string;
}

export interface GetIncomePaymentsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetIncomePaymentsProps = Omit<
	GetProps<
		IncomePayments,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetIncomePaymentsQueryParams,
		GetIncomePaymentsPathParams
	>,
	'path'
> &
	GetIncomePaymentsPathParams;

/**
 * Get account pensions for financial year.
 *
 * Get the value of choice income pensions for a financial year. By default this API returns pensions for current date. This API optionally accepts financial year end date and returns pensions for the respective FY end date.
 *
 */
export const GetIncomePayments = ({
	product_name,
	account_number,
	...props
}: GetIncomePaymentsProps) => (
	<Get<
		IncomePayments,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetIncomePaymentsQueryParams,
		GetIncomePaymentsPathParams
	>
		path={`/accounts/${product_name}/${account_number}/income-payments`}
		{...props}
	/>
);

export type UseGetIncomePaymentsProps = Omit<
	UseGetProps<
		IncomePayments,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetIncomePaymentsQueryParams,
		GetIncomePaymentsPathParams
	>,
	'path'
> &
	GetIncomePaymentsPathParams;

/**
 * Get account pensions for financial year.
 *
 * Get the value of choice income pensions for a financial year. By default this API returns pensions for current date. This API optionally accepts financial year end date and returns pensions for the respective FY end date.
 *
 */
export const useGetIncomePayments = ({
	product_name,
	account_number,
	...props
}: UseGetIncomePaymentsProps) =>
	useGet<
		IncomePayments,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		GetIncomePaymentsQueryParams,
		GetIncomePaymentsPathParams
	>(
		(paramsInPath: GetIncomePaymentsPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/income-payments`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetTfnStatusPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetTfnStatusProps = Omit<
	GetProps<
		TfnStatusResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetTfnStatusPathParams
	>,
	'path'
> &
	GetTfnStatusPathParams;

/**
 * Get TFN status
 */
export const GetTfnStatus = ({
	product_name,
	account_number,
	...props
}: GetTfnStatusProps) => (
	<Get<
		TfnStatusResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetTfnStatusPathParams
	>
		path={`/accounts/${product_name}/${account_number}/tfn`}
		{...props}
	/>
);

export type UseGetTfnStatusProps = Omit<
	UseGetProps<
		TfnStatusResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetTfnStatusPathParams
	>,
	'path'
> &
	GetTfnStatusPathParams;

/**
 * Get TFN status
 */
export const useGetTfnStatus = ({
	product_name,
	account_number,
	...props
}: UseGetTfnStatusProps) =>
	useGet<
		TfnStatusResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetTfnStatusPathParams
	>(
		(paramsInPath: GetTfnStatusPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/tfn`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface CreateTfnPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type CreateTfnProps = Omit<
	MutateProps<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnRequest,
		CreateTfnPathParams
	>,
	'path' | 'verb'
> &
	CreateTfnPathParams;

/**
 * Update TFN for the account.
 */
export const CreateTfn = ({
	product_name,
	account_number,
	...props
}: CreateTfnProps) => (
	<Mutate<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnRequest,
		CreateTfnPathParams
	>
		verb="POST"
		path={`/accounts/${product_name}/${account_number}/tfn`}
		{...props}
	/>
);

export type UseCreateTfnProps = Omit<
	UseMutateProps<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnRequest,
		CreateTfnPathParams
	>,
	'path' | 'verb'
> &
	CreateTfnPathParams;

/**
 * Update TFN for the account.
 */
export const useCreateTfn = ({
	product_name,
	account_number,
	...props
}: UseCreateTfnProps) =>
	useMutate<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnRequest,
		CreateTfnPathParams
	>(
		'POST',
		(paramsInPath: CreateTfnPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/tfn`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface CreateTfnConsentPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type CreateTfnConsentProps = Omit<
	MutateProps<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnConsentRequest,
		CreateTfnConsentPathParams
	>,
	'path' | 'verb'
> &
	CreateTfnConsentPathParams;

/**
 * Update TFN consent
 */
export const CreateTfnConsent = ({
	product_name,
	account_number,
	...props
}: CreateTfnConsentProps) => (
	<Mutate<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnConsentRequest,
		CreateTfnConsentPathParams
	>
		verb="POST"
		path={`/accounts/${product_name}/${account_number}/tfn/consent`}
		{...props}
	/>
);

export type UseCreateTfnConsentProps = Omit<
	UseMutateProps<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnConsentRequest,
		CreateTfnConsentPathParams
	>,
	'path' | 'verb'
> &
	CreateTfnConsentPathParams;

/**
 * Update TFN consent
 */
export const useCreateTfnConsent = ({
	product_name,
	account_number,
	...props
}: UseCreateTfnConsentProps) =>
	useMutate<
		Response204,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		TfnConsentRequest,
		CreateTfnConsentPathParams
	>(
		'POST',
		(paramsInPath: CreateTfnConsentPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/tfn/consent`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetMemberDirectEligibilityPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetMemberDirectEligibilityProps = Omit<
	GetProps<
		MemberDirectEligibilityResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetMemberDirectEligibilityPathParams
	>,
	'path'
> &
	GetMemberDirectEligibilityPathParams;

/**
 * Get member direct eligibility
 */
export const GetMemberDirectEligibility = ({
	product_name,
	account_number,
	...props
}: GetMemberDirectEligibilityProps) => (
	<Get<
		MemberDirectEligibilityResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetMemberDirectEligibilityPathParams
	>
		path={`/accounts/${product_name}/${account_number}/member-direct`}
		{...props}
	/>
);

export type UseGetMemberDirectEligibilityProps = Omit<
	UseGetProps<
		MemberDirectEligibilityResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetMemberDirectEligibilityPathParams
	>,
	'path'
> &
	GetMemberDirectEligibilityPathParams;

/**
 * Get member direct eligibility
 */
export const useGetMemberDirectEligibility = ({
	product_name,
	account_number,
	...props
}: UseGetMemberDirectEligibilityProps) =>
	useGet<
		MemberDirectEligibilityResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetMemberDirectEligibilityPathParams
	>(
		(paramsInPath: GetMemberDirectEligibilityPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/member-direct`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetAccountAdviserPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * Account Identifier in Link
	 */
	account_number: string;
}

export type GetAccountAdviserProps = Omit<
	GetProps<
		AdviserResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetAccountAdviserPathParams
	>,
	'path'
> &
	GetAccountAdviserPathParams;

/**
 * Get Financial Adviser for a member's account.
 */
export const GetAccountAdviser = ({
	product_name,
	account_number,
	...props
}: GetAccountAdviserProps) => (
	<Get<
		AdviserResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetAccountAdviserPathParams
	>
		path={`/accounts/${product_name}/${account_number}/adviser`}
		{...props}
	/>
);

export type UseGetAccountAdviserProps = Omit<
	UseGetProps<
		AdviserResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetAccountAdviserPathParams
	>,
	'path'
> &
	GetAccountAdviserPathParams;

/**
 * Get Financial Adviser for a member's account.
 */
export const useGetAccountAdviser = ({
	product_name,
	account_number,
	...props
}: UseGetAccountAdviserProps) =>
	useGet<
		AdviserResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		GetAccountAdviserPathParams
	>(
		(paramsInPath: GetAccountAdviserPathParams) =>
			`/accounts/${paramsInPath.product_name}/${paramsInPath.account_number}/adviser`,
		{ pathParams: { product_name, account_number }, ...props },
	);
