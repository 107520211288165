/* eslint-disable no-console */

import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import inRange from 'lodash/inRange';
import { GetDataError } from 'restful-react';

import { Error as IdentityError } from 'core/api/client/IdentityApi';
import { Error as RegistrationExpError } from 'core/api/client/RegistrationExperienceApi';
import { isObject } from 'core/utils/object-utils';
import { isInSitecorePreviewOrEditMode } from 'core/utils/global-utils';
import { ICONS } from 'core/components/Icon';

/**
 * A recursive loop over the placeholders in Sitecore's
 * layout service; checks if any components in any of those
 * renderings are missing a 'dataSource', and replaces them
 * with an 'Unauthored' (read: debug) component. This prevents
 * the vague 'An error occurred.' popup in the Experience Editor,
 * and also prevents JSS's 'Connected Mode' from outright crashing.
 *
 * @param {Object} placeholdersObj - The root placeholders object.
 * @returns {Object} The above, with any problem components filtered.
 */
export function recursiveFilterPlaceholders(placeholdersObj: {
	[key: string]: any;
}) {
	const placeholderNames = Object.keys(placeholdersObj);

	return placeholderNames.reduce((acc: { [key: string]: any }, key) => {
		// Array of components
		let placeholderComponents = placeholdersObj[key] || [];

		placeholderComponents = placeholderComponents.map(
			(
				component: {
					componentName: string;
					placeholders?: {
						[key: string]: any;
					};
					dataSource: string;
					fields: {
						[key: string]: any;
					};
				} | null,
			) => {
				const { dataSource, placeholders, componentName, fields } = component!;
				// Considered broken if it has a name, but no dataSource (content)
				// Exceptions are made for components that have placeholders of their own
				const isBroken =
					!!componentName && !dataSource && !placeholders && !fields;
				const showDebugComponent =
					isExperienceEditorActive() ||
					isInSitecorePreviewOrEditMode() ||
					process.env.NODE_ENV === 'development';

				if (isBroken) {
					console.log('------------------------');
					console.log(
						`Component '${componentName}' is missing a 'dataSource':`,
					);
					console.log(component);
					console.log('------------------------');

					if (showDebugComponent) {
						// Reassign component rendering to a predefined 'UnauthoredComponent'
						// which won't crash Sitecore, and displays an informative message
						component = {
							...component,

							// Just some value, potentially preventing edge-cases
							// should this component structure even get re-scanned
							dataSource: '/',

							fields: {
								// Retain the original componentName so we can render it
								// in the 'UnauthoredComponent' for logging purposes
								sourceComponentName: componentName,
							},
							componentName: 'UnauthoredComponent',
						};
					} else {
						// Delete the component entirely if it's in a mode like
						// preview or - somehow - production. Edge-case.
						component = null;
					}
				} else if (!!placeholders && Object.keys(placeholders).length > 0) {
					component!.placeholders = recursiveFilterPlaceholders(placeholders);
				}

				return component;
			},
		);

		acc[key] = placeholderComponents;

		return acc;
	}, {});
}

/**
 * Determines whether field could be considered a 'valid' JSS object.
 *
 * @param {Object} field
 */
export const isJssField = (field?: any) =>
	!!field && isObject(field) && typeof field !== 'string' && !!field.value;

// ------------------------------
// JSS types

export type JssTextType<T = string> = {
	value: T;
};

export type JssImageType = {
	value: {
		src: string;
		alt: string;
		class?: string;
		title?: string;
	};
};

export type JssLinkType = {
	editable?: boolean;
	value: {
		text: string;
		href: string;
		linktype?: string;
		url?: string;
		anchor?: string;
		title?: string;
		target?: string;
		class?: string;
		id?: string;
		eventLabel?: string;
	};
};

export type JssPhoneLinkType = {
	editable?: boolean;
	value: {
		text: string;
		url: string;
		href: string;
		linktype?: string;
		title?: string;
		target?: string;
		class?: string;
	};
};

export type JssBooleanType = {
	value: boolean;
};

export type JssIconType = {
	value: ICONS;
};

export type SitecoreItemType<T = object> = {
	id: string;
	fields: T;
};

export type SitecoreErrorMsgItemType = SitecoreItemType<{
	code: JssTextType;
	errorMessage: JssTextType;
	errorTitle?: JssTextType;
	errorIcon?: JssImageType;
}>;

export type SitecoreSuccessMsgItemType = SitecoreItemType<{
	code: JssTextType;
	successMessage: JssTextType;
}>;

export type JssPdfLinkType = {
	value: {
		href: string;
		linktype: string;
		url: string;
	};
};

export type JssPdfLinksItemType = {
	id: string;
	fields: {
		codeFrom: JssTextType;
		codeTo: JssTextType;
		link: JssPdfLinkType;
	};
};

export type SitecoreTreeOptionsItemType = {
	id: string;
	fields: {
		Key: JssTextType;
		Value: JssTextType;
		Help: JssTextType;
	};
};

// ------------------------------

/**
 * Get the message value from an array of messages (success or error type)
 * References:
 * https://confluence.australiansuper.net.au/display/MemberPortalV5/GL02+-+Success+Notifications
 * https://confluence.australiansuper.net.au/display/MemberPortalV5/GL01+-+Error+Messages
 *
 * @param {string} code - code of certain message base on confluence. e.g. SN08
 * @param {array}  messageItems - array of message.
 * @param {string}  messageType - The type of message to be fetch. It can be errorMessage (by default) | successMessage
 * @returns {string} The description pair of the code being fetch.
 */
export const getMessageByCode = (
	code: string | undefined,
	messageItems: (SitecoreErrorMsgItemType | SitecoreSuccessMsgItemType)[],
	messageType: 'errorMessage' | 'successMessage' = 'errorMessage',
) => {
	if (!isArray(messageItems) || !code) return '';

	const messageItemTypeDetail = messageItems.find(
		item => item.fields.code.value === code,
	);

	return get(messageItemTypeDetail, `fields.${messageType}.value`, '');
};

/**
 * Get the TMD PDF url based on the classification id of logged in member.
 *
 * @param {string} code - classification id from sitecore member context
 * @param {array}  pdfLinks - array of TMD PDF links.
 * @returns {string} The description pair of the code being fetch.
 */
export const getPdfLink = (
	code: string | undefined,
	pdfLinks: JssPdfLinksItemType[],
) => {
	if (!isArray(pdfLinks) || !code)
		/* https://confluence.australiansuper.net.au/pages/viewpage.action?pageId=102270356
		 *  B09 - if NO CODE AVAILABLE redirect to the URL below.
		 */
		return 'https://www.australiansuper.com/tools-and-advice/learn/target-market-determinations';

	const pdfLinkItemTypeDetail = pdfLinks.find(
		item =>
			item.fields.codeFrom.value === code ||
			inRange(
				parseInt(code),
				parseInt(item.fields.codeFrom.value ?? 0),
				parseInt(item.fields.codeTo.value ?? 0),
			),
	);

	return get(pdfLinkItemTypeDetail, `fields.link.value.url`, '');
};

/**
 * Get the page state and database use, and returns a boolean.
 *
 * @param {string} pageState - page state of the current page location on sitecore admin panel
 * @param {string} database - active database on sitecore admin panel
 * @returns {boolean} - returns a boolean if state is in preview or edit mode.
 */
export const getSitecorePageState = (
	pageState: string | undefined,
	database: string | undefined,
) => {
	const masterDB = 'master';

	if (!pageState && !database) {
		return false;
	}

	return (
		(database?.toLowerCase() === masterDB &&
			pageState?.toLowerCase() === 'edit') ||
		(database?.toLowerCase() === masterDB &&
			pageState?.toLowerCase() === 'preview') ||
		database?.toLowerCase() === masterDB
	);
};

/**
 * @description This method returns OTP error message from Sitecore or API response
 * @param {string} sitecoreMessage Should be the raw message from getMessageByCode
 * since the fallback value is also string. This is also in relation with the ReactHTMLParser
 * that should accept only string content.
 * @param {GetDataError<IdentityError | RegistrationExpError> | null} otpError Fallback value
 * @returns {string} Error message
 */
export const getOtpErrorMessage = (
	sitecoreMessage: string,
	otpError: GetDataError<IdentityError | RegistrationExpError> | null,
) => {
	return ([401, 504].includes(otpError?.status!)
		? sitecoreMessage
		: otpError?.message ?? 'Unable to parse error') as string;
};

/**
 * This method returns the image path without hostname
 */
export const getCleanImageSrc = (src?: string) => {
	const isValidURL =
		src?.toLowerCase().substring(0, 8) === 'https://' ||
		src?.toLowerCase().substring(0, 7) === 'http://';

	if (!src || !isValidURL) {
		return src;
	}

	const urlObj = new URL(String(src));
	const newURL = urlObj?.href?.replace(urlObj?.origin, '');

	return newURL;
};

/**
 * This method returns JSS image field without hostname
 */
export const getFormattedJSSImage = (imgField: JssImageType | undefined) => {
	if (!imgField) {
		return {
			value: {
				alt: '',
				src: '',
			},
		};
	}

	return {
		value: {
			alt: imgField?.value?.alt,
			src: getCleanImageSrc(imgField?.value?.src) ?? '',
		},
	};
};

/**
 * @description Parses richtext string with image into HTML element
 * to find the img src. It then uses `getCleanImageSrc` method.
 * @param {string | JssTextType} field - Sitecore field value
 * @returns {string}
 */
export const getFormattedRichTextWithImage = (field: string | JssTextType) => {
	const el = document.createElement('html');
	el.innerHTML = typeof field === 'string' ? field : field?.value;

	const imgCollection = el.getElementsByTagName('img');

	for (let i = 0; i < imgCollection?.length; i++) {
		const cleanImgSrc = getCleanImageSrc(imgCollection[i]?.src);
		if (typeof cleanImgSrc === 'string') {
			imgCollection[i].src = cleanImgSrc;
		}
	}

	const newRichText = el.getElementsByTagName('body')[0].innerHTML;

	return typeof field === 'string'
		? newRichText
		: ({ value: newRichText } as JssTextType);
};
