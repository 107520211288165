import styled from 'styled-components';

export const Container = styled.div`
	header {
		transition: all 2s ease;
		transform: translateY(176px);

		@keyframes goDown {
			0% {
				@-webkit-transform: translate(0);
			}

			100% {
				@-webkit-transform: translateY(176px);
			}
		}

		animation: goDown 2s ease;
		animation-iteration-count: 1;
	}
`;
