import React from 'react';

import * as S from './LayoutPadding.style';

export interface IProps {
	children: React.ReactNode;
}

const LayoutPadding = ({ children }: IProps) => (
	<S.Padding>{children}</S.Padding>
);

export default LayoutPadding;
