export default {
	DATA_LAYER: {
		DOESNT_EXIST:
			'!! Attempted to send an analytics event but no `dataLayer` object was found to push it to; GTM not initialised.',
		EVENT: 'Event details:',
	},

	SITECORE: {
		INACTIVE:
			"GTM is not enabled in Sitecore's Experience Editor, so this tracking event purposefully did not fire.",
	},
};
