/* eslint-disable react-hooks/rules-of-hooks */
import config from 'jss-boilerplate/temp/config.js';

import api from './api/useCreateGUID.api';

export function useCreateGUID() {
	const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathPartner}`;

	const { mutate: requestGuid, error, loading } = api.useCreateGUID({
		base,
	});

	return { requestGuid, error, loading };
}
