// TODO
// - disabled colours
// - form colours
// - isolated modules pods/theme/index default import
// - rename BackgroundContainer...? Theme container? Nah?
// - rename theme.js storybook stuff
// - provide all theme colours in the ThemeProvider at root level?
// 	-- have BackgroundContainer also implement/override ThemeProvider?
// - will page background color become a brand colour?
// - update other brand colours changing (more shades of grey)
// - should cta CtaStateType just be CSS blobs? Way more flexible (for things like underlines?)

import { css, FlattenSimpleInterpolation as CSS } from 'styled-components';

import { BackgroundContainerProps } from 'pods/theme/components/BackgroundContainer';

// ---------------------------------

export { default as localTheme } from './local';

// ---------------------------------
// Core functions

interface Composition {
	id: string;
	name: string;
	color: string;
	isDark: boolean;
	text: string;
}

export interface CtaStateType {
	color: string | null;
	text: string;
	border: string | null;
}

export interface CtaType {
	base: CtaStateType;
	hover: CtaStateType;
	active: CtaStateType;
	disabled: CtaStateType;
	alert: CtaStateType;
	loading: CtaStateType;
	inverted: Omit<CtaType, 'inverted'>;
}

export interface IThemeObject {
	global: {
		logos: {
			dark?: string;
			light?: string;
		};

		page: {
			color: string;
			isDark: boolean;

			breadcrumbs: {
				color: string;
				isDark: boolean;
			};

			unauthenticatedFooter: { color: string; isDark: boolean };
		};

		module: {
			color: string;
			isDark: boolean;
		};

		notifications: {
			info: string;
			error: string;
			success: string;
			warning: string;
			loading: string;
			close: string;
			color: string;
		};

		typography: {
			colors: {
				default: string;
				subdued: string;
				active: string;
				error: string;
				success: string;

				genericDark: string;
				genericLight: string;
			};

			hyperlinks: {
				color: string;
				isDark: boolean;
			};

			highlight: {
				color: string;
				text: string;
			};
		};

		ctas: {
			primary: CtaType;
			secondary: CtaType;
			plain: CtaType;
			warning: CtaType;
		};

		colors: {
			primary: Composition;
			secondary: Composition;
			tertiary: Composition;
			disabled: Composition;
		};

		borders: {
			hr: string;
			module: string;
		};
	};

	variables: {
		[key: string]: any;
	};

	core: {
		getCompositionColor: (id?: string) => string | undefined;
		getCompositionText: (id?: string) => string | undefined;
		getCompositionIsDark: (id?: string) => boolean | undefined;
	};

	css: {
		getText: (id?: string) => CSS | '';
		getDarkText: () => CSS;
		getLightText: () => CSS;
		getBackgroundAndText: (id?: string) => CSS;
	};

	util: {
		isBrandColor: (id?: string) => boolean;
		shouldCtaInvert: (bgProps: BackgroundContainerProps) => boolean;
	};
}

export const getThemeHelpers = (
	theme: IThemeObject & {
		id: string;
		name: string;
		compositions: Composition[];
	},
): object => {
	const _getBrandParam = (id?: string, param?: keyof Composition) => {
		if (!id || !param || typeof id !== 'string') return undefined;

		// Normalise both comparison names to lower case
		id = id.toLowerCase();
		const colorObj = theme.compositions.find(
			obj => obj.id.toLowerCase() === id,
		);

		return colorObj?.[param] ?? undefined;
	};

	/**
	 * These tags are targeted specifically because they are
	 * the same tag set that have the base text colours applied
	 * to them in GlobalStyles.js.
	 *
	 * @param {string} color
	 */
	const _applyTextColors = (color: string): CSS => css`
		/* Child selectors will inherit this  */
		color: ${color};
	`;

	// ----------------------------------------
	// Core functions - interacting directly with the `Composition` object

	const core = {
		/**
		 * Returns `color` param from associated `Composition` object.
		 *
		 * @param {string} id - `Composition` id.
		 */
		getCompositionColor: (id?: string): string | undefined =>
			id ? (_getBrandParam(id, 'color') as string) : undefined,

		/**
		 * Returns `text` param from associated `Composition` object.
		 *
		 * @param {string} id - `Composition` id.
		 */
		getCompositionText: (id?: string): string | undefined =>
			_getBrandParam(id, 'text') as string,

		/**
		 * Returns `isDark` param from associated `Composition` object.
		 *
		 * @param {string} id - `Composition` id.
		 */
		getCompositionIsDark: (id?: string): boolean =>
			!!_getBrandParam(id, 'isDark'),
	};

	// ----------------------------------------
	// CSS functions - helper functions to return `Composition` configured CSS

	/**
	 * Retrieves and applies relevant CSS `color` to common
	 * nested text selectors, according to the provided
	 * background color. Text color is defined alongside the
	 * `id` (provided) in the associated `Composition` theme object.
	 *
	 * @param {string} id - `Composition` id.
	 */
	const getText = (id?: string): CSS | '' => {
		const textColor = core.getCompositionText(id);

		return textColor ? _applyTextColors(textColor) : '';
	};

	/**
	 * Applies both background color /and/ text CSS.
	 * Shorthand for combined `getCompositionColor` and `getText`.
	 *
	 * @param {string} id - `Composition` id.
	 */
	const getBackgroundAndText = (id?: string): CSS => css`
		background-color: ${core.getCompositionColor(id)};
		${getText(id)}
	`;

	const getDarkText = (): CSS =>
		_applyTextColors(theme.global.typography.colors.genericDark);
	const getLightText = (): CSS =>
		_applyTextColors(theme.global.typography.colors.genericLight);

	// ----------------------------------------
	// Util functions - useful enquiries into the nature of a `Composition`

	const isBrandColor = (id?: string): boolean => !!core.getCompositionColor(id);

	/**
	 * Determines when it's appropriate for a Cta's "inverted" style to be
	 * applied by comparing the Cta's `isDark` with the provided bgColor and
	 * bgImage shade colour, where applicable.
	 *
	 * @param {string} bgProps - BackgroundContainer props object.
	 */
	const shouldCtaInvert = (bgProps: BackgroundContainerProps): boolean => {
		const { bgColor, bgImage } = bgProps;

		// Normalise strict type checks to lowercase
		let { bgImageShadeColor = '', textColor = '' } = bgProps;
		textColor = textColor.toLowerCase();
		bgImageShadeColor = bgImageShadeColor.toLowerCase();

		// If applicable, check background image shade, taking into account
		// the manual black/white settings also available to image shade overlays
		const isBgImageShade = !!bgImage && !!bgImageShadeColor;
		const isBlackShade = isBgImageShade && bgImageShadeColor === 'black';
		const isWhiteShade = isBgImageShade && bgImageShadeColor === 'white';
		const shadeIsDark =
			isBgImageShade &&
			(isBlackShade
				? true
				: isWhiteShade
				? false
				: core.getCompositionIsDark(bgImageShadeColor));

		// Determine the `Composition` currently applied to the module:
		// bgImage shade when applicable, or bgColor
		const Composition = isBgImageShade ? bgImageShadeColor : bgColor;
		const brandColorIsDark = isBgImageShade
			? shadeIsDark
			: core.getCompositionIsDark(Composition);

		// Determine whether BackgroundContainer textColor prop override is valid,
		// i.e. when bgImage is present but no overlay. Ternary if/else fallback to global module default
		const textColorIsDark =
			textColor === 'dark'
				? false
				: textColor === 'light'
				? true
				: theme.global.module.isDark;
		const hasValidTextOverride = textColor && bgImage && !bgImageShadeColor;

		return hasValidTextOverride ? textColorIsDark : brandColorIsDark;
	};

	return {
		core,
		css: {
			getText,
			getBackgroundAndText,
			getDarkText,
			getLightText,
		},
		util: {
			isBrandColor,
			shouldCtaInvert,
		},
	};
};
