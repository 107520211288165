import React from 'react';

import { useOutsideClick } from '../useOutsideClick';
import { useToggle } from '../useToggle';
import * as S from './DropdownWrapper.style';

export interface IDropdownProps
	extends Omit<React.HTMLAttributes<HTMLElement>, 'style'> {
	/** The top offset of the dropdown panel */
	topOffset?: number;
	children: React.PropsWithChildren<{}>;
	alignment?: 'right' | 'left' | 'center';
}

interface IUseDropdown {
	Dropdown: React.FunctionComponent<IDropdownProps>;
	isExpanded: boolean;
	toggleDropdown: () => void;
}

/**
 * A hook for using Dropdown wrapper.
 *
 * @param ref 				 - Element ref of the toggle button of the Dropdown panel for enabling closing by clicking outside
 * @param initialState - The initial state for the Dropdown to expand or collapse
 */
export const useDropdown = (
	ref: React.RefObject<any>,
	initialState = false,
): IUseDropdown => {
	const [isExpanded, toggleDropdown] = useToggle(initialState);

	useOutsideClick(ref, toggleDropdown, [false]);

	const Dropdown = ({
		children,
		topOffset,
		alignment = 'center',
		...restProps
	}: IDropdownProps) => {
		return (
			<S.Wrapper
				isExpanded={isExpanded}
				aria-hidden={!isExpanded}
				topOffset={topOffset}
				alignment={alignment}
				{...restProps}
			>
				{children}
			</S.Wrapper>
		);
	};

	return { Dropdown, isExpanded, toggleDropdown };
};
