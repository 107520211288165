import React from 'react';

import * as S from './JssPlaceholder.style';

/**
 * Rendered when an exception (error) is thrown by any component
 * withing the Placeholder. Error is caught and details logged in console.
 */
const ErrorComponent = () => {
	return (
		<S.ErrorComponentWrapper>
			<S.ErrorComponent>
				<S.ErrorTitle>
					We&apos;re not able to display this information at the moment.
				</S.ErrorTitle>
				<S.ErrorDescription>
					Please refresh this page or try again later.
				</S.ErrorDescription>
			</S.ErrorComponent>
		</S.ErrorComponentWrapper>
	);
};

export default ErrorComponent;
