import { Member } from 'core/api/client/MemberApi';

/**
 * Get member name to display across member portal
 *
 * @description Return first name and last name combined for display purposes
 * @param member - current member object from MemberContext
 */
export const getDisplayName = (member?: Member): string => {
	if (!member) {
		return '';
	}

	return `${member?.first_name ?? ''} ${member?.last_name ?? ''}`.trim();
};
