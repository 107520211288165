import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const accountSwitchSplashKeyName = 'AccountSwitchSplash';
const accountSwitchModalKeyName = 'AccountSwitchModal';
const accountSwitchFromListKeyName = 'AccountSwitchFromList';
const accountSwitchToolTipKeyName = 'AccountSwitchToolTip';
const isAccountSwitchedFromDb = 'AccountSwitchedFromDb';

export const getAccountSwitchSplash = (): string => {
	const accountSwitchSplash = getLocalStorageItem(accountSwitchSplashKeyName);

	return accountSwitchSplash;
};

export const getAccountSwitchModal = (): string => {
	const accountSwitchModal = getLocalStorageItem(accountSwitchModalKeyName);

	return accountSwitchModal;
};

export const getAccountSwitchFromList = (): string => {
	const accountSwitchFromList = getLocalStorageItem(
		accountSwitchFromListKeyName,
	);

	return accountSwitchFromList;
};

export const setAccountSwitchFromList = (tokenValue: string) => {
	setLocalStorageItem(accountSwitchFromListKeyName, tokenValue);
};

export const setAccountSwitchSplash = (tokenValue: string) => {
	setLocalStorageItem(accountSwitchSplashKeyName, tokenValue);
};

export const setAccountSwitchModal = (tokenValue: string) => {
	setLocalStorageItem(accountSwitchModalKeyName, tokenValue);
};

export const removeAccountSwitchModal = () => {
	removeLocalStorageItem(accountSwitchModalKeyName);
};

export const removeAccountSwitchSplash = () => {
	removeLocalStorageItem(accountSwitchSplashKeyName);
};

export const removeAccountSwitchFromList = () => {
	removeLocalStorageItem(accountSwitchFromListKeyName);
};

export const setAccountSwitchToolTip = (tokenValue: string): void => {
	setLocalStorageItem(accountSwitchToolTipKeyName, tokenValue);
};

export const getAccountSwitchToolTip = (): string => {
	return getLocalStorageItem(accountSwitchToolTipKeyName);
};

export const removeAccountSwitchToolTip = () => {
	removeLocalStorageItem(accountSwitchToolTipKeyName);
};

export const getIsAccountSwitchedFromDb = () => {
	return getLocalStorageItem(isAccountSwitchedFromDb);
};

export const setIsAccountSwitchedFromDb = (tokenValue: string): void => {
	setLocalStorageItem(isAccountSwitchedFromDb, tokenValue);
};
