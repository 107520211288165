/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import { useGetAddress } from 'core/api/client/SystemValidatorApi';
import GetAddressMock from './mocks/GetAddress.mock.json';

export default smartMockApi({
	useGetAddress: {
		live: useGetAddress,
		mock: params => {
			return useGetMock({
				...params,
				payload: GetAddressMock,
			});
		},
	},
});
