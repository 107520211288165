import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const combineSuperAuthTokenFlagKeyName = 'CombineSuperAuthToken';

export const getCombineSuperAuthTokenFlag = (): string => {
	return getLocalStorageItem(combineSuperAuthTokenFlagKeyName);
};

export const setCombineSuperAuthTokenFlag = (tokenValue: string) => {
	setLocalStorageItem(combineSuperAuthTokenFlagKeyName, tokenValue);
};

export const removeCombineSuperAuthTokenFlag = () => {
	removeLocalStorageItem(combineSuperAuthTokenFlagKeyName);
};
