/**
 * The only consistent way we can distinguish a Promise from
 * other object is whether it has a .then() function.
 *
 * Ref: https://stackoverflow.com/a/27746324.
 *
 * @param {Object} obj
 * @returns {boolean}
 */
export function isPromise(obj) {
	const thenFn = obj && obj.then;

	return !!thenFn && typeof thenFn === 'function';
}

/**
 * Strict object typecheck:
 * - Is type 'object'.
 * - Not a function or an array (these are also type 'object').
 * - Not null (typeof 'object' returns true for this too).
 * - Not a 'Promise' type object.
 *
 * @param {Object} obj
 * @returns {boolean}
 */
export function isObject(obj) {
	const correctType = typeof obj === 'object' && obj === Object(obj);
	const notArray = !Array.isArray(obj);
	const notFunction = typeof obj !== 'function';
	const notNull = obj != null;
	const notPromise = !isPromise(obj);

	return correctType && notArray && notFunction && notNull && notPromise;
}

/**
 * JSON parse try/catch check.
 *
 * @param {string} str - JSON string or JavaScript object.
 * @returns {boolean}
 */
export function isValidJSON(str) {
	if (typeof str !== 'string') {
		if (isObject(str)) {
			str = JSON.stringify(str);
		} else {
			return false;
		}
	}

	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}

	return true;
}

/**
 * Loop through nested object keys ensuring they exist, and creating them if they dont.
 * Optionally set a terminal value at the end of the path. This is so one can confidently
 * assign values to nested keys when their existence isn't guaranteed.
 *
 * @param {Object} obj
 * @param {string[]} path - The prospect path.
 * @param {any} terminalValue - Value to set at the end of the path to.
 *
 * @returns {Object} Returns back the object (if required). Note that alone this function already mutates the object passed into it.
 */
export function objSet(obj, path, terminalValue) {
	let branch = obj;

	path.forEach((nextBranch, idx) => {
		if (idx === path.length - 1) {
			branch[nextBranch] = terminalValue;
		} else if (!branch[nextBranch]) {
			branch[nextBranch] = {};
		}

		branch = branch[nextBranch];
	});

	return obj;
}
