import { isWebBrowser } from 'core/utils/global-utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getSessionCookieItem = (key: string): string => {
	if (!isWebBrowser || !document?.cookie) {
		// skip use of Cookie in server side rendering
		return '';
	}

	const itemValue = cookies.get(key) ?? '';

	return itemValue;
};

export const setSessionCookieItem = (key: string, itemValue: string) => {
	if (!isWebBrowser || !document?.cookie) {
		// skip use of Cookie in server side rendering
		return;
	}
	cookies.set(key, itemValue, { path: '/' });
};

export const removeSessionCookieItem = (key: string) => {
	if (!isWebBrowser || !document?.cookie) {
		// skip use of Cookie in server side rendering
		return;
	}
	cookies.remove(key, { path: '/' });
};

export const clearSessionCookie = () => {
	if (!isWebBrowser || !document?.cookie) {
		// skip use of Cookie in server side rendering
		return;
	}

	Object.keys(cookies.getAll() as object).map(key =>
		removeSessionCookieItem(key),
	);
};
