import React from 'react';

import * as Typo from 'core/styles/Typography';
import { Icon, ICONS } from 'core/components/Icon';
import * as AC from 'core/styles/AnimationComponents';

import * as S from './LoginSpinner.style';

interface IProps {
	dataTargetId?: string;
}

export const LoginSpinner = ({ dataTargetId = 'login-spinner' }: IProps) => (
	<S.Wrapper>
		<S.Container>
			<Typo.DisplaySmall data-target-id={`${dataTargetId}--spinner-label`}>
				{`Logging in...`}
			</Typo.DisplaySmall>
			<AC.Rotate>
				<Icon name={ICONS.SPINNER} size={24} />
			</AC.Rotate>
		</S.Container>
	</S.Wrapper>
);
