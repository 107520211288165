import React from 'react';

import { isWebBrowser } from 'core/utils/global-utils';

import * as S from './StickyPosition.style';

interface IStickWrapper extends React.HTMLAttributes<{}> {
	/** A single node that should should be sticky at a position */
	children: React.ReactNode;
	/** Height to preserve the space before the ref is created */
	fallbackHeight: number;
	/** State of the sticky wrapper – whether in 'fixed mode' or not */
	isFixedOnTop: boolean;
}

/**
 * A hook to stick element on its position, e.g. sticky header.
 *
 * @param ref - react ref object of the sticky element
 */
export const useStickyPosition = (ref: React.RefObject<HTMLElement>) => {
	const [isFixedOnTop, setFixedOnTop] = React.useState(false);
	const [topOffset, setTopOffset] = React.useState(0);

	/**
	 * Handles the window scrolling event to fix the element when scroll down,
	 *   and restore its position when scroll back to top.
	 */
	const handleWindowScroll = React.useCallback(() => {
		const scrollY = isWebBrowser ? window?.scrollY : 0;
		if (!isFixedOnTop && topOffset < scrollY) {
			// Set to true when the ref element is about to be hidden by the current viewport
			setFixedOnTop(true);
		} else if (isFixedOnTop && topOffset >= scrollY) {
			// Set to false when the ref element is back to its original position on the top
			setFixedOnTop(false);
		}
	}, [topOffset, isFixedOnTop]);

	React.useEffect(() => {
		if (!isFixedOnTop && ref.current) {
			// Set the offset top of the ref element when it is not fixed on the top
			// NOTE: this is to ensure always using the top offset when the ref element is not sticky to the top
			setTopOffset(ref.current.offsetTop);
		}

		// NOTE: in case of styles not fully loaded, which causes the offset top changes after component mounted
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFixedOnTop, ref.current]);

	React.useEffect(() => {
		if (isWebBrowser) {
			window?.addEventListener('scroll', handleWindowScroll);

			return () => window?.removeEventListener('scroll', handleWindowScroll);
		}
	}, [handleWindowScroll]);

	/** NOTE: Using useCallback and passing 'elementHeight' and 'isFixedOnTop' values in as props
	 * 	so that the wrapper's reference is saved, and isn't destroyed and re-mounted each state change */
	const StickyWrapper = React.useCallback(
		({ children, fallbackHeight, isFixedOnTop, ...props }: IStickWrapper) => (
			<S.StickyContainer
				{...{
					fallbackHeight,
					isFixedOnTop,
					elementHeight: ref.current?.clientHeight,
					...props,
				}}
			>
				{children}
			</S.StickyContainer>
		),
		[],
	);

	return {
		isFixedOnTop,
		StickyWrapper,
	};
};
