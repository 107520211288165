import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const lastUrlAccessKeyName = 'LastUrlAccess';

export const getLastUrlAccess = (): string => {
	const accessToken = getLocalStorageItem(lastUrlAccessKeyName);

	return accessToken;
};

export const setLastUrlAccess = (tokenValue: string) => {
	setLocalStorageItem(lastUrlAccessKeyName, tokenValue);
};

export const removeLastUrlAccess = () => {
	removeLocalStorageItem(lastUrlAccessKeyName);
};
