import React from 'react';

import * as AC from 'core/styles/AnimationComponents';
import * as S from './LoadingDots.style';

interface IProps {
	dotColor: string;
}

const LoadingDots = ({ dotColor }: IProps) => (
	<S.InlineFlex>
		<AC.ThreeDots {...{ dotColor }}>
			<span />
		</AC.ThreeDots>
	</S.InlineFlex>
);

export default LoadingDots;
