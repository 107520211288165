import moment from 'moment';
import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const importantNoticeKeyName = 'ImportantNoticeKeyName';

export type ImportantNoticeProps = {
	hash: number;
	publishEndDate?: string;
	expired?: boolean;
};

export const getImportantNoticeFlag = (
	serverDateTime: Date,
): ImportantNoticeProps[] => {
	const importantNoticeFlag = getLocalStorageItem(importantNoticeKeyName);

	if (importantNoticeFlag) {
		try {
			// This will cleanup the past dates versus the current date to avoid storing a lot of data in localStorage.
			// Will check for past dates and tag it as expired.
			const itemArray = JSON.parse(importantNoticeFlag)?.map(
				(i: ImportantNoticeProps) => ({
					...i,
					expired: moment(i.publishEndDate).isBefore(
						moment(new Date(serverDateTime)),
					),
				}),
			);
			// Filter those data which are not yet expired.
			const cleanUpArray =
				itemArray.filter((c: ImportantNoticeProps) => c.expired === false) ??
				[];

			// Update localStorage
			setLocalStorageItem(importantNoticeKeyName, JSON.stringify(cleanUpArray));

			return cleanUpArray;
		} catch (error) {
			return [];
		}
	}

	return [];
};

export const setImportantNoticeFlag = (tokenValue: ImportantNoticeProps) => {
	const item = getLocalStorageItem(importantNoticeKeyName);
	const notifArray = [];

	if (item) {
		notifArray.push(...item, tokenValue);
	} else {
		notifArray.push(tokenValue);
	}

	setLocalStorageItem(importantNoticeKeyName, JSON.stringify(notifArray));
};

export const removeImportantNoticeFlag = () => {
	removeLocalStorageItem(importantNoticeKeyName);
};
