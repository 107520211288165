import styled from 'styled-components';

export const Wrapper = styled.header`
	background-color: ${({ theme }) => theme.global.page.color};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 59px 0 39px;

	img {
		width: 237px;
	}
`;
