import { useHistory } from 'react-router-dom';

/**
 * Hook to go back to previous history record safely.
 *
 * @description Go back to the previous page in history.
 *   If no history exist then go one directory up.
 */
export const useGoBackSafe = () => {
	const history = useHistory();

	const goBackSafe = () => {
		if (history.length > 1) {
			history.goBack();
		} else {
			const currentPath = history.location?.pathname ?? '';
			// remove the last directory from the path '/<any-non-space-character>
			const parentPath = currentPath.replace(/(?:\/[^\s\/]*)$/, '');

			history.push(parentPath ?? '/');
		}
	};

	return { goBackSafe };
};
