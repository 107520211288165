/* eslint-disable @typescript-eslint/camelcase */
import config from 'jss-boilerplate/temp/config.js';

import { useMemberContext } from 'core/hooks';
import { getSelectedMemberAccount } from 'core/sitecore/member';

import api from './api';

type ProductName = 'Accumulation' | 'Pension';

/**
 * This function retrieves the account balance of specified account.
 */
export function usePopulateBalanceForDefaultAccount(
	isFromSwitch: boolean,
	acctFromSwitch?: string,
	productFromSwitch?: string,
) {
	const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathAccount}`;

	const { accounts = [] } = useMemberContext();
	const selectedAccount = getSelectedMemberAccount(accounts);
	const productClassification = selectedAccount?.product_classification ?? '';
	// Two sources: one from header nagivation, the other is from account switcher
	const accountNumber = isFromSwitch
		? acctFromSwitch
		: selectedAccount?.account_number;
	const productName = isFromSwitch
		? productFromSwitch
		: selectedAccount?.product_name;

	const { data: response, loading, error, refetch } = api.useGetBalances({
		base,
		product_name: productName as ProductName,
		account_number: accountNumber as string,
	});

	const balance = response?.data?.balances[0]?.fy_totals[0]?.amount;

	return {
		accountNumber,
		productName,
		productClassification,
		balance,
		loading,
		error,
		refetch,
	};
}
