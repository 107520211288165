import React from 'react';

import Cta from 'core/components/Cta';
import { ICONS, Icon } from 'core/components/Icon';

import { getDisplayedPageNumbers } from './Pagination.helpers';
import * as S from './Pagination.style';

interface IPageIndicator extends Partial<Pick<IPagination, 'setCurrent'>> {
	page?: number;
	isActive?: boolean;
}

/**
 * PaginationItem component to handle the page navigation.
 *
 * @note component that indicate hidden page numbers is hidden from the ScreenReaders to avoid confusion.
 */
const PaginationItem = ({
	page,
	isActive = false,
	setCurrent,
}: IPageIndicator) => (
	<S.PaginationItem isActive={isActive} isHiddenPageIndicator={!page}>
		<Cta
			ctaStyle="plain"
			ctaType={isActive || !page ? 'cosmetic' : 'button'}
			{...(setCurrent && page && { onClick: () => setCurrent(page) })}
			{...(page
				? {
						'aria-label': `${
							isActive ? `Currently on page ${page}` : `Go to page ${page}`
						}`,
						'aria-current': isActive,
				  }
				: { 'aria-hidden': true, 'tabIndex': -1 })}
		>
			{page ?? '...'}
		</Cta>
	</S.PaginationItem>
);

interface IPagination {
	/** Total number of the page */
	total: number;
	/** Current page number */
	current: number;
	/** Set the current page number */
	setCurrent: React.Dispatch<React.SetStateAction<number>>;
	/** Increment the current page number by one */
	handleForward: () => void;
	/** Decrement the current page number by one */
	handleBackward: () => void;
	/** Data target id for testing */
	dataTarget?: string;
}

/**
 * Pagination component.
 *
 * @description Always has maximum four clickable page indicators and a indicator for the current page.
 */
const PaginationBar = ({
	total = 5,
	current = 2,
	setCurrent,
	handleForward,
	handleBackward,
	dataTarget = 'pagination',
}: IPagination) => {
	return (
		<S.PaginationNavBar role="navigation" aria-label="pagination navigation">
			{current !== 1 && (
				<S.ArrowContainer>
					<Cta
						ctaStyle="plain"
						ctaType="button"
						aria-label="Go to previous page."
						data-target-id={`${dataTarget}--backward-btn`}
						onClick={handleBackward}
					>
						<Icon name={ICONS.ARROW_BACK} />
					</Cta>
				</S.ArrowContainer>
			)}
			<S.PageNumberList data-target-id={`${dataTarget}--page-number-list`}>
				{getDisplayedPageNumbers(current, total).map(
					(pageNumber, index, displayedPageNumbers) => (
						<React.Fragment key={`pagination-${pageNumber}`}>
							{!!index &&
								displayedPageNumbers[index - 1] + 1 !== pageNumber && (
									<PaginationItem />
								)}
							<PaginationItem
								page={pageNumber}
								isActive={current === pageNumber}
								setCurrent={setCurrent}
							/>
						</React.Fragment>
					),
				)}
			</S.PageNumberList>
			{current !== total && (
				<S.ArrowContainer isForwardArrow>
					<Cta
						ctaStyle="plain"
						ctaType="button"
						aria-label="Go to next page."
						data-target-id={`${dataTarget}--forward-btn`}
						onClick={handleForward}
					>
						<Icon name={ICONS.ARROW_FORWARD} />
					</Cta>
				</S.ArrowContainer>
			)}
		</S.PaginationNavBar>
	);
};

export default PaginationBar;
