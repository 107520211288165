import isArray from 'lodash/isArray';

import { PaymentCalendar, PensionDetails } from 'core/api/client/PaymentApi';
import { ValueReferenceDataType } from 'core/api/client/ReferenceDataApi';
import { mapCodeToDescription } from 'core/utils/global-utils';
import { PAYMENT_STATUS_CODE, PAYMENT_FREQUENCY } from 'core/constants';

/**
 * Get the payment details based on the payment status code.
 *
 * @param {array} paymentCalendar - an array of payment details retrieved from the payment api.
 * @param {status} status         - payment status code.
 * @returns {array}               - payment details found by the same status code
 */
export const getPaymentsByStatus = (
	paymentCalendar: PaymentCalendar[] | undefined,
	status: string,
) => {
	// TODO: error handling on unexpected data type from api
	if (!isArray(paymentCalendar)) return [];

	const payments = paymentCalendar.filter(
		payment => payment.payment_status_code === status,
	);

	return payments;
};

/**
 * Parse the payment api response with payment frequency reference.
 *
 * @note With the fetched response:
 *   - find the past payments (previousPayments) and sorted by the paid date (most recent at the start)
 *   - find the information about the upcoming payment: frequency, due date, and amount
 *   - **if no upcoming payment found, `nextPaymentInfo` set to `null`**
 * @param pensionDetails          - member's pension details
 * @param paymentFrequencyRefData - payment frequency reference data to map frequency type to displayed text
 * @param paymentTypeRefData - payment type reference data to map frequency type to displayed text
 */
export const parsePaymentResponse = (
	pensionDetails: PensionDetails[],
	paymentFrequencyRefData: ValueReferenceDataType[] | undefined,
	paymentTypeRefData: ValueReferenceDataType[] | undefined,
) => {
	const [
		{
			payment_calendar: paymentCalendar,
			payment_frequency_details: paymentFrequencyDetail,
			total_paid_ytd: totalPaymentToDate,
			current_pension_nomination_details: paymentDetails,
			payment_calendar_total_paid: paymentCalendarTotalPaid,
			next_payment_date: nextPaymentDate,
		},
	] = pensionDetails;
	const totalPayments = totalPaymentToDate?.total_payment ?? 0;
	// Note: the value is true, if all items in the payment calendar array has payment_status_code as 'PAID'
	const isPaymentCompleteYtd = totalPaymentToDate?.is_payment_complete_ytd;

	const paymentFrequencyDescription = mapCodeToDescription(
		paymentFrequencyDetail?.payment_frequency,
		paymentFrequencyRefData,
	).toLowerCase(); // i.e. 'YEARLY' to 'yearly'

	// find the upcoming payment amount and date
	const [nextPayment] = getPaymentsByStatus(
		paymentCalendar,
		PAYMENT_STATUS_CODE.AWAITING_PAYMENT,
	);

	const checkSemiyearly = () => {
		const paymentFrequencyLabel = paymentFrequencyDetail?.payment_frequency?.toUpperCase();

		if (
			paymentFrequencyLabel === PAYMENT_FREQUENCY.SEMIYEARLY ||
			paymentFrequencyLabel === PAYMENT_FREQUENCY.HALF_YEARLY
		) {
			return PAYMENT_FREQUENCY.HALF_YEARLY.toLowerCase();
		}

		return paymentFrequencyLabel?.toLowerCase() ?? '';
	};
	// assign null to nextPaymentInfo if there is no upcoming payment
	const nextPaymentInfo = nextPayment
		? {
				paymentFrequency: checkSemiyearly(),
				paymentFrequencyDesc: paymentFrequencyDescription,
				amount: nextPayment.gross_installment_pension,
				nextPaymentDate: nextPaymentDate,
				paymentPayableDate: nextPayment.payment_payable_date,
		  }
		: null;

	// find the past payments
	const pastPayments = getPaymentsByStatus(
		paymentCalendar,
		PAYMENT_STATUS_CODE.PAID,
	);
	// NOTE: the payment summary uses only the latest one but Db payment information requires a sorted array.
	const sortedPayments = pastPayments
		.sort(
			(prev, next) =>
				// sort the payments array with paid_date in descending order
				Date.parse(next.paid_date!) - Date.parse(prev.paid_date!),
		)
		.map(payment => ({
			amount: payment.gross_installment_pension,
			date: payment.paid_date,
		}));

	// Convert code to description
	const paymentTypeToDesc = paymentTypeRefData?.find(ptrd => {
		return ptrd.code === paymentDetails?.payment_type?.toUpperCase();
	})?.description as string;

	return {
		totalPayments,
		nextPaymentInfo,
		isPaymentCompleteYtd,
		previousPayments: sortedPayments,
		paymentFrequency: paymentFrequencyDetail?.payment_frequency ?? '',
		paymentType: paymentDetails?.payment_type ?? '',
		paymentTypeDesc: paymentTypeToDesc ?? '',
		paymentDay: paymentFrequencyDetail?.day_of_month1 ?? 0,
		paymentIndexFactor: paymentDetails?.index_factor?.replace('%', '') ?? '',
		amountPerPayment: paymentDetails?.value ?? 0,
		paymentCalendarTotalPaid:
			paymentCalendarTotalPaid?.gross_installment_pension ?? 0,
	};
};
