import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const redirectUrlName = 'RedirectUrl';

export const getRedirectUrl = (): string => {
	return getLocalStorageItem(redirectUrlName);
};

export const setRedirectUrl = (tokenValue: string) => {
	setLocalStorageItem(redirectUrlName, tokenValue);
};

export const removeRedirectUrl = () => {
	removeLocalStorageItem(redirectUrlName);
};
