import { isJwtExpired } from 'jwt-check-expiration';
import {
	TransactionDetail,
	TransactionClaim,
	TransactionContribution,
	TransactionTransferIn,
} from 'core/api/client/TransactionApi';
import {
	clearSessionCookie,
	getAccessToken,
	getRefreshToken,
	getSessionStorageItem,
	setAccessToken,
	setDisplayLogoutSuccessFlag,
	setRefreshToken,
	setSessionStorageItem,
} from 'core/browserStorage';
import { memberRefreshToken } from 'core/api';
import { LOGOUT_STATUS, SITECORE_PAGES } from 'core/constants';

export enum ResponseCodes {
	Response200 = 200,
	Response401 = 401,
	Response404 = 404,
	Response500 = 500,
}

export const TOKEN_REFRESH_KEY = 'isTokenRefreshOngoing';

/**
 * Get error message from API error object
 *
 * @param {any} error                       - error object from Restful-React resonse
 * @param {ResponseCodes[]} ignoreResponses - if provided, ignore error message for this list of response error codes
 * @param {string} sitecoreMessage          - optional, custom error message - data source can be from Sitecore.
 */
export const getErrorMessage = (
	error: any,
	ignoreResponses: ResponseCodes[] = [],
	sitecoreMessage?: string,
): string => {
	if (!error || !error.message) {
		// no error
		return '';
	}

	if (!error.status) {
		// error contains message but contains NO status, likely a browser side connection issue or CORS error
		return sitecoreMessage ?? error.message;
	}

	if (!ignoreResponses.includes(error.status)) {
		// error contains a status, which is considered a false positive
		return sitecoreMessage ?? error.message;
	}

	// default scenario
	return '';
};

{
	/**---------------------TRANSACTIONS--------------------*/
}

/**
 * Type guard for TransactionDetail - checking if props is in TransactionClaim
 * @param txnDetails - TransactionDetail
 */
export function isTransactionClaim(
	txnDetails?: TransactionDetail,
): txnDetails is TransactionClaim {
	return (txnDetails as TransactionClaim)?.claim_distribution !== undefined;
}

/**
 * Type guard for TransactionDetail - checking if props is in TransactionContribution
 * @param txnDetails - TransactionDetail
 */
export function isTransactionContribution(
	txnDetails?: TransactionDetail,
): txnDetails is TransactionContribution {
	return (txnDetails as TransactionContribution)?.employer_number !== undefined;
}

/**
 * Type guard for TransactionDetail - checking if props is in TransactionTransferIn
 * @param txnDetails - TransactionDetail
 */
export function isTransactionTransferIn(
	txnDetails?: TransactionDetail,
): txnDetails is TransactionTransferIn {
	return (txnDetails as TransactionTransferIn)?.fund_name !== undefined;
}

export function doRefreshToken() {
	let isAccesTokenExpired;
	const isTokenRefreshOngoing = getSessionStorageItem(TOKEN_REFRESH_KEY);

	if (isTokenRefreshOngoing !== '1') {
		try {
			isAccesTokenExpired = isJwtExpired(getAccessToken());
		} catch (e) {
			// Token retrieval error
			return Promise.reject(
				'token retrieval error, user likely unauthenticated',
			);
		}

		if (isAccesTokenExpired) {
			setSessionStorageItem(TOKEN_REFRESH_KEY, '1');
			const rToken = getRefreshToken();

			return memberRefreshToken(rToken)
				.then(result => {
					if (result.status === 200) {
						setSessionStorageItem(TOKEN_REFRESH_KEY, '0');
						const { accessToken, refreshToken, isValid } = result?.data;

						if (isValid === true) {
							setAccessToken(accessToken);
							setRefreshToken(refreshToken);
						} else {
							sessionStorage.clear();
							localStorage.clear();
							clearSessionCookie();
							setDisplayLogoutSuccessFlag(LOGOUT_STATUS.LOGOUT_JWT);
							window.location.assign(SITECORE_PAGES.LOGIN);
						}
					}
				})
				.catch(() => {
					setSessionStorageItem(TOKEN_REFRESH_KEY, '0');
					// Redirect to login screen since token will be invalid hereon, user needs to relogin
					sessionStorage.clear();
					localStorage.clear();
					clearSessionCookie();
					setDisplayLogoutSuccessFlag(LOGOUT_STATUS.LOGOUT_JWT);
					window.location.assign(SITECORE_PAGES.LOGIN);
				});
		}
	}

	return Promise.resolve();
}
