import styled from 'styled-components';

import * as mx from 'core/styles/mixins';

const MOBILE_BP = 'm';

export const Wrapper = styled.div`
	background-color: ${({ theme }) =>
		theme.global.page.unauthenticatedFooter.color};
	padding: 20px 0 32px; /* Offset 12px li margin-top */

	${mx.bp(MOBILE_BP)`
		padding: 52px 0 32px; /* Offset 12px li margin-top */
	`}
`;

export const ListItem = styled.li`
	&,
	a {
		color: ${({ theme }) => theme.global.typography.colors.subdued};
		font-size: 12px;
		line-height: 20px;

		${mx.bp(MOBILE_BP)`
			font-size: 14px;
			line-height: 24px;
		`}
	}

	a {
		text-decoration: none;

		&:hover,
		&:focus {
			text-decoration: underline;
			color: ${({ theme }) => theme.global.typography.colors.active};
		}
	}
`;

export const List = styled.ul`
	${mx.listReset}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${ListItem} {
		margin: 12px 24px 0;
	}

	${mx.bp(MOBILE_BP)`
		flex-flow: row wrap;
	`}
`;
