/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_SEN_xxx
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of unread records for this object
	 */
	unread_count?: number;
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

export type ProductName = 'Accumulation' | 'Pension';

export interface RetrievePutSensResponse {
	data?: RetrievePutSensResponseType;
}

export interface RetrieveSensResponse {
	data?: RetrieveSensDataType;
	meta?: Meta;
}

export interface RetrievePutSensResponseType {
	unread_count?: number;
}

export interface RetrieveSensDataType {
	'significant-event-notices'?: RetrieveSenResponseDataType[];
}

export interface RetrieveSenResponse {
	data?: RetrieveSenResponseDataType;
}

export interface RetrieveSenResponseDataType {
	id: string;
	message_title: string;
	message: string;
	communication_date: string;
	is_read: boolean;
	communications_category: string;
	account: SenAccountDataType;
}

export interface SenAccountDataType {
	account_number: string;
	product_name: ProductName;
}

export interface PutSenRequest {
	data?: PutSenRequestData;
}

export interface PutSenRequestData {
	is_read: boolean;
}

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Forbidden
 */
export type Response403 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Conflict
 */
export type Response409 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetSensQueryParams {
	/**
	 * The date at which to end the search for sens.This field represents the from date. ISO8601 format.
	 */
	to_date?: string;
	/**
	 * The date at which to start the search for sens.This field represents the from date. ISO8601 format.
	 */
	from_date?: string;
	/**
	 * The page number requested, starting from 1
	 */
	offset?: number;
	/**
	 * The number of records per page
	 */
	limit?: number;
}

export type GetSensProps = Omit<
	GetProps<
		RetrieveSensResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetSensQueryParams,
		void
	>,
	'path'
>;

/**
 * Retrieves a collection of significant event notices details for a member
 */
export const GetSens = (props: GetSensProps) => (
	<Get<
		RetrieveSensResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetSensQueryParams,
		void
	>
		path={`/significant-event-notices`}
		{...props}
	/>
);

export type UseGetSensProps = Omit<
	UseGetProps<
		RetrieveSensResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetSensQueryParams,
		void
	>,
	'path'
>;

/**
 * Retrieves a collection of significant event notices details for a member
 */
export const useGetSens = (props: UseGetSensProps) =>
	useGet<
		RetrieveSensResponse,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		GetSensQueryParams,
		void
	>(`/significant-event-notices`, props);

export interface UpdateFormPathParams {
	/**
	 * Product type. i.e Accumulation or Pension
	 */
	product_name: ProductName;
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
	/**
	 * Identitfer of a significant event notices
	 */
	id: string;
}

export type UpdateFormProps = Omit<
	MutateProps<
		RetrievePutSensResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		void,
		PutSenRequest,
		UpdateFormPathParams
	>,
	'path' | 'verb'
> &
	UpdateFormPathParams;

/**
 * Edit a significant event notices details for an account
 */
export const UpdateForm = ({
	product_name,
	account_number,
	id,
	...props
}: UpdateFormProps) => (
	<Mutate<
		RetrievePutSensResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		void,
		PutSenRequest,
		UpdateFormPathParams
	>
		verb="PUT"
		path={`/significant-event-notices/${product_name}/${account_number}/${id}`}
		{...props}
	/>
);

export type UseUpdateFormProps = Omit<
	UseMutateProps<
		RetrievePutSensResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		void,
		PutSenRequest,
		UpdateFormPathParams
	>,
	'path' | 'verb'
> &
	UpdateFormPathParams;

/**
 * Edit a significant event notices details for an account
 */
export const useUpdateForm = ({
	product_name,
	account_number,
	id,
	...props
}: UseUpdateFormProps) =>
	useMutate<
		RetrievePutSensResponse,
		Response400 | Response401 | Response500 | ErrorResponseResponse,
		void,
		PutSenRequest,
		UpdateFormPathParams
	>(
		'PUT',
		(paramsInPath: UpdateFormPathParams) =>
			`/significant-event-notices/${paramsInPath.product_name}/${paramsInPath.account_number}/${paramsInPath.id}`,
		{ pathParams: { product_name, account_number, id }, ...props },
	);

export interface GetSenDocumentPathParams {
	/**
	 * Product type. i.e Accumulation or Pension
	 */
	product_name: ProductName;
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
	/**
	 * Identitfer of a significant event notices
	 */
	id: string;
}

export type GetSenDocumentProps = Omit<
	GetProps<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetSenDocumentPathParams
	>,
	'path'
> &
	GetSenDocumentPathParams;

/**
 * Retrieve a significant event notice document
 */
export const GetSenDocument = ({
	product_name,
	account_number,
	id,
	...props
}: GetSenDocumentProps) => (
	<Get<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetSenDocumentPathParams
	>
		path={`/documents/${product_name}/${account_number}/${id}`}
		{...props}
	/>
);

export type UseGetSenDocumentProps = Omit<
	UseGetProps<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetSenDocumentPathParams
	>,
	'path'
> &
	GetSenDocumentPathParams;

/**
 * Retrieve a significant event notice document
 */
export const useGetSenDocument = ({
	product_name,
	account_number,
	id,
	...props
}: UseGetSenDocumentProps) =>
	useGet<
		void,
		| Response400
		| Response401
		| Response403
		| Response500
		| ErrorResponseResponse,
		void,
		GetSenDocumentPathParams
	>(
		(paramsInPath: GetSenDocumentPathParams) =>
			`/documents/${paramsInPath.product_name}/${paramsInPath.account_number}/${paramsInPath.id}`,
		{ pathParams: { product_name, account_number, id }, ...props },
	);
