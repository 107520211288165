import React from 'react';

import * as S from './JssPlaceholder.style';

interface IProps {
	rendering: {
		componentName: string;
	};
}

/**
 * Rendered when a component has been specified within a Placeholder,
 * but no corresponding React component exists registered with JSS.
 */
const MissingComponent = ({ rendering }: IProps) => {
	const componentName = rendering?.componentName ?? 'Unnamed Component';

	return (
		<S.Wrapper color="goldenrod">
			<S.MissingComponent>
				<S.Title>
					<strong>Component missing:</strong>
					<br />
					<span>{`'${componentName}'`}</span>
				</S.Title>
				<S.Description>
					JSS component is missing React implementation. See the web console for
					more information.
				</S.Description>
			</S.MissingComponent>
		</S.Wrapper>
	);
};

export default MissingComponent;
