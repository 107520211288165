// Global form fields

export const ADDRESS = {
	COUNTRY: 'country',
	ADDRESS_LOOKUP: 'addressLookup',
	ADDRESS: 'address',
	STREET_1: 'address_line1',
	STREET_2: 'address_line2',
	/*** @note: added SUBURB_CITY for SMSF postal address, and leave SUBURB for contact details form  */
	SUBURB: 'city',
	SUBURB_CITY: 'suburb',
	STATE: 'state',
	POSTCODE: 'postcode',
	AU_POSTCODE: 'au_postcode',
	INTL_POSTCODE: 'intl_postcode',
	MANUAL_ADDRESS: 'manual',
	ADDITIONAL_ADDRESS: 'additionalAddress',
};

export const ADDRESS_TYPE = {
	RESIDENTIAL: 'RESIDENTIAL',
	POSTAL: 'POSTAL',
};

export const MANUAL_ADDRESS = 'manual';

export const STATE_POSTCODE = 'state-and-postcode';

export enum DOB {
	DAY = 'dob-day',
	MONTH = 'dob-month',
	YEAR = 'dob-year',
}
