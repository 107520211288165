import React from 'react';

/**
 * A hook to handle event when click outside of an element.
 *
 * @param ref      - React ref of the element
 * @param callback - Handler of the click event outside the element
 * @param args     - Arguments for the handler callback in array
 */
export const useOutsideClick = <T, K>(
	ref: React.RefObject<T>,
	callback: (...args: [K]) => void,
	args: [K] = [] as any,
) => {
	const handleClick = (e: MouseEvent) => {
		if (ref.current && !(ref.current as any).contains(e.target)) {
			// Invoke the callback if current target is not contained by the element node
			callback(...args);
		}
	};

	React.useEffect(() => {
		document.addEventListener('click', handleClick);

		return () => document.removeEventListener('click', handleClick);
	});
};
