import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const savedWithdrawalsDataKeyName = 'SavedWithdrawalsData';

export const getSavedWithdrawalsData = (): string => {
	return getLocalStorageItem(savedWithdrawalsDataKeyName);
};

export const setSavedWithdrawalsData = (tokenValue: string) => {
	setLocalStorageItem(savedWithdrawalsDataKeyName, tokenValue);
};

export const removeSavedWithdrawalsData = () => {
	removeLocalStorageItem(savedWithdrawalsDataKeyName);
};
