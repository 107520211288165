import styled from 'styled-components';

export const InlineFlex = styled.div`
	display: inline-flex;
	padding-top: 7px;
	padding-bottom: 7px;
	margin-left: -8px;
	justify-content: center;
	width: 70px;
`;
