// Component names in Sitecore
// Entries in this file are only relevant if they are being
// used as centralised keys in other files; e.g. in the
// adjacent ./placeholders.js definition file

const COMPONENT_NAMES = {
	SUB_LAYOUTS: {
		FULL_WIDTH: '100-SubLayout',
		FIFTY_FIFTY: '50-50 SubLayout',
		TWO_THIRDS_SPLIT: '66-33 SubLayout',
		ONE_THIRD_SPLIT: '33-66 SubLayout',
		THIRDS: '33-33-33 SubLayout',
		QUARTERS: '25-25-25-25 SubLayout',
	},
	HEADER: 'Header',
	NAV: 'Nav',
};

export default COMPONENT_NAMES;
