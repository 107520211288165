import React from 'react';
import { JssImageType } from 'core/utils/sitecore-utils';

import AppContainer from 'src/_AppContainer';
import AppPlaceholders from 'src/_AppPlaceholders';
import UnauthenticatedHeader from 'jss-components/t01-navigation/Header/subcomponents/UnauthenticatedHeader';
import { LoginSpinner } from 'jss-components/t07-account-access/Login/subComponents/LoginSpinner';
import UnauthenticatedFooter from 'jss-components/t01-navigation/Footer/subcomponents/UnauthenticatedFooter';
import { IFields as IFooterProps } from 'jss-components/t01-navigation/Footer/Footer.sitecore';

import * as S from './LoadingLayout.style';

interface IProps {
	headerLogo: JssImageType;
	footerProps: IFooterProps;
}

const LoadingLayout = ({ headerLogo, footerProps }: IProps) => {
	return (
		<AppContainer>
			<S.Container>
				<AppPlaceholders
					TopContent={() => <UnauthenticatedHeader logoSrc={headerLogo} />}
					MainContent={() => <LoginSpinner />}
					BottomContent={() => <UnauthenticatedFooter fields={footerProps} />}
				/>
			</S.Container>
		</AppContainer>
	);
};

export default LoadingLayout;
