import { useState, useEffect } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import config from 'jss-boilerplate/temp/config.js';
import { useAppContext, useMemberContext, useTrackEvent } from 'core/hooks';
import { doRefreshToken } from 'core/utils/api-utils';
import { getSelectedMemberAccount } from 'core/sitecore/member';
import { getAccessToken } from 'core/browserStorage';
import {
	AKAMAI_BOT_FLAG,
	QUALIFIED_NAME,
	X_CORRELATION_APP,
} from 'core/constants';
import { isStorybook } from 'stories/utils';

const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathNotifyEmployer}`;

/**
 * Custom hook for Notify Employer
 *
 * @param {string} formName - Custom form name
 * @returns {object} - Returns reponse, error and loading state
 */
export const useNotifyEmployer = (formName?: string) => {
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(false);
	const [errorData, setErrorData] = useState(``);
	const [loading, setLoading] = useState(false);
	const [downloadResponse, setDownloadResponse] = useState(null);
	const [downloadError, setDownloadError] = useState(false);
	const [downloadErrorData, setDownloadErrorData] = useState(``);
	const [downloadLoading, setDownloadLoading] = useState(false);

	const { backEndRequest, handleBackEndRequest } = useAppContext();
	const { accounts = [] } = useMemberContext();
	const selectedAccount = getSelectedMemberAccount(accounts);
	const accountNumber = selectedAccount?.account_number ?? '';
	const productName = selectedAccount?.product_name ?? '';

	const { trackEvent } = useTrackEvent({
		event_section: `member_documentation`,
		event_type: `notify employer`,
		event_title: ``,
	});

	const _formName = formName
		? formName
		: `Pay-my-super-into-AustralianSuper.pdf`;

	const blankFormCall = () => {
		const blankFormURL = `https://www.australiansuper.com/-/media/australian-super/files/tools-and-advice/forms-and-fact-sheets/superannuation/forms/choice-of-super-fund/pay-my-super-into-australiansuper-desktop.pdf`;
		const link = document.createElement('a');
		link.href = blankFormURL;
		link.setAttribute('download', _formName);
		document.body.appendChild(link);
		link.click();

		// Analytics spreadsheet:
		// - Section: 'Notify Employer'
		// - ID: 11.1.9
		trackEvent({
			event_title: `what will we send your employer`,
			event_label: `download your account payment detail`,
			event_trigger: `click button`,
			event_url: blankFormURL,
		});
		setDownloadLoading(false);
	};

	const notifyEmployerCall = async () => {
		// Check if we need to do a refreshtoken for the case when the user has been
		// staying in the form for too long
		if (!isStorybook() || !isExperienceEditorActive()) await doRefreshToken();

		return axios({
			url: `${base}/notify-employer/${productName}/${accountNumber}`,
			method: 'POST',
			responseType: 'blob',
			headers: {
				'Authorization': `Bearer ${getAccessToken()}`,
				'Accept': 'application/pdf',
				'X-API-Key': config?.externalServices?.coarseGrainApiKey, // API Coarse grain
				'X-Correlation-Id': uuidv4(), // FED initiated correlationID
				'X-Correlation-App': X_CORRELATION_APP, // Link detection of request origin
			},
			withCredentials: AKAMAI_BOT_FLAG, // akamai bot detection
		});
	};

	useEffect(() => {
		// Call the Notify employer API on load to see if the PDF can be pre-populated
		setLoading(true);

		notifyEmployerCall()
			.then(response => {
				setError(false);
				setResponse(response.data);
			})
			.catch(error => {
				setError(true);
				setErrorData(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const download = (hasDownloadFormErrorProps: boolean) => {
		setDownloadLoading(true);

		if (hasDownloadFormErrorProps) {
			blankFormCall();
		} else {
			notifyEmployerCall()
				.then(response => {
					// Session timeout will be handled in https://jira.australiansuper.net.au/browse/MPORTAL-4581
					// Logout
					// This is to reset the session timeout as per:
					// [BR01] https://confluence.australiansuper.net.au/display/MemberPortalV5/T07.E05.P01+-+Display+Logout+Success
					handleBackEndRequest(!backEndRequest);

					const url = window.URL.createObjectURL(
						new Blob([response.data], {
							type: 'text/csv;charset=UTF-8',
						}),
					);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute(QUALIFIED_NAME, _formName);
					document.body.appendChild(link);
					link.click();

					// Analytics spreadsheet:
					// - Section: 'Notify Employer'
					// - ID: 11.1.9
					trackEvent({
						event_title: `what will we send your employer`,
						event_label: `download your account payment detail`,
						event_trigger: `click button`,
						event_url: url,
					});

					setDownloadResponse(response.data);
				})
				.catch(error => {
					blankFormCall();
					setDownloadError(true);
					setDownloadErrorData(error);
				})
				.finally(() => {
					setDownloadLoading(false);
				});
		}
	};

	return {
		response,
		error,
		errorData,
		loading,
		download,
		downloadResponse,
		downloadError,
		downloadErrorData,
		downloadLoading,
	};
};
