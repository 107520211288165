/* eslint-disable react-hooks/rules-of-hooks */

import { useCreateGUID } from 'core/api/client/PartnerApi';

import { usePostMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';

import UseCreateGUIDData from './mocks/UseCreateGUID.mock.json';

export default smartMockApi({
	useCreateGUID: {
		live: useCreateGUID,
		mock: () => {
			return usePostMock({ payload: UseCreateGUIDData });
		},
	},
});
