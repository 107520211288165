// TODO
// - Alert modifier
// - Disabled/inverted styles clash
// - CTA base style background-colour transition
// - can JSS logic be extracted from CTA (decoupled?)

// - <Button/> and Cosmetic CTAs retaining focus state on click?
// - storybook long/short label examples
// - richtext examples in storybook?
// - full-width "plain" and/or "unstyled" responsive/styles?
// - should/could CtaContainer be bundled in this component folder?

// - CtaContainer sub-component - pods?
// -- Rename `stretch` prop
// - Cta vs CTA in references (comments?)
// - icon align JSS position (refactor position to use absolute?)

// - All types configurable story

// - can we not refactor Jss support so it's just the <Link/> tag with ctaProps / core CTA class...? Instead of cosmetic CTA wrapping Jss <a/>?

// - NOTE: RTE CTAs dont support
// `-- Icons
// 	-- Loading
//  -- disabled href navigation supression (just styles)
// - "RTE" in 'All Types' story toggle to exclude/hide RTE row when not supported? (and print why?)

// - storybook configurable all types with JSS field checked:
// 	-- 'unstyled' not rendering
// 	-- anchor not rendering <a/>?

// Icons:
// - margins (too big?)
// - unstyled icon colours
// - JSS padding

import React from 'react';
import cx from 'classnames';
import ConditionalWrap from 'conditional-wrap';
import { withTheme } from 'styled-components';
import { executeCallback } from 'core/utils/global-utils';
import { IThemeObject } from 'pods/theme';
/* eslint-disable-next-line no-restricted-imports */
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Icon, ICONS, IProps as IconProps } from 'core/components/Icon';
import RoutableSitecoreLink from 'core/components/RoutableSitecoreLink';
import {
	isJssField,
	JssTextType,
	JssLinkType,
} from 'core/utils/sitecore-utils';

import LoadingDots from '../LoadingDots';
import * as S from './Cta.style';

// ------------------------------------------

const ICON_SIZES = {
	small: 16,
	medium: 20,
	large: 24,
};

// ------------------------------------------

type onClickType =
	| React.MouseEvent<HTMLButtonElement>
	| React.MouseEvent<HTMLAnchorElement>
	| React.MouseEvent<HTMLSpanElement>;

export type CtaStyle = 'primary' | 'secondary' | 'plain' | 'warning';
export type CtaType = 'anchor' | 'button' | 'cosmetic' | 'unstyled';
export type CtaSize = 'extraSmall' | 'small' | 'medium' | 'large';

export interface CtaProps {
	ctaStyle?: CtaStyle;
	ctaType?: CtaType;
	size?: CtaSize;

	jssLink?: JssLinkType;
	jssText?: JssTextType;

	theme: IThemeObject;

	// Flags to change the way the CTA renders
	isAlert?: boolean;
	isInverted?: boolean;
	/** For 'plain' style only, render the cta component like a inline hyper link without padding */
	isLinkAlike?: boolean;
	/** For 'plain' style only, remove padding from the cta component */
	isWithoutPadding?: boolean;

	// CTA states
	isLoading?: boolean;
	isDisabled?: boolean;
	// Feasibly useful for cosmetic CTAs
	isHovered?: boolean;

	// Icon related
	icon?: ICONS | null;
	iconAlign?: 'left' | 'right';
	iconProps?: Omit<IconProps, 'name'>;
	// This is for cta that wants to display icon only, no text.
	isIconOnly?: boolean;

	// Semantic tag contextual props
	href?: string;
	target?: string;
	buttonType?: 'button' | 'submit' | 'reset';
	isDownload?: boolean;

	// Events
	onClick?: (event: onClickType) => void;

	/** For overriding default location in jssLinks with onClick */
	/** Can be used for when you need a link to be handled with the onClick only */
	/** especially with API hook / function that handles redirect instead */
	onClickOverDefault?: boolean;

	// Contents
	children?: string | React.ReactNode;

	// This is for cta having icon and internal link.
	isJssWithIcon?: boolean;

	// if moving onClick to S.content instead leaving it on <a>, should use when href is present on <a>
	// only for "unstyled" ctaType ATM
	isOnClickWrapper?: boolean;

	// for accessibility if we want CTA to be nonfocusable
	nonFocusable?: boolean;
}

// ------------------------------------------

const Cta = ({
	ctaStyle = 'primary',
	ctaType = 'anchor',
	size = 'medium',
	theme,
	jssLink,
	jssText,
	isLoading = false,
	isAlert,
	isInverted,
	isDisabled,
	isHovered,
	isLinkAlike = false,
	isWithoutPadding = false,
	icon,
	iconAlign = 'right',
	iconProps = {} as IconProps,
	isIconOnly = false,
	href,
	target,
	buttonType,
	isDownload,
	onClick,
	onClickOverDefault,
	children,
	isJssWithIcon = false,
	isOnClickWrapper = false,
	nonFocusable,
	// Props passthrough to SemanticCta
	// (e.g. event handlers, aria-tags, data-attributes)
	...props
}: CtaProps) => {
	const isUnstyled = ctaType === 'unstyled';
	const isAnchor = ctaType === 'anchor' || isUnstyled;
	const isCosmetic = ctaType === 'cosmetic';
	const isButton = ctaType === 'button';

	const jssField = jssText ?? jssLink;
	const isJss = isJssField(jssField);
	const isJssLink = isJss && jssLink && isAnchor;

	// Core semantic tag the CTA will render as.
	// NOTE: JSS tags are always cosmetic wrappers around the native JSS link/text
	const SemanticCta =
		isCosmetic || isJssLink ? 'span' : isButton ? 'button' : 'a';

	// When setting disabled=true, return true, otherwise return undefined (as opposed to false)
	// This is so the relevant attribute (`disabled` or `aria-disabled` is only conditionally present
	// as opposed to 'always present and either set to true/false'. Cleaner DOM output this way.
	const disabledState = isDisabled || isLoading ? true : undefined;

	const ctaProps = {
		'href': isUnstyled
			? jssLink?.value.href ?? href
			: isAnchor
			? href
			: undefined,
		'target': isAnchor ? target : undefined,
		'tabIndex': nonFocusable ? -1 : isCosmetic && !isJssLink ? 0 : undefined,
		'disabled': isButton ? disabledState : undefined,
		'aria-disabled': !isButton ? disabledState : undefined,
		'className': !isUnstyled
			? cx(S.CTA_CLASS, {
					// Styles --------------------------------
					'cta-primary': ctaStyle === 'primary',
					'cta-secondary': ctaStyle === 'secondary',
					'cta-plain': ctaStyle === 'plain',
					'cta-warning': ctaStyle === 'warning',

					// States & flags ------------------------
					'-alert': isAlert,
					'-disabled': isDisabled,
					'-loading': isLoading,
					'-hovered': isHovered,
					'-inverted': isInverted,
					'-linkAlike': isLinkAlike,
					'-withoutPadding': isWithoutPadding,
			  })
			: undefined,
		'onClick': (event: onClickType) => {
			// Suppress `href` navigation for disabled hyperlinks
			if (isDisabled && isAnchor) {
				event.preventDefault();
			}

			if (onClickOverDefault) {
				event.preventDefault();
				executeCallback(onClick, event);
			} else {
				executeCallback(onClick, event);
			}
		},
		'download': isDownload,
		...props,
	};

	// Optionally adjacent content icon
	const ctaIcon = icon && (
		<Icon
			name={icon}
			size={!!size ? ICON_SIZES[size] : undefined}
			{...iconProps}
		/>
	);

	const loadingDotsColor: string =
		ctaStyle === 'primary' || ctaStyle === 'warning'
			? theme.global.ctas.primary.base.text ?? '#FFFFFF'
			: theme.global.ctas.primary.base.color ?? '#171C8F';

	const _handleClick = ctaProps.onClick;

	return (
		<S.Cta className={S.CTA_CLASS_WRAPPER}>
			{/* Override some select CTA styles to facilitate utilising JSS's <Link/>
			component for the Experience Editor whilst retaining core CTA style parity. */}
			<ConditionalWrap
				condition={isJssLink}
				wrap={(children: React.ReactNode) => (
					<S.SitecoreOverrides
						isWithoutPadding={isWithoutPadding || isLinkAlike}
						{...{ isDisabled, isLoading }}
					>
						{isIconOnly ? (
							<RoutableSitecoreLink
								field={jssLink!}
								{...ctaProps}
								isHrefSuppressed={!!onClickOverDefault || !!isDisabled}
								showIconOnly
							>
								{ctaIcon}
							</RoutableSitecoreLink>
						) : isJssLink && isJssWithIcon ? (
							<a {...ctaProps}>
								{children}
								{ctaIcon}
							</a>
						) : (
							children
						)}
					</S.SitecoreOverrides>
				)}
			>
				{/* Dont apply CTA core styles/semantics if sepcified as `unstyled`.
				Done this way to centralise CTA render/wrapper logic (e.g. shared S.Content/Icon etc) */}
				<ConditionalWrap
					condition={!isUnstyled}
					wrap={(children: React.ReactNode) => (
						<SemanticCta
							type={isButton && !isJssLink ? buttonType ?? 'button' : undefined}
							{...ctaProps}
						>
							{children}
						</SemanticCta>
					)}
				>
					<S.Content
						{...{ iconAlign, size }}
						onClick={isOnClickWrapper ? _handleClick : undefined}
					>
						{/* Cta content (text or encapsulated link) */}
						{isUnstyled ? (
							isJss ? (
								<RoutableSitecoreLink
									field={jssLink!}
									{...ctaProps}
									isHrefSuppressed={!!onClickOverDefault || !!isDisabled}
									showLinkTextWithChildrenPresent
								>
									{ctaIcon}
								</RoutableSitecoreLink>
							) : isOnClickWrapper ? (
								() => {
									const { onClick, ...anchorProps } = ctaProps;

									return (
										<a {...anchorProps}>
											{children}
											{ctaIcon}
										</a>
									);
								}
							) : (
								<a {...ctaProps}>
									{children}
									{ctaIcon}
								</a>
							)
						) : isLoading ? (
							<LoadingDots dotColor={loadingDotsColor} />
						) : isJssLink ? (
							<RoutableSitecoreLink
								field={jssLink!}
								download={isDownload}
								isHrefSuppressed={!!onClickOverDefault || !!isDisabled}
								className={S.JSS_CTA_CLASS}
								showLinkTextWithChildrenPresent
							>
								{ctaIcon}
							</RoutableSitecoreLink>
						) : isJss ? (
							<Text field={jssText!}>{ctaIcon}</Text>
						) : isIconOnly ? (
							<RoutableSitecoreLink
								field={jssLink!}
								download={isDownload}
								isHrefSuppressed={!!onClickOverDefault || !!isDisabled}
								className={S.JSS_CTA_CLASS}
								showLinkTextWithChildrenPresent
							>
								{ctaIcon}
							</RoutableSitecoreLink>
						) : (
							<>
								{children}
								{ctaIcon}
							</>
						)}
					</S.Content>
				</ConditionalWrap>
			</ConditionalWrap>
		</S.Cta>
	);
};

export default withTheme(Cta);
