/* eslint-disable no-restricted-imports */

import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { isStorybook } from 'stories/utils';
import { isInSitecorePreviewOrEditMode } from 'core/utils/global-utils';

// ------------------------
// Types

// Extract the live api function type out
type LiveApiFunction<T> = T extends { live: infer U; mock: any } ? U : T;

type SmartMockedApiFunctions<T> = { [key in keyof T]: LiveApiFunction<T[key]> };

// ------------------------

const shouldMock =
	isStorybook() ||
	isExperienceEditorActive() ||
	isInSitecorePreviewOrEditMode();

export const smartMockApi = <
	T extends { [key: string]: { live: any; mock: (props: any) => any } }
>(
	apiFunctions: T,
): SmartMockedApiFunctions<T> =>
	Object.keys(apiFunctions).reduce((acc, apiObjKey) => {
		const { mock, live } = (apiFunctions as any)[apiObjKey];
		(acc as any)[apiObjKey] = shouldMock ? mock : live;

		return acc;
	}, {}) as SmartMockedApiFunctions<T>;
