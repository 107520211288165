// Note: Some of these WithdrawalTypes values are highly dependent on withdrawalTypeDropdownOptions Key values
export enum WithdrawalTypes {
	DEFAULT = '',
	RPA = 'Reached preservation age',
	UNP = 'Unrestricted non-preserved super',
	SMSF = 'Transfer to another super fund',
	PENSION = 'Pension',
	FHA = 'Severe financial hardship (Type A)',
	FHB = 'Severe financial hardship (Type B)',
	BALANCE_OF_200_OR_LESS = 'Balance of $200 or less',
	TERMINAL_ILLNESS = 'Terminal illness',
}

export enum WITHDRAWAL_MIN_BALANCE {
	RPA = 6000,
	TRANSFER_TO_ANOTHER = 6000,
	UNP = 6000,
}

export enum WITHDRAWAL_MAX_AMOUNT {
	FHA = 10000,
}

export enum FORM_STATUS {
	SAVED = 'SAVED',
	SUBMITTED = 'SUBMITTED',
}

export const WITHDRAWAL_STORAGE_ITEM = 'WITHDRAWAL_PAYLOAD';

// Analytics constants section
export const WITHDRAWALS_EVENT_SECTION = 'withdrawals';
export const ACCUM_EVENT_TYPE = 'make a withdrawal claim - accum';
export const PENSION_EVENT_TYPE = 'make a withdrawal claim - pension';

export const SMSF_PAGE_TITLE = 'Transfer to Another Fund';
export const FHA_PAGE_TITLE = 'Financial Hardship Claim Type A';
export const FHB_PAGE_TITLE = 'Financial Hardship Claim Type B';
export const UNP_PAGE_TITLE = 'Unrestricted Non Preserved';
export const PENSION_PAGE_TITLE = 'Pension Withdrawal Claim';
export const RPA_PAGE_TITLE = 'Reached Preservation Age Withdrawal';

export const VIEW_EVENT_LABEL = 'view';
export const NEXT_EVENT_LABEL = 'next';
export const BACK_EVENT_LABEL = 'back';
export const EDIT_EVENT_LABEL = 'edit';
export const CLICK_BUTTON_EVENT_TRIGGER = 'click button';
export const SCREEN_LOAD_EVENT_TRIGGER = 'screen load';
export const TFN_EVENT_LABEL =
	'what are the benefits of providing my tax file number?';

export const TFN_EVENT_TYPE = 'withdrawal tfn form';
export const DECLARATION_EVENT_TYPE = 'declaration form';
export const GID_EVENT_TYPE = 'withdrawal identification check form';
export const FH_EVENT_TYPE = 'financial hardship claim form';

export const NO_OPTIONS_SELECTED = 'No options selected';
export const AN_AMOUNT_I_CHOOSE = 'An amount I choose';

export enum WITHDRAWAL_TYPE {
	PARTIAL_WITHDRAWAL = 'Partial withdrawal',
	FULL_WITHDRAWAL = 'Full withdrawal',
	PARTIAL_BALANCE_TRANSFER = 'Partial balance transfer',
	FULL_BALANCE_TRANSFER = 'Full balance transfer',
}

export const ACTIVE_FUND_STATUS = 'ACTIVE';

export const MEMBER_DIRECT = 'MEMBER_DIRECT';
