import styled from 'styled-components';
import * as vr from 'core/styles/variables';
import * as anim from 'core/styles/animations';

export const Rotate = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	animation: ${anim.rotate} 1.5s infinite linear;

	/* Override margin added to the icon by other classes */
	&&& i {
		margin: 0;
	}
`;

export const ThreeDots = styled.div<{
	dotColor: string;
}>`
	& span {
		position: absolute;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: ${props => props.dotColor};
		color: ${props => props.dotColor};
		animation: ${anim.threeDotsFlashing} 0.9s infinite alternate;
		animation-delay: -0.3s;
		z-index: ${vr.zIndexBase.top};
	}

	& {
		position: relative;
		width: 0;
		height: 10px;
		border-radius: 5px;
		background-color: ${props => props.dotColor};
		color: ${props => props.dotColor};
	}

	&::before,
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 0;
	}

	&::before {
		left: -15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: ${props => props.dotColor};
		color: ${props => props.dotColor};
		animation: ${anim.threeDotsFlashing} 0.9s infinite alternate;
		animation-delay: -0.6s;
	}

	&::after {
		left: 15px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		background-color: ${props => props.dotColor};
		color: ${props => props.dotColor};
		animation: ${anim.threeDotsFlashing} 0.9s infinite alternate;
		animation-delay: 0s;
	}

	/* Override margin added to the icon by other classes */
	&&& i {
		margin: 0;
	}
`;
