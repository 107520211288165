export enum PAYMENT_STATUS_CODE {
	AWAITING_PAYMENT = 'AWAITING_PAYMENT',
	PAID = 'PAID',
}

export const HALF_YEARLY = {
	code: 'HALF-YEARLY',
	description: 'Twice per Year',
};

export const PAYMENT_FREQUENCY = {
	SEMIYEARLY: 'SEMIYEARLY',
	HALF_YEARLY: 'HALF-YEARLY',
};
