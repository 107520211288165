import React from 'react';

import ReactModal, { IProps as ModalProps } from 'core/components/Modal';

import { useToggle } from 'core/hooks/useToggle';
import { isWebBrowser } from 'core/utils/global-utils';
import { useAppContext } from 'core/hooks/useAppContext';
import { MODAL_ARIA_NAMES } from 'core/constants';

type Modal = Omit<ModalProps, 'isOpen' | 'onRequestClose'>;

interface IUseModal extends Array<any> {
	0: React.FC<Modal>;
	1: (shouldOpen?: any) => void;
}

/**
 * Custom hook to use Modal component.
 *
 * @param initialState  - the initial open state of the Modal.
 * @returns {IUseModal} - the Modal component with its own toggle method to control the isOpen state
 */
export const useModal = (initialState = false, rootId = 'root'): IUseModal => {
	const [isOpen, toggleModal] = useToggle(initialState);
	const [isModalClosed, setIsModalClosed] = React.useState(false);
	const {
		handleAccountSwitcherModal,
		handleAccountSwitch,
		handleLivePersonModal,
	} = useAppContext();

	const Modal = React.useCallback(
		({ children, ...restProps }: Modal) => (
			<ReactModal
				isOpen={isOpen}
				onRequestClose={() => {
					toggleModal();
					handleAccountSwitcherModal('');
					handleAccountSwitch(true);
					handleLivePersonModal(false);
				}}
				onAfterClose={() => setIsModalClosed(true)}
				appElement={(isWebBrowser && document.getElementById(rootId)) ?? {}}
				ariaHideApp={isOpen}
				aria={{
					labelledby: MODAL_ARIA_NAMES.HEADING,
					describedby: MODAL_ARIA_NAMES.DESCRIPTION,
				}}
				contentLabel={MODAL_ARIA_NAMES.HEADING}
				{...restProps}
			>
				{children}
			</ReactModal>
		),
		[isOpen, toggleModal, rootId],
	);

	return [Modal, toggleModal, isModalClosed];
};
