import styled, { css } from 'styled-components';

import { Cta } from 'core/components/Cta/Cta.style';
import { Icon } from 'core/components/Icon/Icon.style';
import * as mx from 'core/styles/mixins';

export const PaginationNavBar = styled.nav<{ theme: any }>`
	display: inline-flex;
	position: relative;
	justify-content: center;
	margin: 0 44px;

	${Cta} > * {
		${mx.bp('xxs')`
			${mx.circle('32px')}
		`}

		${mx.circle('24px')}

		padding: 0;
		display: flex;
		justify-content: center;

		&:hover {
			background-color: ${({ theme }) =>
				theme.global.page.tabs.arrowButton.color};
		}
	}

	${Icon} {
		margin: 0;
	}
`;

export const PageNumberList = styled.ul`
	${mx.listReset}
	${mx.bp('xxs')`
		max-width: 212px;
	`}

	display:inline-flex;
	align-items: center;
	max-width: 170px;

	li {
		margin-top: 0;
	}
`;

export const PaginationItem = styled.li<{
	isActive?: boolean;
	isHiddenPageIndicator?: boolean;
}>`
	display: inline-flex;
	justify-content: center;
	flex-shrink: ${({ isHiddenPageIndicator }) =>
		isHiddenPageIndicator ? 1 : 0};
	flex-grow: ${({ isHiddenPageIndicator }) => (isHiddenPageIndicator ? 1 : 0)};

	${Cta} > * {
		${({ isHiddenPageIndicator }) =>
			isHiddenPageIndicator &&
			css`
				pointer-events: none;
				width: auto;
				height: auto;
			`}

		${({ isActive, theme }) =>
			isActive &&
			css`
				background-color: ${theme.global.ctas.primary.base.color};
				color: ${theme.global.ctas.primary.base.text};
				pointer-events: none;
			`}
	}

	& + & {
		margin: 0 0 0 4px;
	}
`;

export const ArrowContainer = styled.div<{ isForwardArrow?: boolean }>`
	display: inline-block;
	position: absolute;
	margin: 0;

	${({ isForwardArrow }) =>
		isForwardArrow
			? css`
					${mx.bp('xxs')`
						right: -20px;
					`}

					right: -10px;
					transform: translate(100%, 0);
			  `
			: css`
					${mx.bp('xxs')`
						left: -20px;
					`}

					left: -10px;
					transform: translate(-100%, 0);
			  `}
`;

export const PaginationBarContainer = styled.div`
	width: 100%;
	padding: 10px 24px;
	display: flex;
	justify-content: center;
	border-top: 1px solid ${({ theme }) => theme.global.borders.module};
`;

export const PaginationStatusContainer = styled.div<{
	hasBorderBottom?: boolean;
	withoutPadding?: boolean;
}>`
	width: 100%;
	padding: ${({ withoutPadding }) => `${withoutPadding ? '0' : '10px 24px'}`};
	display: flex;
	justify-content: center;
	${({ hasBorderBottom }) =>
		css`
			border-bottom: ${hasBorderBottom ? '1px' : '0px'} solid
				${({ theme }) => theme.global.borders.module};
		`};
`;
