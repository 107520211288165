/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';
import {
	useGetAnnualStatements,
	useGetAnnualStatementDocument,
} from 'core/api/client/AnnualStatementsApi';

import GetAnnualStatementsMock from './mocks/GetAnnualStatements.mock.json';

export default smartMockApi({
	useGetAnnualStatementDocument: {
		live: useGetAnnualStatementDocument,
		mock: () => useGetMock({ payload: {} }),
	},
	useGetAnnualStatements: {
		live: useGetAnnualStatements,
		mock: ({ account_number }) => {
			const payload = {
				default: () => GetAnnualStatementsMock,
				without_latest_statement: () =>
					GetAnnualStatementsMock.data.annual_statements.map(a => ({
						...a,
						date: '2019-11-03T00:55:22.227Z',
					})),
			};

			return useGetMock({
				payload: (payload[account_number] || payload.default)(),
			});
		},
	},
});
