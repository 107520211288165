import React from 'react';
import ReactModal, { Styles } from 'react-modal';
import noScroll from 'no-scroll';

import { Icon, ICONS } from 'core/components/Icon/Icon';
import { executeCallback } from 'core/utils/global-utils';
import { MODAL_ARIA_NAMES } from 'core/constants';

import * as vr from 'core/styles/variables';

import * as S from './Modal.style';

export interface IProps extends Omit<ReactModal.Props, 'style'> {
	/** Modal content */
	children: React.ReactNode;
	/** Additional modal style */
	modalStyle?: Styles;
	/** Should the modal render a close button on the top right corner */
	hasCloseIcon?: boolean;
	/** Optional footer component, such as Cta groups */
	footerComponent?: React.ReactNode | React.ReactNodeArray;
	/**Is the modal viewing submitted form */
	noPadding?: boolean;
	/**Justify content for footer */
	footerJustifyContent?: JustifyContent;
}

export type JustifyContent =
	| 'flex-start'
	| 'flex-end'
	| 'start'
	| 'end'
	| 'left'
	| 'right'
	| 'center'
	| 'space-around'
	| 'space-evenly'
	| 'space-between'
	| 'normal'
	| 'inherit'
	| 'initial'
	| 'revert'
	| 'stretch'
	| 'unset';

/**
 * The Modal component without toggle methods.
 *
 * @param param0.hasCloseIcon - whether to render the close icon on the top right corner
 * @note for the DRY principle, use the custom hook [useModal]{@link src/core/hooks/useModal/useModal.tsx}.
 *   See {@link src/core/components/Modal/__tests__/Modal.stories.js} for the example fo the hook usage.
 */
const Modal = ({
	children,
	modalStyle = {},
	hasCloseIcon = true,
	noPadding = false,
	onAfterClose,
	onAfterOpen,
	footerComponent,
	footerJustifyContent,
	...restProps
}: IProps) => {
	const style = {
		content: {
			left: '10px',
			right: '10px',
			bottom: 'auto',
			margin: 'auto',
			borderRadius: '8px',
			padding: '0',
			maxHeight: 'calc(90% - 40px)',
			maxWidth: '500px',
			// Override this property if needed
			...modalStyle?.content,
		},
		overlay: {
			background: 'rgba(0,0,0,0.6)',
			zIndex: vr.zIndexBase.top,
			...modalStyle?.overlay,
		},
	};
	const { onRequestClose } = restProps;

	/** Disable scrolling when the modal is open */
	const _onAfterClose = React.useCallback(() => {
		executeCallback(onAfterClose);
		noScroll.off();
	}, [onAfterClose]);

	/** Re-enable scrolling when the modal is close */
	const _onAfterOpen = React.useCallback(() => {
		executeCallback(onAfterOpen);
		noScroll.on();
	}, [onAfterOpen]);

	return (
		<S.Wrapper>
			<ReactModal
				style={style}
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
				shouldFocusAfterRender
				shouldReturnFocusAfterClose
				onAfterClose={_onAfterClose}
				onAfterOpen={_onAfterOpen}
				aria={{
					labelledby: MODAL_ARIA_NAMES.HEADING,
					describedby: MODAL_ARIA_NAMES.DESCRIPTION,
				}}
				contentLabel={MODAL_ARIA_NAMES.HEADING}
				{...restProps}
			>
				<>
					<S.ModalContentWrapper withPadding={!noPadding}>
						{hasCloseIcon && (
							<S.BtnWrapper onClick={onRequestClose}>
								<Icon name={ICONS.CLOSE} aria-label="Close the modal" />
							</S.BtnWrapper>
						)}
						{children}
					</S.ModalContentWrapper>
					{footerComponent && (
						<S.FooterContainer footerJustifyContent={footerJustifyContent}>
							{footerComponent}
						</S.FooterContainer>
					)}
				</>
			</ReactModal>
		</S.Wrapper>
	);
};

export default Modal;
