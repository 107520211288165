/* eslint-disable @typescript-eslint/camelcase */

import { useState, useEffect } from 'react';

import moment from 'moment';
import axios from 'axios';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { v4 as uuidv4 } from 'uuid';

import config from 'jss-boilerplate/temp/config.js';
import { useAppContext, useMemberContext } from 'core/hooks';
import { getSelectedMemberAccount } from 'core/sitecore/member';
import { getAccessToken } from 'core/browserStorage';
import {
	ProductName,
	RetrieveSenResponseDataType,
} from 'core/api/client/SignificantEventNoticeApi';
import { doRefreshToken } from 'core/utils/api-utils';
import {
	AKAMAI_BOT_FLAG,
	QUALIFIED_NAME,
	X_CORRELATION_APP,
} from 'core/constants';
import { isStorybook } from 'stories/utils';

import api from './api';

const base = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathSignificantEventNotice}`;

export const useSens = (id?: string) => {
	const {
		backEndRequest,
		handleBackEndRequest,
		unreadMsgCount,
		handleUnreadMsgCount,
	} = useAppContext();
	const { accounts = [] } = useMemberContext();
	const selectedAccount = getSelectedMemberAccount(accounts);
	const accountNumber = selectedAccount?.account_number ?? '';
	const productName = selectedAccount?.product_name ?? '';
	const [currentAccessToken] = useState(getAccessToken());

	const {
		data: apiDataSen,
		loading: loadingSens,
		error: errorSens,
		refetch: refetchSens,
	} = api.useGetSens({
		base,
		requestOptions: {
			headers: {
				'Cache-Control': 'no-cache',
			},
		},
	});

	const { mutate: read, loading: isReading } = api.useUpdateForm({
		base,
		product_name: productName as ProductName,
		account_number: accountNumber,
		id: id ?? '',
	});

	const { loading: downloading } = api.useGetSenDocument({
		base,
		product_name: productName as ProductName,
		account_number: accountNumber,
		id: id ?? '',
		lazy: true,
	});

	const download = async (messageId?: string, messageDate?: string) => {
		// Check if we need to do a refreshtoken for the case when the user has been
		// staying in the form for too long
		if (!isStorybook() || !isExperienceEditorActive()) await doRefreshToken();

		axios({
			url: `${base}/documents/${productName}/${accountNumber}/${messageId ??
				''}`,
			method: 'GET',
			responseType: 'blob',
			headers: {
				'Authorization': `Bearer ${currentAccessToken}`,
				'Accept': 'application/pdf',
				'X-API-Key': config?.externalServices?.coarseGrainApiKey, // API Coarse grain
				'X-Correlation-Id': uuidv4(), // FED initiated correlationID
				'X-Correlation-App': X_CORRELATION_APP, // Link detection of request origin
			},
			withCredentials: AKAMAI_BOT_FLAG, // akamai bot detection
		})
			.then(response => {
				// Logout
				// This is to reset the session timeout as per:
				// [BR01] https://confluence.australiansuper.net.au/display/MemberPortalV5/T07.E05.P01+-+Display+Logout+Success
				handleBackEndRequest(!backEndRequest);

				const filename =
					response.headers['content-disposition']
						.split('filename=')[1]
						.split(';')[0] ?? '';
				const formattedDocDate = moment(messageDate)
					.format('YYYYMMM')
					.toUpperCase();
				const customFileName = `${accountNumber}${formattedDocDate}ST.pdf`;
				const url = window.URL.createObjectURL(
					new Blob([response.data], {
						type: 'application/pdf',
					}),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(QUALIFIED_NAME, filename ?? customFileName);
				document.body.appendChild(link);
				link.click();
			})
			.catch(error => {
				console.log('SEN err: ', error);
			});
	};

	const sens = apiDataSen?.data?.[
		'significant-event-notices'
	] as RetrieveSenResponseDataType[];
	const unreadNotifications = () => {
		return sens
			?.filter(sen => {
				return !sen.is_read;
			})
			.slice(0, 5);
	};

	useEffect(() => {
		// Load initial value
		handleUnreadMsgCount(apiDataSen?.meta?.unread_count);
	}, [apiDataSen]);

	const details = sens?.filter(sen => {
		return sen.id === id;
	});

	const isNotificationOpened = details?.[0]?.is_read;

	return {
		sens,
		unreadNotifications: unreadNotifications(),
		unreadMsgCount,
		details: details?.[0],
		isNotificationOpened,
		loadingSens,
		errorSens,
		refetchSens,
		download,
		downloading,
		read,
		isReading,
	};
};
