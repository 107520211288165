import COMPONENTS from 'core/sitecore/definitions/component-definitions';

export const NAMES = {
	ROOT: {
		TOP: 'jss-top',
		MAIN: 'jss-main',
		BOTTOM: 'jss-bottom',
	},

	SUB_LAYOUTS: {
		FULL_WIDTH: 'content-full-width',

		FIFTY_LEFT: 'content-50-50-left',
		FIFTY_RIGHT: 'content-50-50-right',

		TWO_THIRDS_LEFT: 'content-66-left',
		TWO_THIRDS_RIGHT: 'content-66-right',

		THIRDS_LEFT: 'content-33-left',
		THIRDS_MIDDLE: 'content-33-middle',
		THIRDS_RIGHT: 'content-33-right',

		QUARTERS_FIRST: 'content-25-first',
		QUARTERS_SECOND: 'content-25-second',
		QUARTERS_THIRD: 'content-25-third',
		QUARTERS_FOURTH: 'content-25-fourth',
	},

	PAGE_LAYOUTS: {
		ONE_COLUMN_MAIN: 'content-1-column-main',

		// Yes this is the same as the above, and that's fine;
		// BEDs assure me although the placeholder key is shared/the-same
		// they can still have separate placeholder settings for a unique
		// set of allowed renderings that can be authored within, etc
		DASHBOARD: 'content-1-column-main',
		UNAUTHENTICATED_ONE_COLUMN: 'unauthenticated-1-column-main',

		TWO_COLUMN_LEFT_MAIN: 'content-2-column-left-main',
		TWO_COLUMN_LEFT_ASIDE: 'content-2-column-left-aside',

		TWO_COLUMN_RIGHT_MAIN: 'content-2-column-right-main',
		TWO_COLUMN_RIGHT_ASIDE: 'content-2-column-right-aside',
	},
};

// Placeholder groupings per component
// Note: This is only relevant for components that want to
// iteratively render <Placeholders/>; components that use
// a single placeholder can import directly from NAMES
//
// Key: Name of component in Sitecore
// Value: List of placholders used by that component
export const GROUPS = {
	SUB_LAYOUTS: {
		[COMPONENTS.SUB_LAYOUTS.FULL_WIDTH]: [NAMES.SUB_LAYOUTS.FULL_WIDTH],
		[COMPONENTS.SUB_LAYOUTS.FIFTY_FIFTY]: [
			NAMES.SUB_LAYOUTS.FIFTY_LEFT,
			NAMES.SUB_LAYOUTS.FIFTY_RIGHT,
		],
		[COMPONENTS.SUB_LAYOUTS.TWO_THIRDS_SPLIT]: [
			NAMES.SUB_LAYOUTS.TWO_THIRDS_LEFT,
			NAMES.SUB_LAYOUTS.THIRDS_RIGHT,
		],
		[COMPONENTS.SUB_LAYOUTS.ONE_THIRD_SPLIT]: [
			NAMES.SUB_LAYOUTS.THIRDS_LEFT,
			NAMES.SUB_LAYOUTS.TWO_THIRDS_RIGHT,
		],
		[COMPONENTS.SUB_LAYOUTS.THIRDS]: [
			NAMES.SUB_LAYOUTS.THIRDS_LEFT,
			NAMES.SUB_LAYOUTS.THIRDS_MIDDLE,
			NAMES.SUB_LAYOUTS.THIRDS_RIGHT,
		],
		[COMPONENTS.SUB_LAYOUTS.QUARTERS]: [
			NAMES.SUB_LAYOUTS.QUARTERS_FIRST,
			NAMES.SUB_LAYOUTS.QUARTERS_SECOND,
			NAMES.SUB_LAYOUTS.QUARTERS_THIRD,
			NAMES.SUB_LAYOUTS.QUARTERS_FOURTH,
		],
	},
};
