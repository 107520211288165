import {
	getSessionCookieItem,
	setSessionCookieItem,
	removeSessionCookieItem,
} from './sessionCookie';

const accessTokenKeyName = 'AccessToken';

export const getAccessToken = (): string => {
	const accessToken = getSessionCookieItem(accessTokenKeyName);

	// TODOs
	// if token is invalid, clear sessionStorage and logout user and return empty string
	// If about to expire (in next 5 mins?), get a new access token using refresh token from Forgerock
	// if token is expired (user has been inactive for 10 min), clear sessionStorage and logout user and return empty string

	return accessToken;
};

export const setAccessToken = (tokenValue: string) => {
	setSessionCookieItem(accessTokenKeyName, tokenValue);
};

export const removeAccessToken = () => {
	removeSessionCookieItem(accessTokenKeyName);
};
