import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const selectedAccountNumberKeyName = 'SelectedAccountNumber';

export const getSelectedAccountNumber = (): string | null => {
	const selectedAccountNumber = getLocalStorageItem(
		selectedAccountNumberKeyName,
	);

	return selectedAccountNumber === '' ||
		typeof selectedAccountNumber === 'undefined'
		? ''
		: selectedAccountNumber;
};

export const setSelectedAccountNumber = (tokenValue: string) => {
	setLocalStorageItem(selectedAccountNumberKeyName, tokenValue);
};

export const removeSelectedAccountNumber = () => {
	removeLocalStorageItem(selectedAccountNumberKeyName);
};
