/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VAL_00000001
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description?: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
}

export interface MemberAccountResponse {
	data?: Account;
}

export interface MemberAccountRequest {
	data?: Account;
}

export interface Account {
	account_number: string;
	product_name?: string;
}

export interface GUIDResponse {
	data?: Guid;
}

export interface Guid {
	redirect_uri?: string;
}

/**
 * Update successful. No Content
 */
export type Response204 = void;

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Forbidden
 */
export type Response403 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Conflict
 */
export type Response409 = Error;

/**
 * Gone
 */
export type Response410 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetMemberAccountNumberQueryParams {
	GUID: string;
}

export type GetMemberAccountNumberProps = Omit<
	GetProps<
		MemberAccountResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response410
		| Response500
		| ErrorResponseResponse,
		GetMemberAccountNumberQueryParams,
		void
	>,
	'path'
>;

/**
 * Get member's account number
 */
export const GetMemberAccountNumber = (props: GetMemberAccountNumberProps) => (
	<Get<
		MemberAccountResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response410
		| Response500
		| ErrorResponseResponse,
		GetMemberAccountNumberQueryParams,
		void
	>
		path={`/partners/members/account-number`}
		{...props}
	/>
);

export type UseGetMemberAccountNumberProps = Omit<
	UseGetProps<
		MemberAccountResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response410
		| Response500
		| ErrorResponseResponse,
		GetMemberAccountNumberQueryParams,
		void
	>,
	'path'
>;

/**
 * Get member's account number
 */
export const useGetMemberAccountNumber = (
	props: UseGetMemberAccountNumberProps,
) =>
	useGet<
		MemberAccountResponse,
		| Response400
		| Response401
		| Response403
		| Response404
		| Response410
		| Response500
		| ErrorResponseResponse,
		GetMemberAccountNumberQueryParams,
		void
	>(`/partners/members/account-number`, props);

export type CreateGUIDProps = Omit<
	MutateProps<
		GUIDResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		MemberAccountRequest,
		void
	>,
	'path' | 'verb'
>;

/**
 * Save account number and return a GUID.
 */
export const CreateGUID = (props: CreateGUIDProps) => (
	<Mutate<
		GUIDResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		MemberAccountRequest,
		void
	>
		verb="POST"
		path={`/partners/members/account-number`}
		{...props}
	/>
);

export type UseCreateGUIDProps = Omit<
	UseMutateProps<
		GUIDResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		MemberAccountRequest,
		void
	>,
	'path' | 'verb'
>;

/**
 * Save account number and return a GUID.
 */
export const useCreateGUID = (props: UseCreateGUIDProps) =>
	useMutate<
		GUIDResponse,
		| Response400
		| Response401
		| Response404
		| Response500
		| ErrorResponseResponse,
		void,
		MemberAccountRequest,
		void
	>('POST', `/partners/members/account-number`, props);
