/* Generated by restful-react */

import React from 'react';
import {
	Get,
	GetProps,
	useGet,
	UseGetProps,
	Mutate,
	MutateProps,
	useMutate,
	UseMutateProps,
} from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VAL_00000001
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

export interface PaymentDataType {
	data?: Pensions;
	meta?: Meta;
}

export interface ConfigurationDetailsDataType {
	data?: PensionConfiguration;
}

/**
 * Details of a pensions
 */
export interface Pensions {
	pension_details?: PensionDetails[];
	bank_account_details?: BankAccountDetails[];
}

/**
 * Configuration Details of a pensions
 */
export interface PensionConfiguration {
	configuration_details?: ConfigurationDetails;
}

export interface ConfigurationDetails {
	/**
	 * pension type code
	 */
	type_code?: string;
	/**
	 * pension type description
	 */
	type_description?: string;
	/**
	 * pension type status
	 */
	type_status?: string;
	/**
	 * pension commencent date
	 */
	commencement_date?: string;
	/**
	 * pension is non commutable
	 */
	is_non_commutable?: boolean;
	/**
	 * pension is death benefit pension
	 */
	is_death_benefit_pension?: boolean;
	/**
	 * pension is death benefit taxfree
	 */
	is_death_benefit_taxfree?: boolean;
	/**
	 * pension is pre1994 pension
	 */
	is_pre1994_pension?: boolean;
	/**
	 * pension is reversionary beeficiary elected
	 */
	is_reversionary_beneficiary_elected?: boolean;
	/**
	 * pension is crystalised
	 */
	is_crystalised?: boolean;
}

export interface PensionDetails {
	/**
	 * pension detail effective date
	 */
	effective_date?: string;
	/**
	 * pension detail next payment date
	 */
	next_payment_date?: string;
	account_based_details?: AccountBasedDetails;
	payment_frequency_details?: PaymentFrequencyDetails;
	payment_calendar?: PaymentCalendar[];
	total_paid_ytd?: TotalPaidYtd;
	payment_calendar_total_paid?: PaymentCalendarTotalPaid;
	pension_benefit_details?: PensionBenefitDetails;
	payment_method_details?: PaymentMethodDetails;
	pension_tax?: PensionTax;
	current_pension_nomination_details?: CurrentPensionNominationDetails;
}

export interface TotalPaidYtd {
	/**
	 * pension details total payment
	 */
	total_payment?: number;
	/**
	 * pension details is_payment_complete_ytda
	 */
	is_payment_complete_ytd?: boolean;
}

export interface AccountBasedDetails {
	/**
	 * account based details minimum percentage
	 */
	minimum_percentage?: number;
	/**
	 * account based details maximum percentage
	 */
	maximum_percentage?: number;
	/**
	 * account based details minimum payable
	 */
	minimum_payable?: number;
	/**
	 * account based details maximum payable
	 */
	maximum_payable?: number;
}

export interface PaymentFrequencyDetails {
	/**
	 * payment frequency details payment frequency
	 */
	payment_frequency?: string;
	/**
	 * payment frequency details day of month 1
	 */
	day_of_month1?: number;
	/**
	 * payment frequency details day of month 2
	 */
	day_of_month2?: number;
}

export interface PaymentCalendar {
	/**
	 * payment calendar payment frequency
	 */
	payment_frequency?: string;
	/**
	 * payment calendar payment due date
	 */
	payment_due_date?: string;
	/**
	 * payment calendar payment payable date
	 */
	payment_payable_date?: string;
	/**
	 * paymet calendar gross installment pension
	 */
	gross_installment_pension?: number;
	/**
	 * payment calendar payment status code
	 */
	payment_status_code?: string;
	/**
	 * payment paid date
	 */
	paid_date?: string;
}

export interface PaymentCalendarTotalPaid {
	/**
	 * gross installment pension
	 */
	gross_installment_pension?: number;
	/**
	 * net installment pension
	 */
	net_installment_pension?: number;
}

export interface PensionBenefitDetails {
	annual_pension_details?: AnnualPensionDetails;
}

export interface AnnualPensionDetails {
	/**
	 * annual pension details gross elected
	 */
	gross_elected?: number;
	/**
	 * annual pension details taxfree component
	 */
	taxfree_component?: number;
	/**
	 * annual pension details taxable component
	 */
	taxable_component?: number;
}

export interface PaymentMethodDetails {
	/**
	 * payment method details payment method code
	 */
	payment_method_code?: string;
}

export interface PensionTax {
	/**
	 * pension tax is claiming tax free threshold
	 */
	is_claiming_tax_free_threshold?: boolean;
	/**
	 * pension tax is claiming tax rebate
	 */
	is_claiming_tax_rebate?: boolean;
	/**
	 * pension tax is claiming deductable amount
	 */
	is_claiming_deductible_amount?: boolean;
	/**
	 * pension tax is claiming sr au tax offset
	 */
	is_claiming_sr_au_tax_offset?: boolean;
	/**
	 * pension tax is claiming medicare levy full exempt
	 */
	is_claiming_medicare_levy_full_exempt?: boolean;
	/**
	 * pension tax is claiming medicare levy half exempt
	 */
	is_claiming_medicare_levy_half_exempt?: boolean;
	/**
	 * pension tax is claiming medicare levy surcharge
	 */
	is_claiming_medicare_levy_surcharge?: boolean;
	/**
	 * pension tax is claiming family tax benefit offset
	 */
	is_claiming_family_tax_benefit_offset?: boolean;
	/**
	 * pension tax is claiming specia offset
	 */
	is_claiming_special_offset?: boolean;
	/**
	 * pension tax rebate value
	 */
	rebate_value?: number;
	/**
	 * pension tax override deductible value
	 */
	override_deductible_value?: number;
	/**
	 * pension tax is claiming help offset
	 */
	is_claiming_help_offset?: boolean;
	/**
	 * pension tax is claiming sfss offset
	 */
	is_claiming_sfss_offset?: boolean;
	/**
	 * pension tax benefit value
	 */
	tax_benefit_value?: number;
	/**
	 * pension tax offset value
	 */
	tax_offset_value?: number;
}

export interface CurrentPensionNominationDetails {
	/**
	 * current pension nomination details payment type
	 */
	payment_type?: string;
	/**
	 * current pension nomination details type
	 */
	type?: string;
	/**
	 * current pension nomination details type description
	 */
	type_description?: string;
	/**
	 * current pension nomination details type value
	 */
	value?: number;
	/**
	 * current pension nomination details index factor
	 */
	index_factor?: string;
	/**
	 * current pension nomination details index factor descripion
	 */
	index_factor_description?: string;
	/**
	 * current pension nomination pension period balance
	 */
	pension_period_balance?: number;
}

export interface BankAccountDetails {
	/**
	 * bank account details bank id
	 */
	bank_id?: number;
	/**
	 * bank account details amount
	 */
	amount?: number;
}

export interface PaymentUpdate {
	data?: PaymentDetails;
}

export interface PaymentDetails {
	/**
	 * The date range of the contribution made.This field represents the from date. ISO8601 format.
	 */
	effective_date: string;
	payment_frequency: RefDataBodyDetails;
	payment_date: RefDataBodyDetails;
	month_nominated: RefDataBodyDetails;
	payment_type: RefDataBodyDetails;
	indexation_factor: RefDataBodyDetails;
	amount: number;
}

/**
 * Used to store metadata of a pagination.
 */
export interface RefDataBodyDetails {
	code?: string;
}

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

/**
 * No Content
 */
export type Response201 = void;

/**
 * No Content
 */
export type Response204 = void;

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetConfigurationDetailsPathParams {
	/**
	 * Product name. i.e Pension
	 */
	product_name: 'Pension';
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type GetConfigurationDetailsProps = Omit<
	GetProps<
		ConfigurationDetailsDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetConfigurationDetailsPathParams
	>,
	'path'
> &
	GetConfigurationDetailsPathParams;

/**
 * Retrieves all the configuration details of payment of the account.
 */
export const GetConfigurationDetails = ({
	product_name,
	account_number,
	...props
}: GetConfigurationDetailsProps) => (
	<Get<
		ConfigurationDetailsDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetConfigurationDetailsPathParams
	>
		path={`/payments/${product_name}/${account_number}/configuration-details`}
		{...props}
	/>
);

export type UseGetConfigurationDetailsProps = Omit<
	UseGetProps<
		ConfigurationDetailsDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetConfigurationDetailsPathParams
	>,
	'path'
> &
	GetConfigurationDetailsPathParams;

/**
 * Retrieves all the configuration details of payment of the account.
 */
export const useGetConfigurationDetails = ({
	product_name,
	account_number,
	...props
}: UseGetConfigurationDetailsProps) =>
	useGet<
		ConfigurationDetailsDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetConfigurationDetailsPathParams
	>(
		(paramsInPath: GetConfigurationDetailsPathParams) =>
			`/payments/${paramsInPath.product_name}/${paramsInPath.account_number}/configuration-details`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface GetPaymentSummaryQueryParams {
	/**
	 * The date range of the contribution made.This field represents the from date. ISO8601 format.
	 */
	from_date?: string;
	/**
	 * The date range of the contribution made. This field represents the to date. ISO8601 format.
	 */
	to_date?: string;
}

export interface GetPaymentSummaryPathParams {
	/**
	 * Product name. i.e Pension
	 */
	product_name: 'Pension';
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type GetPaymentSummaryProps = Omit<
	GetProps<
		PaymentDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetPaymentSummaryQueryParams,
		GetPaymentSummaryPathParams
	>,
	'path'
> &
	GetPaymentSummaryPathParams;

/**
 * Retrieves all the payment summary of the account.
 */
export const GetPaymentSummary = ({
	product_name,
	account_number,
	...props
}: GetPaymentSummaryProps) => (
	<Get<
		PaymentDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetPaymentSummaryQueryParams,
		GetPaymentSummaryPathParams
	>
		path={`/payments/${product_name}/${account_number}/pensions`}
		{...props}
	/>
);

export type UseGetPaymentSummaryProps = Omit<
	UseGetProps<
		PaymentDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetPaymentSummaryQueryParams,
		GetPaymentSummaryPathParams
	>,
	'path'
> &
	GetPaymentSummaryPathParams;

/**
 * Retrieves all the payment summary of the account.
 */
export const useGetPaymentSummary = ({
	product_name,
	account_number,
	...props
}: UseGetPaymentSummaryProps) =>
	useGet<
		PaymentDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetPaymentSummaryQueryParams,
		GetPaymentSummaryPathParams
	>(
		(paramsInPath: GetPaymentSummaryPathParams) =>
			`/payments/${paramsInPath.product_name}/${paramsInPath.account_number}/pensions`,
		{ pathParams: { product_name, account_number }, ...props },
	);

export interface UpdatePaymentSummaryPathParams {
	/**
	 * Product name. i.e Pension
	 */
	product_name: 'Pension';
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type UpdatePaymentSummaryProps = Omit<
	MutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		PaymentUpdate,
		UpdatePaymentSummaryPathParams
	>,
	'path' | 'verb'
> &
	UpdatePaymentSummaryPathParams;

/**
 * updates the payment details of the account.
 */
export const UpdatePaymentSummary = ({
	product_name,
	account_number,
	...props
}: UpdatePaymentSummaryProps) => (
	<Mutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		PaymentUpdate,
		UpdatePaymentSummaryPathParams
	>
		verb="POST"
		path={`/payments/${product_name}/${account_number}/pensions`}
		{...props}
	/>
);

export type UseUpdatePaymentSummaryProps = Omit<
	UseMutateProps<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		PaymentUpdate,
		UpdatePaymentSummaryPathParams
	>,
	'path' | 'verb'
> &
	UpdatePaymentSummaryPathParams;

/**
 * updates the payment details of the account.
 */
export const useUpdatePaymentSummary = ({
	product_name,
	account_number,
	...props
}: UseUpdatePaymentSummaryProps) =>
	useMutate<
		Response204,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		PaymentUpdate,
		UpdatePaymentSummaryPathParams
	>(
		'POST',
		(paramsInPath: UpdatePaymentSummaryPathParams) =>
			`/payments/${paramsInPath.product_name}/${paramsInPath.account_number}/pensions`,
		{ pathParams: { product_name, account_number }, ...props },
	);
