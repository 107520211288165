/* eslint-disable react-hooks/rules-of-hooks */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';

import { useGetBalances } from 'core/api/client/AccountApi';

import GetBalancesMock from './mocks/GetBalances.mock.json';

export default smartMockApi({
	useGetBalances: {
		live: useGetBalances,
		mock: () => useGetMock({ payload: GetBalancesMock }),
	},
});
