import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const pwdKeyName = 'pwd';

export const getPwd = (): string => {
	return getLocalStorageItem(pwdKeyName);
};

export const setPwd = (tokenValue: string) => {
	setLocalStorageItem(pwdKeyName, tokenValue);
};

export const removePwd = () => {
	removeLocalStorageItem(pwdKeyName);
};
