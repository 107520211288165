import { MemberAccount } from 'core/interface/MemberAccount';
import { getSelectedAccountNumber } from 'core/browserStorage';

/**
 * Get selected account number from Member context accounts attributed
 *
 * @param memberAccounts - array of accounts from Member context
 */
export const getSelectedMemberAccount = (
	memberAccount?: MemberAccount[],
): MemberAccount | null => {
	if (!memberAccount) {
		return null;
	}
	const selectedAccountNumber = getSelectedAccountNumber();
	const filteredMemberAccounts = memberAccount.filter(
		x => x.account_number === selectedAccountNumber,
	);

	return !!selectedAccountNumber ? filteredMemberAccounts[0] : memberAccount[0];
};
