/* Generated by restful-react */

import React from 'react';
import { Get, GetProps, useGet, UseGetProps } from 'restful-react';

export interface Error {
	/**
	 * HTTP status code
	 */
	status: number;
	/**
	 * Code indicating the type error e.g. ERR_VAL_00000001
	 */
	error_code: string;
	/**
	 * Description of the problem
	 */
	description: string;
	/**
	 * Optional. More detailed error code, may be used differentiate between variations of the main error type
	 */
	error_sub_code?: string;
	/**
	 * Optional. Further information regarding the problem
	 */
	more_info?: string;
	/**
	 * Optional. URL to documentation on the error e.g. suggestions for resolution
	 */
	doc_link?: string;
}

export interface FinancialYearReference {
	data?: {
		values?: FinancialYearReferenceDataTypes;
	};
	meta?: Meta;
}

export interface FormStatusReference {
	data?: {
		values?: FormStatusReferenceDataTypes;
	};
	meta?: Meta;
}

export type FormStatusReferenceDataTypes = FormStatusReferenceDataType[];

export interface FormStatusReferenceDataType {
	code?: string;
	description?: string;
	supplemental_data?: FormStatusSupplementalDataType;
}

export interface FormStatusSupplementalDataType {
	values?: { [key: string]: any };
}

export type FinancialYearReferenceDataTypes = FinancialYearReferenceDataType[];

export interface FinancialYearReferenceDataType {
	description?: string;
	date_range?: FinancialYearDateRangeDataType;
}

export interface FinancialYearDateRangeDataType {
	from_date?: string;
	to_date?: string;
}

export interface EmployerClassificationDataType {
	data?: {
		values?: EmployerClassificationDataTypeData;
	};
	meta?: Meta;
}

export type EmployerClassificationDataTypeData = EmployerClassificationDataTypeDataValues[];

export interface EmployerClassificationDataTypeDataValues {
	default_classification_id_field?: string;
	default_classification_id?: string;
	default_classifications?: DefaultClassificationDataTypeArray;
	restricted_classification_ids?: RestrictedClassificationIdDataTypeArray;
	occupation_minimum?: OccupationMinimumType;
}

export type DefaultClassificationDataTypeArray = DefaultClassificationDataTypeItem[];

export interface DefaultClassificationDataTypeItem {
	employment_type?: string;
	default_classification_id?: string;
}

export type RestrictedClassificationIdDataTypeArray = RestrictedClassificationIdDataTypeItem[];

export interface RestrictedClassificationIdDataTypeItem {
	id?: string;
	short_description?: string;
	long_description?: string;
}

export interface OccupationMinimumType {
	code?: string;
	short_description?: string;
	long_description?: string;
}

export interface AdditionalDataDefinitionsAsync {
	data?: {
		values?: AdditionalDataDefinitionsAsyncDataTypes;
	};
	meta?: Meta;
}

export type AdditionalDataDefinitionsAsyncDataTypes = AdditionalDataDefinitionsAsyncDataTypeValues[];

export interface AdditionalDataDefinitionsAsyncDataTypeValues {
	additional_data_category?: string;
	plan_code?: string;
	name?: string;
	label?: string;
	short_description?: string;
	long_description?: string;
	is_mandatory?: boolean;
	allowed_values?: AdditionalDataDefinitionsAsyncDataTypeAllowedValues[];
	data_type?: string;
}

export interface AdditionalDataDefinitionsAsyncDataTypeAllowedValues {
	code?: string;
	short_description?: string;
	long_description?: string;
}

export interface ReferenceDataSuperFunds {
	data?: ReferenceDataSuperFundsData;
	meta?: Meta;
}

export interface ReferenceDataSuperFundsData {
	values?: ReferenceDataSuperFundsDataValues[];
}

export interface ReferenceDataSuperFundsDataValues {
	eRollover?: boolean;
	/**
	 * Unique Superannuation Identifier. Note usi and spin are used interchangibly and either of them can be empty
	 */
	usi?: string;
	fund_code?: string;
	/**
	 * flag to identify if the fund is registered with super stream
	 */
	superstream_registered_flag?: boolean;
	/**
	 * Australian Business Number
	 */
	abn: string;
	/**
	 * number identifying superannuation fund
	 */
	superannuation_fund_number: string;
	/**
	 * short description of the superannuation fund
	 */
	short_description?: string;
	/**
	 * name of the superannuation fund
	 */
	name: string;
	/**
	 * name of the body regulating the fund. Possible values are APRA - Australian Prudential Regulation Authority
	 */
	regulator?: string;
	/**
	 * Super Product Identifier Number. Note usi and spin are used interchangibly and either of them can be empty
	 */
	spin?: string;
}

export interface ReferenceDataSMSFFunds {
	data?: ReferenceDataSMSFData;
	meta?: Meta;
}

export interface ReferenceDataSMSFData {
	values?: ReferenceDataSMSFDataValues[];
}

export interface ReferenceDataSMSFDataValues {
	/**
	 * number identifying smsf provider
	 */
	id: string;
	/**
	 * electronic service address
	 */
	esi_alias: string;
	/**
	 * name of the smsf message provider
	 */
	name: string;
}

export interface ReferenceDataInvestmentPerformance {
	data: ReferenceDataInvestmentPerformanceData;
}

export interface ReferenceDataInvestmentPerformanceData {
	product_name: string;
	investment_performance: InvestmentPerformance[];
}

export interface InvestmentPerformance {
	investment_code: string;
	investment_description: string;
	as_at: string;
	ten_years_rates: InvestmentPerformanceYearRates;
	five_years_rates: InvestmentPerformanceYearRates;
	three_years_rates: InvestmentPerformanceYearRates;
	one_year_rates: InvestmentPerformanceYearRates;
}

export interface InvestmentPerformanceYearRates {
	australiansuper: number;
	benchmark: number;
	cpi: number;
}

export interface ReferenceDataType {
	data?: DataReferenceDataType;
	meta?: Meta;
}

export interface InsurancePlanReferenceDataType {
	data?: InsurancePlanDataReferenceDataType;
	meta?: Meta;
}

export interface BsbReferenceDataType {
	data?: BsbValueReferenceDataType;
}

export interface MemberClassificationReferenceDataType {
	data?: MemberClassificationDataReferenceDataType;
	meta?: Meta;
}

export interface OccupationReferenceDataType {
	data?: OccupationDataReferenceDataType;
	meta?: Meta;
}

export interface InvestmentReferenceDataType {
	data?: InvestmentDataReferenceDataType;
	meta?: Meta;
}

export interface OutboundCommunicationCaseReferenceDataType {
	data?: OutboundCommunicationCaseDataType;
	meta?: Meta;
}

export interface DataReferenceDataType {
	values?: ValueReferenceDataType[];
}

export interface InsurancePlanDataReferenceDataType {
	values?: InsurancePlanValueReferenceDataType[];
}

export interface MemberClassificationDataReferenceDataType {
	values?: MemberClassificationValueReferenceDataType[];
}

export interface OccupationDataReferenceDataType {
	values?: OccupationValueReferenceDataType[];
}

export interface InvestmentDataReferenceDataType {
	values?: InvestmentValueReferenceDataType[];
}

export interface OutboundCommunicationCaseDataType {
	values?: OutboundCommunicationValueCaseDataType[];
}

export interface ValueReferenceDataType {
	code?: string;
	description?: string;
	is_selectable?: boolean;
	supplemental_data?: SupplementalDataType;
}

export interface InsurancePlanValueReferenceDataType {
	code?: string;
	description?: string;
	tal_description?: string;
}

export interface OutboundCommunicationValueCaseDataType {
	code?: string;
	description?: string;
	supplemental_data?: OutboundCommunicationSupplementalDataType;
}

export interface BeneficiaryRelationshipDataType {
	data?: BeneficiaryRelationshipValues;
	meta?: Meta;
}

export interface BsbValueReferenceDataType {
	/**
	 * BSB (Bank-State-Branch) number is a six-digit number that identifies banks and branches across Australia
	 */
	bsb_number: string;
	institution: string;
	branch: string;
	street_address: string;
	suburb: string;
	state: 'ACT' | 'NSW' | 'NT' | 'QLD' | 'SA' | 'TAS' | 'VIC' | 'WA';
	postcode: string;
}

export interface MemberClassificationValueReferenceDataType {
	plan_code?: string;
	id?: string;
	pension_type?: string;
	employee_category?: string;
	status?: string;
	owner?: MemberClassificationOwnerValueReferenceDataType;
	gender_premium_rate_option?: string;
}

export interface OccupationValueReferenceDataType {
	plan_code?: string;
	code?: string;
	description?: string;
}

export interface MemberClassificationOwnerValueReferenceDataType {
	id?: string;
	type?: string;
}

export interface InvestmentValueReferenceDataType {
	code?: string;
	description?: string;
	category?: string;
	display_order?: number;
	status?: 'ACTIVE' | 'INACTIVE';
	is_editable?: boolean;
	maximum_allocation?: number;
	is_selectable?: boolean;
	supplemental_data?: SupplementalDataType;
}

export interface InvestmentSupplementalDataType {
	link?: InvestmentsDataType;
}

export interface SupplementalDataType {
	link?: { [key: string]: any };
}

export interface OutboundCommunicationSupplementalDataType {
	template_meta?: OutboundCommunicationTemplateDataType[];
}

export interface OutboundCommunicationTemplateDataType {
	type?: 'SMS' | 'EMAIL';
	id?: string;
}

export interface BeneficiaryRelationshipValues {
	values?: BeneficiaryRelationshipValueDataType[];
}

export interface BeneficiaryRelationshipValueDataType {
	code: string;
	description: string;
	non_binding_is_selectable: boolean;
}

export interface PensionsDataType {
	values?: PensionDataType;
}

export interface InvestmentsDataType {
	values?: InvestmentDetailDataType;
}

export interface InvestmentDataTypeValue {
	values?: InvestmentDetailDataType;
}

export interface PensionDataType {
	payment_type?: string;
	yearly_installments?: string;
	index_factor_code?: string;
	yearly?: string;
	quarterly?: string;
	half_yearly?: string;
}

export interface InsuranceDesignReferenceDataType {
	data?: InsuranceDesignDataReferenceDataType;
	meta?: Meta;
}

export interface InsuranceDesignDataReferenceDataType {
	values?: InsuranceDesignDataType[];
}

export interface InsuranceDesignDataType {
	insurance_formula?: string;
	insurance_design?: string;
}

export interface ChoiceIncomeReferenceDataType {
	data?: ChoiceIncomeDataReferenceDataType;
	meta?: Meta;
}

export interface ChoiceIncomeDataReferenceDataType {
	values?: ChoiceIncomeDataType[];
}

export interface ChoiceIncomeDataType {
	choice_sequence?: number;
	frequency?: string;
	values?: string;
}

export interface InvestmentDetailDataType {
	accumulation?: string;
	pension_choice_income?: string;
	transition_to_retirement?: string;
}

export interface PartyTypeReferenceDataType {
	data?: PartyTypeDataReferenceDataType;
	meta?: Meta;
}

export interface PartyTypeDataReferenceDataType {
	values?: PartyTypeDataType[];
}

export interface PartyTypeDataType {
	value?: string;
	crm_entity?: string;
	crm_value?: string;
	as_partytype?: number;
}

export interface DeliveryMethodReferenceDataType {
	data?: DeliveryMethodDataReferenceDataType;
	meta?: Meta;
}

export interface DeliveryMethodDataReferenceDataType {
	values?: DeliveryMethodDataType[];
}

export interface DeliveryMethodDataType {
	value?: string;
	as_delivery_method?: number;
}

export interface DeliverySourceReferenceDataType {
	data?: DeliverySourceDataReferenceDataType;
	meta?: Meta;
}

export interface DeliverySourceDataReferenceDataType {
	values?: DeliverySourceDataType[];
}

export interface DeliverySourceDataType {
	value?: string;
	as_source?: number;
}

export interface MemberCommPrefReferenceDataType {
	data?: MemberCommPrefDataReferenceDataType[];
}

export interface MemberCommPrefDataReferenceDataType {
	key?: string;
	title?: string;
	description?: string;
	display_sort_index?: number;
	is_unsubscribable?: boolean;
	option_type?: string;
	applicable_person_types?: string[];
	applicable_communication_channels?: string[];
	default_communication_channel?: string[];
	options?: string[];
	group?: string;
}

export interface ProductClassificationsReferenceDataType {
	data?: ProductClassificationsDataType;
}

export interface ProductClassificationsDataType {
	code?: number;
	division?: string;
	plan?: string;
}

export interface ReferenceDataSubPlans {
	data?: {
		values?: ReferenceDataSubPlanTypes;
	};
	meta?: Meta;
}

export type ReferenceDataSubPlanTypes = ReferenceDataSubPlanDataTypes[];

export interface ReferenceDataSubPlanDataTypes {
	link_code?: string;
	tal_code?: string;
}

/**
 * Used to store metadata of a pagination.
 */
export interface Meta {
	/**
	 * The total number of records for this object
	 */
	total_records?: number;
	/**
	 * The total number of pages for this object
	 */
	total_pages?: number;
}

export type ProductNameValues = 'Accumulation' | 'Pension';

/**
 * Bad request
 */
export type Response400 = Error;

/**
 * Unauthorized
 */
export type Response401 = Error;

/**
 * Not found
 */
export type Response404 = Error;

/**
 * Invalid request payload
 */
export type Response422 = Error;

/**
 * Internal server error
 */
export type Response500 = Error;

/**
 * An error has occurred
 */
export type ErrorResponseResponse = Error;

export interface GetFormStatusQueryParams {
	code?: 'EXPORTED' | 'NOT_EXPORTED';
	include?: string;
}

export type GetFormStatusProps = Omit<
	GetProps<
		FormStatusReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFormStatusQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of form status
 */
export const GetFormStatus = (props: GetFormStatusProps) => (
	<Get<
		FormStatusReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFormStatusQueryParams,
		void
	>
		path={`/datasets/form-status`}
		{...props}
	/>
);

export type UseGetFormStatusProps = Omit<
	UseGetProps<
		FormStatusReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFormStatusQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of form status
 */
export const useGetFormStatus = (props: UseGetFormStatusProps) =>
	useGet<
		FormStatusReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFormStatusQueryParams,
		void
	>(`/datasets/form-status`, props);

export interface GetFinancialYearsQueryParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: 'Accumulation' | 'Pension';
}

export interface GetFinancialYearsPathParams {
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type GetFinancialYearsProps = Omit<
	GetProps<
		FinancialYearReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFinancialYearsQueryParams,
		GetFinancialYearsPathParams
	>,
	'path'
> &
	GetFinancialYearsPathParams;

/**
 * A list of valid financial years for the product and the member join date specified
 */
export const GetFinancialYears = ({
	account_number,
	...props
}: GetFinancialYearsProps) => (
	<Get<
		FinancialYearReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFinancialYearsQueryParams,
		GetFinancialYearsPathParams
	>
		path={`/datasets/${account_number}/financial-years`}
		{...props}
	/>
);

export type UseGetFinancialYearsProps = Omit<
	UseGetProps<
		FinancialYearReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFinancialYearsQueryParams,
		GetFinancialYearsPathParams
	>,
	'path'
> &
	GetFinancialYearsPathParams;

/**
 * A list of valid financial years for the product and the member join date specified
 */
export const useGetFinancialYears = ({
	account_number,
	...props
}: UseGetFinancialYearsProps) =>
	useGet<
		FinancialYearReference,
		Response401 | Response500 | ErrorResponseResponse,
		GetFinancialYearsQueryParams,
		GetFinancialYearsPathParams
	>(
		(paramsInPath: GetFinancialYearsPathParams) =>
			`/datasets/${paramsInPath.account_number}/financial-years`,
		{ pathParams: { account_number }, ...props },
	);

export interface GetSuperFundsQueryParams {
	usi?: string;
	/**
	 * Name of the fund. Required for APRA search
	 */
	fund_name?: string;
	/**
	 * Australian business number. Required For SMSF search
	 */
	abn?: string;
	/**
	 * Default Value is APRA
	 */
	fund_type?: 'ATO' | 'APRA' | 'ALL';
}

export type GetSuperFundsProps = Omit<
	GetProps<
		ReferenceDataSuperFunds,
		Response401 | Response500 | ErrorResponseResponse,
		GetSuperFundsQueryParams,
		void
	>,
	'path'
>;

/**
 * Used to search for Superannuation Funds as potential sources for Rollovers.
 */
export const GetSuperFunds = (props: GetSuperFundsProps) => (
	<Get<
		ReferenceDataSuperFunds,
		Response401 | Response500 | ErrorResponseResponse,
		GetSuperFundsQueryParams,
		void
	>
		path={`/datasets/funds`}
		{...props}
	/>
);

export type UseGetSuperFundsProps = Omit<
	UseGetProps<
		ReferenceDataSuperFunds,
		Response401 | Response500 | ErrorResponseResponse,
		GetSuperFundsQueryParams,
		void
	>,
	'path'
>;

/**
 * Used to search for Superannuation Funds as potential sources for Rollovers.
 */
export const useGetSuperFunds = (props: UseGetSuperFundsProps) =>
	useGet<
		ReferenceDataSuperFunds,
		Response401 | Response500 | ErrorResponseResponse,
		GetSuperFundsQueryParams,
		void
	>(`/datasets/funds`, props);

export type GetSMSFFundsProps = Omit<
	GetProps<
		ReferenceDataSMSFFunds,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * Used to search for a SMSF super funds
 */
export const GetSMSFFunds = (props: GetSMSFFundsProps) => (
	<Get<
		ReferenceDataSMSFFunds,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/smsf-funds`}
		{...props}
	/>
);

export type UseGetSMSFFundsProps = Omit<
	UseGetProps<
		ReferenceDataSMSFFunds,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * Used to search for a SMSF super funds
 */
export const useGetSMSFFunds = (props: UseGetSMSFFundsProps) =>
	useGet<
		ReferenceDataSMSFFunds,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/smsf-funds`, props);

export interface GetAdditionalDataDefinitionsQueryParams {
	additionalDataCategories:
		| 'Member'
		| 'MemberEnquiry'
		| 'Employer'
		| 'EmployerEnquiry';
}

export interface GetAdditionalDataDefinitionsPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
}

export type GetAdditionalDataDefinitionsProps = Omit<
	GetProps<
		AdditionalDataDefinitionsAsync,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAdditionalDataDefinitionsQueryParams,
		GetAdditionalDataDefinitionsPathParams
	>,
	'path'
> &
	GetAdditionalDataDefinitionsPathParams;

/**
 * This API retrieves a list additional data fields for Plan, for member and/or Employee.
 */
export const GetAdditionalDataDefinitions = ({
	product_name,
	...props
}: GetAdditionalDataDefinitionsProps) => (
	<Get<
		AdditionalDataDefinitionsAsync,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAdditionalDataDefinitionsQueryParams,
		GetAdditionalDataDefinitionsPathParams
	>
		path={`/datasets/${product_name}/additional-data-definitions`}
		{...props}
	/>
);

export type UseGetAdditionalDataDefinitionsProps = Omit<
	UseGetProps<
		AdditionalDataDefinitionsAsync,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAdditionalDataDefinitionsQueryParams,
		GetAdditionalDataDefinitionsPathParams
	>,
	'path'
> &
	GetAdditionalDataDefinitionsPathParams;

/**
 * This API retrieves a list additional data fields for Plan, for member and/or Employee.
 */
export const useGetAdditionalDataDefinitions = ({
	product_name,
	...props
}: UseGetAdditionalDataDefinitionsProps) =>
	useGet<
		AdditionalDataDefinitionsAsync,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		GetAdditionalDataDefinitionsQueryParams,
		GetAdditionalDataDefinitionsPathParams
	>(
		(paramsInPath: GetAdditionalDataDefinitionsPathParams) =>
			`/datasets/${paramsInPath.product_name}/additional-data-definitions`,
		{ pathParams: { product_name }, ...props },
	);

export interface GetEmployerClassificationPathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * The employer account ID in LINK
	 */
	employer_id: string;
}

export type GetEmployerClassificationProps = Omit<
	GetProps<
		EmployerClassificationDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetEmployerClassificationPathParams
	>,
	'path'
> &
	GetEmployerClassificationPathParams;

/**
 * employer_number.
 */
export const GetEmployerClassification = ({
	product_name,
	employer_id,
	...props
}: GetEmployerClassificationProps) => (
	<Get<
		EmployerClassificationDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetEmployerClassificationPathParams
	>
		path={`/datasets/${product_name}/${employer_id}/employer-classifications`}
		{...props}
	/>
);

export type UseGetEmployerClassificationProps = Omit<
	UseGetProps<
		EmployerClassificationDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetEmployerClassificationPathParams
	>,
	'path'
> &
	GetEmployerClassificationPathParams;

/**
 * employer_number.
 */
export const useGetEmployerClassification = ({
	product_name,
	employer_id,
	...props
}: UseGetEmployerClassificationProps) =>
	useGet<
		EmployerClassificationDataType,
		Response401 | Response404 | Response500 | ErrorResponseResponse,
		void,
		GetEmployerClassificationPathParams
	>(
		(paramsInPath: GetEmployerClassificationPathParams) =>
			`/datasets/${paramsInPath.product_name}/${paramsInPath.employer_id}/employer-classifications`,
		{ pathParams: { product_name, employer_id }, ...props },
	);

export type GetSubplansProps = Omit<
	GetProps<
		ReferenceDataSubPlans,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * Used to map Link Subplan to TAL Subplan
 */
export const GetSubplans = (props: GetSubplansProps) => (
	<Get<
		ReferenceDataSubPlans,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/subplans`}
		{...props}
	/>
);

export type UseGetSubplansProps = Omit<
	UseGetProps<
		ReferenceDataSubPlans,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * Used to map Link Subplan to TAL Subplan
 */
export const useGetSubplans = (props: UseGetSubplansProps) =>
	useGet<
		ReferenceDataSubPlans,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/subplans`, props);

export interface GetPaymentTypesQueryParams {
	/**
	 * Valid values <br> <b>'supplemental_data'</b> retrieves link codes <br> <b>'view'</b> retrieves properties that is selectable in getPension <br> <b>'edit'</b> retrieves properties that is selectable in postPension
	 */
	include?: string;
}

export type GetPaymentTypesProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentTypesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment types
 */
export const GetPaymentTypes = (props: GetPaymentTypesProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentTypesQueryParams,
		void
	>
		path={`/datasets/payment-types`}
		{...props}
	/>
);

export type UseGetPaymentTypesProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentTypesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment types
 */
export const useGetPaymentTypes = (props: UseGetPaymentTypesProps) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentTypesQueryParams,
		void
	>(`/datasets/payment-types`, props);

export interface GetPaymentFrequenciesQueryParams {
	include?: string;
}

export type GetPaymentFrequenciesProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentFrequenciesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment frequencies
 */
export const GetPaymentFrequencies = (props: GetPaymentFrequenciesProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentFrequenciesQueryParams,
		void
	>
		path={`/datasets/payment-frequencies`}
		{...props}
	/>
);

export type UseGetPaymentFrequenciesProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentFrequenciesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment frequencies
 */
export const useGetPaymentFrequencies = (
	props: UseGetPaymentFrequenciesProps,
) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentFrequenciesQueryParams,
		void
	>(`/datasets/payment-frequencies`, props);

export interface GetPaymentMonthsQueryParams {
	include?: string;
}

export type GetPaymentMonthsProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentMonthsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment months
 */
export const GetPaymentMonths = (props: GetPaymentMonthsProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentMonthsQueryParams,
		void
	>
		path={`/datasets/payment-months`}
		{...props}
	/>
);

export type UseGetPaymentMonthsProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentMonthsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of payment months
 */
export const useGetPaymentMonths = (props: UseGetPaymentMonthsProps) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentMonthsQueryParams,
		void
	>(`/datasets/payment-months`, props);

export type GetPaymentDaysProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of payment days
 */
export const GetPaymentDays = (props: GetPaymentDaysProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/payment-days`}
		{...props}
	/>
);

export type UseGetPaymentDaysProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of payment days
 */
export const useGetPaymentDays = (props: UseGetPaymentDaysProps) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/payment-days`, props);

export interface GetPaymentIndexationFactorsQueryParams {
	include?: string;
}

export type GetPaymentIndexationFactorsProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentIndexationFactorsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of valid payment types
 */
export const GetPaymentIndexationFactors = (
	props: GetPaymentIndexationFactorsProps,
) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentIndexationFactorsQueryParams,
		void
	>
		path={`/datasets/indexation-factors`}
		{...props}
	/>
);

export type UseGetPaymentIndexationFactorsProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentIndexationFactorsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of valid payment types
 */
export const useGetPaymentIndexationFactors = (
	props: UseGetPaymentIndexationFactorsProps,
) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetPaymentIndexationFactorsQueryParams,
		void
	>(`/datasets/indexation-factors`, props);

export type GetChoiceSequencesProps = Omit<
	GetProps<
		ChoiceIncomeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of choice sequences
 */
export const GetChoiceSequences = (props: GetChoiceSequencesProps) => (
	<Get<
		ChoiceIncomeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/choice-sequences`}
		{...props}
	/>
);

export type UseGetChoiceSequencesProps = Omit<
	UseGetProps<
		ChoiceIncomeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of choice sequences
 */
export const useGetChoiceSequences = (props: UseGetChoiceSequencesProps) =>
	useGet<
		ChoiceIncomeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/choice-sequences`, props);

export interface GetInvestmentDetailsQueryParams {
	'include'?: string;
	'status'?: 'ACTIVE' | 'INACTIVE';
	'is-selectable'?: boolean;
}

export type GetInvestmentDetailsProps = Omit<
	GetProps<
		InvestmentReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentDetailsQueryParams,
		void
	>,
	'path'
>;

/**
 * Returns a list of all active investments options that maps LINK specific details to more readable details.
 */
export const GetInvestmentDetails = (props: GetInvestmentDetailsProps) => (
	<Get<
		InvestmentReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentDetailsQueryParams,
		void
	>
		path={`/datasets/investment-details`}
		{...props}
	/>
);

export type UseGetInvestmentDetailsProps = Omit<
	UseGetProps<
		InvestmentReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentDetailsQueryParams,
		void
	>,
	'path'
>;

/**
 * Returns a list of all active investments options that maps LINK specific details to more readable details.
 */
export const useGetInvestmentDetails = (props: UseGetInvestmentDetailsProps) =>
	useGet<
		InvestmentReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentDetailsQueryParams,
		void
	>(`/datasets/investment-details`, props);

export interface GetInvestmentPerformanceQueryParams {
	/**
	 * To specify if account type is TTR
	 */
	account_type?: 'TTR';
}

export interface GetInvestmentPerformancePathParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: ProductNameValues;
	/**
	 * e.g annual-rates
	 */
	type: string;
}

export type GetInvestmentPerformanceProps = Omit<
	GetProps<
		ReferenceDataInvestmentPerformance,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentPerformanceQueryParams,
		GetInvestmentPerformancePathParams
	>,
	'path'
> &
	GetInvestmentPerformancePathParams;

/**
 * Retrieve investment performance data.
 */
export const GetInvestmentPerformance = ({
	product_name,
	type,
	...props
}: GetInvestmentPerformanceProps) => (
	<Get<
		ReferenceDataInvestmentPerformance,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentPerformanceQueryParams,
		GetInvestmentPerformancePathParams
	>
		path={`/datasets/investment-performance/${product_name}/${type}`}
		{...props}
	/>
);

export type UseGetInvestmentPerformanceProps = Omit<
	UseGetProps<
		ReferenceDataInvestmentPerformance,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentPerformanceQueryParams,
		GetInvestmentPerformancePathParams
	>,
	'path'
> &
	GetInvestmentPerformancePathParams;

/**
 * Retrieve investment performance data.
 */
export const useGetInvestmentPerformance = ({
	product_name,
	type,
	...props
}: UseGetInvestmentPerformanceProps) =>
	useGet<
		ReferenceDataInvestmentPerformance,
		Response401 | Response500 | ErrorResponseResponse,
		GetInvestmentPerformanceQueryParams,
		GetInvestmentPerformancePathParams
	>(
		(paramsInPath: GetInvestmentPerformancePathParams) =>
			`/datasets/investment-performance/${paramsInPath.product_name}/${paramsInPath.type}`,
		{ pathParams: { product_name, type }, ...props },
	);

export interface GetCommunicationCasesQueryParams {
	include?: string;
}

export type GetCommunicationCasesProps = Omit<
	GetProps<
		OutboundCommunicationCaseReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCommunicationCasesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of communication cases
 */
export const GetCommunicationCases = (props: GetCommunicationCasesProps) => (
	<Get<
		OutboundCommunicationCaseReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCommunicationCasesQueryParams,
		void
	>
		path={`/datasets/communication-cases`}
		{...props}
	/>
);

export type UseGetCommunicationCasesProps = Omit<
	UseGetProps<
		OutboundCommunicationCaseReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCommunicationCasesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of communication cases
 */
export const useGetCommunicationCases = (
	props: UseGetCommunicationCasesProps,
) =>
	useGet<
		OutboundCommunicationCaseReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCommunicationCasesQueryParams,
		void
	>(`/datasets/communication-cases`, props);

export interface GetRelationshipCodesQueryParams {
	'non-binding-is-selectable'?: boolean;
}

export type GetRelationshipCodesProps = Omit<
	GetProps<
		BeneficiaryRelationshipDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetRelationshipCodesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of relationship codes
 */
export const GetRelationshipCodes = (props: GetRelationshipCodesProps) => (
	<Get<
		BeneficiaryRelationshipDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetRelationshipCodesQueryParams,
		void
	>
		path={`/datasets/beneficiary-relationship-codes`}
		{...props}
	/>
);

export type UseGetRelationshipCodesProps = Omit<
	UseGetProps<
		BeneficiaryRelationshipDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetRelationshipCodesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of relationship codes
 */
export const useGetRelationshipCodes = (props: UseGetRelationshipCodesProps) =>
	useGet<
		BeneficiaryRelationshipDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetRelationshipCodesQueryParams,
		void
	>(`/datasets/beneficiary-relationship-codes`, props);

export type GetNominationTypesProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of nomination types
 */
export const GetNominationTypes = (props: GetNominationTypesProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/beneficiary-nomination-types`}
		{...props}
	/>
);

export type UseGetNominationTypesProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of nomination types
 */
export const useGetNominationTypes = (props: UseGetNominationTypesProps) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/beneficiary-nomination-types`, props);

export interface GetBsbPathParams {
	/**
	 * BSB (Bank-State-Branch) number is a six-digit number (including hyphen seperator) that identifies banks and branches across Australia
	 */
	bsb_number: string;
}

export type GetBsbProps = Omit<
	GetProps<
		BsbReferenceDataType,
		Response401 | Error | Response500 | ErrorResponseResponse,
		void,
		GetBsbPathParams
	>,
	'path'
> &
	GetBsbPathParams;

/**
 * Retrieve BSB (Bank-State-Branch) details identifying an Australian banks or branch.
 */
export const GetBsb = ({ bsb_number, ...props }: GetBsbProps) => (
	<Get<
		BsbReferenceDataType,
		Response401 | Error | Response500 | ErrorResponseResponse,
		void,
		GetBsbPathParams
	>
		path={`/datasets/bsbs/${bsb_number}`}
		{...props}
	/>
);

export type UseGetBsbProps = Omit<
	UseGetProps<
		BsbReferenceDataType,
		Response401 | Error | Response500 | ErrorResponseResponse,
		void,
		GetBsbPathParams
	>,
	'path'
> &
	GetBsbPathParams;

/**
 * Retrieve BSB (Bank-State-Branch) details identifying an Australian banks or branch.
 */
export const useGetBsb = ({ bsb_number, ...props }: UseGetBsbProps) =>
	useGet<
		BsbReferenceDataType,
		Response401 | Error | Response500 | ErrorResponseResponse,
		void,
		GetBsbPathParams
	>(
		(paramsInPath: GetBsbPathParams) =>
			`/datasets/bsbs/${paramsInPath.bsb_number}`,
		{ pathParams: { bsb_number }, ...props },
	);

export type GeInsurancePlanProps = Omit<
	GetProps<
		InsurancePlanReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of insurance plans
 */
export const GeInsurancePlan = (props: GeInsurancePlanProps) => (
	<Get<
		InsurancePlanReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/insurance-plans`}
		{...props}
	/>
);

export type UseGeInsurancePlanProps = Omit<
	UseGetProps<
		InsurancePlanReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of insurance plans
 */
export const useGeInsurancePlan = (props: UseGeInsurancePlanProps) =>
	useGet<
		InsurancePlanReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/insurance-plans`, props);

export interface GetInsuranceDesignsQueryParams {
	/**
	 * Product type. i.e Accumulation or Pension (case sensitive)
	 */
	product_name: 'Accumulation' | 'Pension';
}

export type GetInsuranceDesignsProps = Omit<
	GetProps<
		InsuranceDesignReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInsuranceDesignsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of insurance designs
 */
export const GetInsuranceDesigns = (props: GetInsuranceDesignsProps) => (
	<Get<
		InsuranceDesignReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInsuranceDesignsQueryParams,
		void
	>
		path={`/datasets/insurance-designs`}
		{...props}
	/>
);

export type UseGetInsuranceDesignsProps = Omit<
	UseGetProps<
		InsuranceDesignReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInsuranceDesignsQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of insurance designs
 */
export const useGetInsuranceDesigns = (props: UseGetInsuranceDesignsProps) =>
	useGet<
		InsuranceDesignReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetInsuranceDesignsQueryParams,
		void
	>(`/datasets/insurance-designs`, props);

export interface GetMemberClassificationsQueryParams {
	product_name: string;
}

export interface GetMemberClassificationsPathParams {
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type GetMemberClassificationsProps = Omit<
	GetProps<
		MemberClassificationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetMemberClassificationsQueryParams,
		GetMemberClassificationsPathParams
	>,
	'path'
> &
	GetMemberClassificationsPathParams;

/**
 * A list of member classifications
 */
export const GetMemberClassifications = ({
	account_number,
	...props
}: GetMemberClassificationsProps) => (
	<Get<
		MemberClassificationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetMemberClassificationsQueryParams,
		GetMemberClassificationsPathParams
	>
		path={`/datasets/${account_number}/member-classifications`}
		{...props}
	/>
);

export type UseGetMemberClassificationsProps = Omit<
	UseGetProps<
		MemberClassificationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetMemberClassificationsQueryParams,
		GetMemberClassificationsPathParams
	>,
	'path'
> &
	GetMemberClassificationsPathParams;

/**
 * A list of member classifications
 */
export const useGetMemberClassifications = ({
	account_number,
	...props
}: UseGetMemberClassificationsProps) =>
	useGet<
		MemberClassificationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetMemberClassificationsQueryParams,
		GetMemberClassificationsPathParams
	>(
		(paramsInPath: GetMemberClassificationsPathParams) =>
			`/datasets/${paramsInPath.account_number}/member-classifications`,
		{ pathParams: { account_number }, ...props },
	);

export interface GetProductOccupationsPathParams {
	/**
	 * The member account ID in LINK
	 */
	account_number: string;
}

export type GetProductOccupationsProps = Omit<
	GetProps<
		OccupationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		GetProductOccupationsPathParams
	>,
	'path'
> &
	GetProductOccupationsPathParams;

/**
 * A list of member occupations based on product name
 */
export const GetProductOccupations = ({
	account_number,
	...props
}: GetProductOccupationsProps) => (
	<Get<
		OccupationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		GetProductOccupationsPathParams
	>
		path={`/datasets/${account_number}/occupational-ratings`}
		{...props}
	/>
);

export type UseGetProductOccupationsProps = Omit<
	UseGetProps<
		OccupationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		GetProductOccupationsPathParams
	>,
	'path'
> &
	GetProductOccupationsPathParams;

/**
 * A list of member occupations based on product name
 */
export const useGetProductOccupations = ({
	account_number,
	...props
}: UseGetProductOccupationsProps) =>
	useGet<
		OccupationReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		GetProductOccupationsPathParams
	>(
		(paramsInPath: GetProductOccupationsPathParams) =>
			`/datasets/${paramsInPath.account_number}/occupational-ratings`,
		{ pathParams: { account_number }, ...props },
	);

export type GetPartyTypeProps = Omit<
	GetProps<
		PartyTypeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of party types
 */
export const GetPartyType = (props: GetPartyTypeProps) => (
	<Get<
		PartyTypeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/party-types`}
		{...props}
	/>
);

export type UseGetPartyTypeProps = Omit<
	UseGetProps<
		PartyTypeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of party types
 */
export const useGetPartyType = (props: UseGetPartyTypeProps) =>
	useGet<
		PartyTypeReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/party-types`, props);

export type GetDeliveryMethodProps = Omit<
	GetProps<
		DeliveryMethodReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of delivery methods
 */
export const GetDeliveryMethod = (props: GetDeliveryMethodProps) => (
	<Get<
		DeliveryMethodReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/delivery-methods`}
		{...props}
	/>
);

export type UseGetDeliveryMethodProps = Omit<
	UseGetProps<
		DeliveryMethodReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of delivery methods
 */
export const useGetDeliveryMethod = (props: UseGetDeliveryMethodProps) =>
	useGet<
		DeliveryMethodReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/delivery-methods`, props);

export type GetDeliverySourceProps = Omit<
	GetProps<
		DeliverySourceReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of delivery sources
 */
export const GetDeliverySource = (props: GetDeliverySourceProps) => (
	<Get<
		DeliverySourceReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/delivery-sources`}
		{...props}
	/>
);

export type UseGetDeliverySourceProps = Omit<
	UseGetProps<
		DeliverySourceReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of delivery sources
 */
export const useGetDeliverySource = (props: UseGetDeliverySourceProps) =>
	useGet<
		DeliverySourceReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/delivery-sources`, props);

export interface GetCountryCodesQueryParams {
	include?: string;
}

export type GetCountryCodesProps = Omit<
	GetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCountryCodesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of country codes
 */
export const GetCountryCodes = (props: GetCountryCodesProps) => (
	<Get<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCountryCodesQueryParams,
		void
	>
		path={`/datasets/country-codes`}
		{...props}
	/>
);

export type UseGetCountryCodesProps = Omit<
	UseGetProps<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCountryCodesQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of country codes
 */
export const useGetCountryCodes = (props: UseGetCountryCodesProps) =>
	useGet<
		ReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetCountryCodesQueryParams,
		void
	>(`/datasets/country-codes`, props);

export type GetMemberCommunicationPreferencesProps = Omit<
	GetProps<
		MemberCommPrefReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of member communication preferences
 */
export const GetMemberCommunicationPreferences = (
	props: GetMemberCommunicationPreferencesProps,
) => (
	<Get<
		MemberCommPrefReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>
		path={`/datasets/member-communication-preferences`}
		{...props}
	/>
);

export type UseGetMemberCommunicationPreferencesProps = Omit<
	UseGetProps<
		MemberCommPrefReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>,
	'path'
>;

/**
 * A list of member communication preferences
 */
export const useGetMemberCommunicationPreferences = (
	props: UseGetMemberCommunicationPreferencesProps,
) =>
	useGet<
		MemberCommPrefReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		void,
		void
	>(`/datasets/member-communication-preferences`, props);

export interface GetDivisionQueryParams {
	/**
	 * The member classification code
	 */
	classification_code: number;
}

export type GetDivisionProps = Omit<
	GetProps<
		ProductClassificationsReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetDivisionQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of product classifications
 */
export const GetDivision = (props: GetDivisionProps) => (
	<Get<
		ProductClassificationsReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetDivisionQueryParams,
		void
	>
		path={`/datasets/division`}
		{...props}
	/>
);

export type UseGetDivisionProps = Omit<
	UseGetProps<
		ProductClassificationsReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetDivisionQueryParams,
		void
	>,
	'path'
>;

/**
 * A list of product classifications
 */
export const useGetDivision = (props: UseGetDivisionProps) =>
	useGet<
		ProductClassificationsReferenceDataType,
		Response401 | Response500 | ErrorResponseResponse,
		GetDivisionQueryParams,
		void
	>(`/datasets/division`, props);
