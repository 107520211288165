import styled, { css } from 'styled-components';

import * as mx from 'core/styles/mixins';

export const Icon = styled.i<{
	rotate?: number;
	size: number;
	flip?: boolean;
}>`
	position: relative;
	display: inline-flex;
	color: inherit;
	justify-content: center;
	align-items: center;
	transition: 0.3s all ease-in-out;

	${({ rotate, flip }) =>
		(!!rotate || !!flip) &&
		css`
			transform: ${!!rotate && `rotate(${rotate}deg)`} ${!!flip && 'scaleX(-1)'};
			transition: 0.3s all ease-in-out;
		`}

	${({ size }) => css`
		${mx.square(`${size}px`)}
	`}

	svg {
		${mx.alignCentre}
		${mx.square('100%')}
	}

	/** @note This is an NOI specific or custom style for their modal icons.
 	See https://australiansuper.atlassian.net/browse/MI0031-1062 for reference ***/
	&&.claimableAmountModalIcon {
		margin-left: 3px;
	}
`;
