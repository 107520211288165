import { createGlobalStyle, css } from 'styled-components';

import * as mx from 'core/styles/mixins';
import { classNameCtas, JSS_CTA_CLASS } from 'core/components/Cta/Cta.style';
import { Icon } from 'core/components/Icon/Icon.style';
import {
	classNameTypography,
	globalTypography,
} from 'core/styles/Typography/Typography.style';
import sitecoreRteStyles from 'core/styles/sitecore/sitecoreRteStyles';
import { GlobalSelectPortal } from 'pods/forms/components/inputs/Select/Select.style';

const GlobalStyles = createGlobalStyle`
	/* ----------------------------------------
	Edge cases */

	/* Sitecore and localhost "connected mode" share cookies, and Sitecore
	renders excess code data onto the page; we can hide that so as not to confuse things.
	Ref: https://sitecore.stackexchange.com/questions/12812/layout-api-returning-strange-code-into-placeholder */
	code[type='text/sitecore'],
	.scChromeData {
		display: none;
	}

	/* Overcome limitation of DynamicSubLayout 'Wrapper'
	not having overflow: hidden, and potentially able to push
	out total document width if used at the very root of the
	document, i.e. against left/right page borders. */
	html {
		overflow-x: hidden;
	}

	/* JSS Cta links (driven by <Cta/>) are converted to spans
	with anchors inside them in the Experience Editor; this causes
	issues with those anchors taking the global anchor color. By
	forcefully making these temporary, experience-editor-only links
	to inherit their colour, the colour will be set correctly. */
	.${JSS_CTA_CLASS} a.scEnabledChrome {
		color: inherit !important;
	}

	/* ----------------------------------------
	Box sizing defaults
	Ref: https://css-tricks.com/box-sizing/ */

	html {
		box-sizing: border-box;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	/* ---------------------------------------
	Theme driven */

	${({ theme }) => css`
		html {
			background-color: ${theme.global.page.color};
		}

		/* Default hyperlink colour, plus any associated adjacent icon */
		a {
			&,
			+ ${Icon} {
				color: ${theme.global.typography.hyperlinks.color};
			}

			&:hover {
				color: ${theme.global.typography.hyperlinks.hover};

				div span {
					color: ${theme.global.typography.hyperlinks.hover};
				}
			}
		}

		hr {
			border-top: 0;
			border-bottom: 1px solid ${theme.global.borders.module};
		}
	`}


	/* ---------------------------------------
	Typography */

	::selection {
		${({ theme }) => css`
			background-color: ${theme.global.typography.highlight.color};
			color: ${theme.global.typography.highlight.text};
		`}
	}

	/* NOTE: Component-level typography is driven by the styled
	component exports found in 'core/styles/Typography'. */
	${globalTypography}

	/* ----------------------------------------
	Sitecore's Rich-Text Editor (RTE)
	- i.e. class-driven rich text */

	/* Styled for RTE typography
	- (proxy for typography component styles) */
	${classNameTypography}

	/* Styles for RTE hyperlinks to look like CTAs
	- (proxy for Cta component styles) */
	${classNameCtas}

	/* Styles for patterns that otherwise dont exist
	outside of Sitecore's Rich Text Editor (like img alignment) */
	${sitecoreRteStyles}

	/* ----------------------------------------
	Accessibility */

	.vh {
		${mx.visuallyhidden}
	}

	/* ----------------------------------------
	Portals */

	${GlobalSelectPortal}
`;

export default GlobalStyles;
