import React, { useState, useEffect } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createHash } from 'crypto';

import { isWebBrowser } from 'core/utils/global-utils';
import { AI } from 'core/services/AppInsights';
import componentFactory from 'jss-boilerplate/temp/componentFactory';
import SitecoreContextFactory from 'jss-boilerplate/SitecoreContextFactory';
import RouteHandler from 'jss-boilerplate/RouteHandler';

import LoadingScreen from './LoadingScreen';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
	'/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
	'/:lang([a-z]{2})/:sitecoreRoute*',
	'/:sitecoreRoute*',
];

if (isWebBrowser) {
	// initialize App Insights only inside browser
	const history = createBrowserHistory({ basename: '' });
	AI.initialize({ history });
}

// wrap the app with:
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router }) => {
	const routeRenderFunction = props => <RouteHandler route={props} />;
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		SitecoreContextFactory.subscribeToContext(context => {
			// added dummy GTM id if Sitecore fails to return metadata.
			const storeId = context?.metadata?.storeId ?? '2072666';

			/**
			 * 'LPTag'.
			 * Ref: https://developers.liveperson.com/le-tag-overview.html
			 *
			 * Global LP tag that exists on every page for the purpose of session
			 * management between pages. Enables the team at AusSuper who manage the
			 * LivePerson dashboard to run campaigns and utilise other LivePerson features.
			 */
			(function(d, s, i) {
				const f = d.getElementsByTagName(s)[0],
					j = d.createElement(s);
				j.async = true;
				j.innerHTML = `
				(window.lpTag = window.lpTag || {}),
				  "undefined" == typeof window.lpTag._tagCount
					? ((window.lpTag = {
						wl: lpTag.wl || null,
						scp: lpTag.scp || null,
						site: ${i} || "",
						section: lpTag.section || "",
						tagletSection: lpTag.tagletSection || null,
						autoStart: lpTag.autoStart !== !1,
						ovr: lpTag.ovr || {},
						_v: "1.10.0",
						_tagCount: 1,
						protocol: "https:",
						events: {
						  bind: function (t, e, i) {
							lpTag.defer(function () {
							  lpTag.events.bind(t, e, i);
							}, 0);
						  },
						  trigger: function (t, e, i) {
							lpTag.defer(function () {
							  lpTag.events.trigger(t, e, i);
							}, 1);
						  },
						},
						defer: function (t, e) {
						  0 === e
							? ((this._defB = this._defB || []), this._defB.push(t))
							: 1 === e
							? ((this._defT = this._defT || []), this._defT.push(t))
							: ((this._defL = this._defL || []), this._defL.push(t));
						},
						load: function (t, e, i) {
						  var n = this;
						  setTimeout(function () {
							n._load(t, e, i);
						  }, 0);
						},
						_load: function (t, e, i) {
						  var n = t;
						  t ||
							(n =
							  this.protocol +
							  "//" +
							  (this.ovr && this.ovr.domain
								? this.ovr.domain
								: "lptag.liveperson.net") +
							  "/tag/tag.js?site=" +
							  this.site);
						  var o = document.createElement("script");
						  o.setAttribute("charset", e ? e : "UTF-8"),
							i && o.setAttribute("id", i),
							o.setAttribute("src", n),
							document.getElementsByTagName("head").item(0).appendChild(o);
						},
						init: function () {
						  (this._timing = this._timing || {}),
							(this._timing.start = new Date().getTime());
						  var t = this;
						  window.attachEvent
							? window.attachEvent("onload", function () {
								t._domReady("domReady");
							  })
							: (window.addEventListener(
								"DOMContentLoaded",
								function () {
								  t._domReady("contReady");
								},
								!1
							  ),
							  window.addEventListener(
								"load",
								function () {
								  t._domReady("domReady");
								},
								!1
							  )),
							"undefined" === typeof window._lptStop && this.load();
						},
						start: function () {
						  this.autoStart = !0;
						},
						_domReady: function (t) {
						  this.isDom ||
							((this.isDom = !0),
							this.events.trigger("LPT", "DOM_READY", { t: t })),
							(this._timing[t] = new Date().getTime());
						},
						vars: lpTag.vars || [],
						dbs: lpTag.dbs || [],
						ctn: lpTag.ctn || [],
						sdes: lpTag.sdes || [],
						hooks: lpTag.hooks || [],
						identities: lpTag.identities || [],
						ev: lpTag.ev || [],
					  }),
					  lpTag.init())
					: (window.lpTag._tagCount += 1);
									`;
				f.parentNode.insertBefore(j, f);
			})(document, 'script', storeId);
		});
		setIsLoading(false);
	}, []);

	/**
	 * 'LP Chat Authentication - Start'.
	 */
	const lpIdentityFunction = (url, partyTypeId) => {
		lpTag.identities = lpTag.identities || [];
		const hashValuePartyTypeId = createHash('sha256')
			.update(partyTypeId)
			.digest('hex');
		// This function tells LP monitoring the person is a known consumer from brand point of view (which is verified with idp),
		// allowing authenticated engagements to be presented to the consumer
		// Refer to issuer and sub here: https://developers.liveperson.com/consumer-authentication-authenticate-in-web-and-mobile-messaging-implementation-guide.html
		function identityFn(callback) {
			callback({
				iss: 'STA0100AU',
				acr: 'loa1',
				sub: hashValuePartyTypeId,
			});
		}

		if (hashValuePartyTypeId) {
			lpTag.identities.push(identityFn);

			if (lpTag?.identities?.safeIdentities?.length > 0) {
				lpTag.newPage(url);
			} else {
				console.log('lpTag.newPage is not ready to fire...');
			}
		} else {
			console.log('Party type id is not yet available');
		}
	};

	useEffect(() => {
		window.addEventListener('load', function() {
			SitecoreContextFactory.subscribeToContext(context => {
				const partyTypeId =
					context?.memberContext?.memberData?.party_type_id ?? '';
				lpIdentityFunction(`${window.location.href}`, partyTypeId);
			});
		});

		setIsLoading(false);
	}, []);

	/**
	 * 'LP Chat Authentication - End'.
	 */

	if (isLoading) {
		return <LoadingScreen />;
	}

	return (
		<SitecoreContext
			componentFactory={componentFactory}
			contextFactory={SitecoreContextFactory}
		>
			<Router location={path} context={{}}>
				<Switch>
					{routePatterns.map(routePattern => (
						<Route
							key={routePattern}
							path={routePattern}
							render={routeRenderFunction}
						/>
					))}
				</Switch>
			</Router>
		</SitecoreContext>
	);
};

export default AppRoot;
