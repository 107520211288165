export enum PRODUCT_NAME {
	ACCUMULATION = 'Accumulation',
	PENSION = 'Pension',
}

export enum TRANSACTION_SUMMARY_TYPE {
	ACCOUNT_BALANCES_ESTIMATED_EARNINGS = 'ACCOUNT_BALANCES_ESTIMATED_EARNINGS',
	CONTRIBUTIONS = 'CONTRIBUTIONS',
	INVESTMENT_RETURNS = 'INVESTMENT_RETURNS',
	FEES = 'FEES',
	INSURANCE_PREMIUMS = 'INSURANCE_PREMIUMS',
	GOVERNMENT_TAXES = 'GOVERNMENT_TAXES',
	INCOME_PAYMENTS = 'INCOME_PAYMENTS',
}

export enum TRANSACTIONS_URL_PARAM_KEYS {
	TRANSACTION_TYPE = 'transactionType',
	FY = 'financialYear',
}
