import { Member } from 'core/api/client/MemberApi';

/**
 * Checks if user has multiple accounts
 *
 * @param member - current member object from MemberContext
 */
export const isMultipleAccounts = (member?: Member): boolean => {
	if (!member || !member.accounts) {
		return false;
	}

	return member?.accounts?.length > 1 ?? false;
};
