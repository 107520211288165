import { keyframes } from 'styled-components';

export const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
`;

export const pulse = keyframes`
	0% {
		left: -200px;
	}

	100% {
		left: calc(100% + 200px);
	}
`;

export const threeDotsFlashing = keyframes`
	0%{
		opacity: 0;
	}
	33.333333%{
		opacity: 0.2;
	}
	66.66666%{	
		opacity: 0.7;
	}
	100% {
		opacity: 0;
	}
`;
