import { Address } from 'core/api/client/MemberApi';

/**
 * Get member's full address by with the format found in the link below
 * See E18: {@link https://confluence.australiansuper.net.au/display/MemberPortalV5/T10.E02.P01+-+Member+Contact+Details+Module}
 *
 * @param address - One of member's addresses from MemberContext
 * @param isRichText - Whether to add line breaks to the address or not
 */
export const getFormattedAddress = (
	address?: Address,
	isRichText = false,
): string => {
	if (!address) {
		return '';
	}

	const isInternational = address.country.toLowerCase() !== 'australia';

	return `${address.address_line1 ?? ''} ${
		address.address_line2 && address.address_line2.trim()
			? ` ${address.address_line2}`
			: ''
	}
	${isRichText ? '<br />' : ''}
	${address.city}, ${address.state} ${address.postcode}${
		isRichText ? '<br />' : ''
	}
	${isInternational ? address.country : ''}`.trim();
};
