/**
 * NOTE: ONLY use single class name selectors here. Don't chain
 * selectors like `img.some-class` or `.some-class.-other-class`;
 * Sitecore will NOT detect these selectors and if you're trying to
 * get them to appear in the RTE Dropdown via `ToolsFile.xml` - they
 * won't appear. Due to this limitation, ensure your selectors here
 * (and anywhere else pertaining to the RTE, ToolsFile/dropdown values
 * especially) are ** single selectors only **.
 */

import { css } from 'styled-components';

import { notificationStyles } from 'core/components/InlineNotification/InlineNotification.style';
import { bodySmall } from 'core/styles/Typography/Typography.style';
import { ICONS, cssIcon } from 'core/components/Icon';
import * as mx from 'core/styles/mixins';

// --------------------------------
/* eslint-disable @typescript-eslint/camelcase */

/**
 * ! HACK
 *
 * Since the download/link lists class names are applied to the contents
 * of each list item (as opposed to the list or list item itself) we are
 * unable to hide the default dot point list markets. Instead, we need to
 * set a background colour to our icons that match the page/module background
 * so it only appears that the dot point is hidden.
 */
const hideDotPoint_HACK = css`
	background-color: ${({ theme }) => theme.global.page.color};

	/* If within a module, hide using the default module background */
	.core-component--card & {
		background-color: ${({ theme }) => theme.global.module.color};
	}
`;

// --------------------------------

const sitecoreRteStyles = css`
	.rte-img--left {
		display: block;
		margin-right: auto;
	}

	.rte-img--right {
		display: block;
		margin-left: auto;
	}

	.rte-img--full {
		display: block;
		width: 100% !important;
		height: auto !important;
	}

	.rte-banner--info {
		${({ theme }) =>
			notificationStyles({
				theme,
			})}

		/* Since the main InlineNotification component uses a
		Typo.BodySmall component, we can merely emulate that here */
		${bodySmall}
	}

	/* --------------------------------
	External link icon

	NOTE: This class name must be manually applied in the hyperlink
	manager when adding a link (one can also select a class to apply).
	This cannot be accurately inferred based on things like the 'target'
	attribute, and even if we could, it might not always be what a
	content author wants displayed. This way they have explicit control. */

	.rte-link--external {
		&::after {
			${mx.pseudo}
			${mx.square('14px')}
			margin-left: 5px;
			top: 2px;
			position: relative;
			display: inline-block;
			background: ${({ theme }) =>
				cssIcon(ICONS.LAUNCH, theme.global.typography.colors.active)};
		}
	}

	/* --------------------------------
	Custom lists

	NOTE: Even if a content author selects the entire list to
	apply this class name, the Experience Editor will still apply
	the below class names as a wrapper span to the contents of
	each individual list item. */

	.rte-list--link a {
		text-decoration: none;

		&::before {
			${mx.pseudo}
			${mx.square('24px')}
			left: -20px;
			background: ${({ theme }) =>
				cssIcon(ICONS.ARROW_RIGHT_ALT, theme.global.typography.colors.active)};
			${hideDotPoint_HACK}
		}
	}

	.rte-list--download a {
		text-decoration: none;

		&::before {
			${mx.pseudo}
			${mx.square('22px')}
			left: -22px;
			top: 1px;
			background: ${({ theme }) =>
				cssIcon(ICONS.DOWNLOAD, theme.global.typography.colors.active)};
			${hideDotPoint_HACK}
		}
	}

	/* ----------------------------- */
`;

export default sitecoreRteStyles;
