import React from 'react';
import ReactHtmlParser from 'react-html-parser';

interface IProps {
	content: string;
}

const Richtext = ({ content }: IProps) => (
	<>
		{(content &&
			ReactHtmlParser(
				content?.replaceAll('&lt;', '<')?.replaceAll('&gt;', '>'),
			)) || <></>}
	</>
);

export default Richtext;
