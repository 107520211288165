import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const sessionTimeLeft = 'MPV5SessionTimer';

export const getSessionTimeLeft = (): number => {
	const timeLeft = getLocalStorageItem(sessionTimeLeft);

	return timeLeft === '' || typeof timeLeft === 'undefined'
		? 1200000
		: parseInt(timeLeft);
};

export const setSessionTimeLeft = (tokenValue: number) => {
	setLocalStorageItem(sessionTimeLeft, String(tokenValue));
};

export const removeSessionTimeLeft = () => {
	removeLocalStorageItem(sessionTimeLeft);
};
