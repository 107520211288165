/* eslint-disable @typescript-eslint/camelcase */

import config from 'jss-boilerplate/temp/config.js';

import api from './api';

const bsbBase = `${config.australianSuperApi.apiDomain}${config.australianSuperApi.apiPathReferenceData}`;

export const useBsbValidator = (bsbInput?: string | null) => {
	const { useGetBsb } = api;

	const convertedString = bsbInput?.toString().replace('-', '');
	const firstStr = convertedString?.substr(0, 3);
	const secondStr = convertedString?.substr(3, 6);
	const cleanedBsb = `${firstStr}-${secondStr}`;

	const { data: bsbResponse } = useGetBsb({
		base: bsbBase,
		bsb_number: cleanedBsb,
	});

	if (
		cleanedBsb?.toLowerCase() !== bsbResponse?.data?.bsb_number.toLowerCase()
	) {
		return false;
	} else {
		return true;
	}
};
