import axios from 'axios';
import {
	getAccessToken,
	getSelectedAccountNumber,
	getSessionTimeLeft,
} from 'core/browserStorage';
import { isInvalidLocalStorage } from 'core/utils/global-utils';

/**
 * Implements a data fetcher using Axios - replace with your favorite
 * SSR-capable HTTP or fetch library if you like. See HttpJsonFetcher<T> type
 * in sitecore-jss library for implementation details/notes.
 * @param {string} url The URL to request; may include query string
 * @param {any} data Optional data to POST with the request.
 */
export function dataFetcher(url, data) {
	const isDevelopmentEnv = process.env.NODE_ENV === 'development';
	const accessToken = getAccessToken();

	const selectedAccountNumber = getSelectedAccountNumber();
	const sessionTimeLeft = getSessionTimeLeft();

	const validatedSessionTimeLeft = isInvalidLocalStorage(sessionTimeLeft)
		? null
		: { sessionTimeLeft };

	const validatedAccountNumber = isInvalidLocalStorage(selectedAccountNumber)
		? null
		: { selectedAccountNumber };

	return axios({
		url,
		method: data ? 'POST' : 'GET',
		data,
		// note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
		// which is necessary for analytics and such
		withCredentials: !isDevelopmentEnv,
		headers: accessToken
			? {
					Authorization: `Bearer ${accessToken}`,
					...validatedAccountNumber,
					...validatedSessionTimeLeft,
			  }
			: null,
	});
}
