import React from 'react';

import * as S from './JssPlaceholder.style';

interface IProps {
	name: string;
}

/**
 * Rendered when a Placeholder has either has no contents, or
 * when rendered outside of a Sitecore JSS context.
 */
const EmptyPlaceholder = ({ name }: IProps) => (
	<S.Wrapper color="grey">
		<S.EmptyPlaceholder>
			<S.Title>
				<strong>Empty Placeholder:</strong>
				<br />
				<span>{`'${name}'`}</span>
			</S.Title>
		</S.EmptyPlaceholder>
	</S.Wrapper>
);

export default EmptyPlaceholder;
