import { FinancialYearReferenceDataType } from 'core/api/client/ReferenceDataApi';

/**
 * This return an object of Current financial year
 *
 * @param fyValues - an array of financial years associated with member's account
 */
export const getFormattedCurrentFY = (
	fyValues?: FinancialYearReferenceDataType[],
) => {
	const filteredFY =
		fyValues?.filter(
			item => item.description?.toLowerCase() === 'current financial year',
		) ?? [];

	return filteredFY[0]?.date_range;
};

/**
 * This return an object of the last financial year
 *
 * @param fyValues - an array of financial years associated with member's account
 */
export const getFormattedLastFY = (
	fyValues?: FinancialYearReferenceDataType[],
) => {
	return fyValues?.slice(-1)[0]?.date_range;
};

/***
 * Retrieves the formatted date range of the second financial year from the given array.
 *
 * @param fyValues - An optional array of financial year references.
 * @returns The date range of the second financial year, or undefined if not found.
 */
export const getFormattedPreviousFY = (
	fyValues?: FinancialYearReferenceDataType[],
) => {
	// Slice the array to get the second financial year, or an empty array if fyValues is undefined or empty
	const slicedFyArray = fyValues?.slice(1, 2) ?? [];

	// Retrieve the second financial year from the sliced array, or undefined if not found
	const secondFy = slicedFyArray.length > 0 ? slicedFyArray[0] : undefined;

	// Extract the date range from the second financial year, or undefined if secondFy is undefined
	const dateRange = secondFy?.date_range;

	return dateRange;
};
