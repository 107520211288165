/* Note this Context's Provider is applied in _AppContainer */

import React from 'react';

import { Member } from 'core/interface/MemberAccount';

// Note fallback member is applied in src/_AppContainer
export const MemberContext = React.createContext({});

/**
 * HOC for the React Context 'MemberContext'; injects the
 * 'member' prop into the provided <Component />.
 *
 * @param {Node} Component
 */
export const withMemberContext = <P extends object>(
	Component: React.ComponentType<P>,
): React.FC<Pick<P, Exclude<keyof P, keyof object>>> => {
	const WithMemberContext = (
		props: Pick<P, Exclude<keyof P, keyof object>>,
	) => {
		return (
			<MemberContext.Consumer>
				{member => <Component {...(props as P)} {...{ member }} />}
			</MemberContext.Consumer>
		);
	};

	const wrappedComponentName =
		(Component.displayName ?? Component.name) || 'Component';
	WithMemberContext.displayName = `withMemberContext(${wrappedComponentName})`;

	return WithMemberContext;
};

export const useMemberContext = () =>
	React.useContext<Member>(MemberContext as any);
