/* stylelint-disable selector-type-no-unknown */

import styled, { css } from 'styled-components';

import * as vr from 'core/styles/variables';
import * as mx from 'core/styles/mixins';
import Cta from 'core/components/Cta';
import { FONT_WEIGHT } from 'core/styles/fonts';

import { Label } from 'pods/forms/components/base/Label/Label.style';

export const baseInputStyles = ({ hasError, readOnly, disabled }: any) => css`
	width: 100%;
	color: ${vr.formfieldColor};
	padding: 17px 45px;
	padding-left: ${vr.formfieldTextInset};
	border: 1px solid ${vr.formfieldBorderColor};
	box-shadow: 0 0 0 1px transparent;
	border-radius: ${vr.formfieldBorderRadius};
	line-height: 1.5;

	${readOnly &&
		css`
			border: 0;
			padding: 0;
		`}

	${disabled &&
		css`
			border-color: ${vr.radioCheckboxDisabled};
			color: ${vr.radioCheckboxDisabled};
			background-color: ${vr.formfieldDisabledBackground};
		`}

	${hasError &&
		css`
			margin: 0;
			border: 1px solid ${vr.formfieldErrorBorderColor};
			box-shadow: 0 0 0 1px ${vr.formfieldErrorBorderColor};
			color: ${vr.formfieldErrorBorderColor};
			outline: none;
		`}


	/* Hide browser 'clear' buttons */
	&::-ms-clear,
	&::-webkit-clear-button {
		display: none;
	}

	&:focus {
		margin: 0;

		${hasError &&
			css`
				margin: 0;
				border: 1px solid ${vr.formfieldErrorBorderColor};
				box-shadow: 0 0 0 1px ${vr.formfieldErrorBorderColor};
				color: ${vr.formfieldErrorBorderColor};
				outline: none;
			`}

		~ ${Label} {
			color: ${vr.formfieldBorderColorFocus};
		}
	}

	~ ${Label} {
		color: ${vr.formlabelColor};
		font-size: ${vr.formlabelFontSize};
		line-height: ${vr.formfieldLineHeight};
		padding: 0 10px;
		transition-property: top, font-size, color;
		transition-duration: 200ms;
	}
`;

export const Input = styled.input.attrs(props => ({ className: '' }))<{
	isInline: boolean;
	hasStartAdornment: boolean;
	hasEndAdornment: boolean;
	hasError: boolean;
	disabled: boolean;
	readOnly: boolean;
	fullWidth: boolean;
}>`
	${props => baseInputStyles(props)}

	padding: 12px 16px;

	${({ isInline }) =>
		isInline &&
		css`
			display: inline-block;
			width: auto;
		`}

	${({ hasStartAdornment }) =>
		hasStartAdornment &&
		css`
			padding-left: 30px;
		`}

	${({ hasEndAdornment }) =>
		hasEndAdornment &&
		css`
			padding-right: 30px;
		`}

	&[type='date'] {
		padding-right: 15px;
	}

	&[type='date']::-webkit-inner-spin-button,
	&[type='date']::-webkit-outer-spin-button,
	&[type='number']::-webkit-inner-spin-button,
	&[type='number']::-webkit-outer-spin-button {
		appearance: none;
		margin: 0;
	}

	&:focus {
		color: ${props =>
			!!props.hasError
				? vr.formfieldOnHoverBorderColor
				: vr.formfieldOnHoverBorderColor};
	}

	&::placeholder {
		color: ${props =>
			!!props.hasError
				? vr.formfieldErrorColor
				: vr.formfieldOnHoverBorderColor};
	}
	&:focus::placeholder {
		color: ${props =>
			!!props.hasError
				? vr.formfieldOnHoverBorderColor
				: vr.formfieldOnHoverBorderColor};
	}

	${props =>
		props.readOnly
			? css`
					border: 2px solid ${vr.borderGrey};
			  `
			: ``}

	${({ hasError, disabled }) =>
		!disabled &&
		css`
			&:hover {
				outline: none;
				border: 1px solid
					${hasError ? vr.formfieldErrorColor : vr.formfieldOnHoverBorderColor};
				box-shadow: 0 0 0 1px
					${hasError ? vr.formfieldErrorColor : vr.formfieldOnHoverBorderColor};
			}

			&:focus {
				outline: none;
				border: 1px solid
					${hasError
						? vr.formfieldBorderColorFocus
						: vr.formfieldBorderColorFocus};
				box-shadow: 0 0 0 1px
					${hasError
						? vr.formfieldBorderColorFocus
						: vr.formfieldBorderColorFocus};
			}
		`}

`;

export const ButtonWithin = styled.button<{
	hasCloseIcon: boolean | undefined;
	hasValue: boolean;
}>`
	${mx.buttonReset}
	${mx.tabFocus}

	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2;
	color: ${vr.formfieldBorderColorFocus};
	cursor: pointer;
	visibility: hidden;
	padding-right: 10px;

	${Input}:focus-within + & {
		visibility: ${props => (props.hasValue ? `visible` : `hidden`)};
	}
`;

export const ShowHideLabel = styled(Cta)`
	${mx.buttonReset}
	${mx.tabFocus}

	font-size: 14px;
	font-weight: ${FONT_WEIGHT.SEMI_BOLD};
`;

export const Container = styled.div<{
	startAdornment?: string;
	endAdornment?: string;
	fullWidth?: boolean;
}>`
	position: relative;

	${({ fullWidth }) =>
		!!fullWidth &&
		css`
			max-width: 100%;
		`}

	${({ startAdornment }) =>
		!!startAdornment &&
		css`
			&::before {
				position: absolute;
				content: '${startAdornment}';
				top: 20px;
				left: 10px;
				color: ${vr.formfieldPlaceholderColor};

			}
		`}

	${({ endAdornment }) =>
		!!endAdornment &&
		css`
			&::after {
				position: absolute;
				content: '${endAdornment}';
				top: 20px;
				right: 10px;
				color: ${vr.formfieldPlaceholderColor};
			}
		`}
`;
