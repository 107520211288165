import React from 'react';

// Sitecore `isExperienceEditorActive()` proxy;
// (and emulating this mode outside of Sitecore)
const ExperienceEditorContext = React.createContext(false);

/**
 * Hook alternative to use the Experience Editor.
 */
export const useExperienceEditorContext = () =>
	React.useContext(ExperienceEditorContext);

/**
 * HOC for the React Context 'ExperienceEditorContext'; injects the
 * 'isExperienceEditorActive' prop into the provided <Component />.
 */
export const withExperienceEditorContext = <P extends object>(
	Component: React.ComponentType<P>,
): React.FC<P & {
	isExperienceEditorActive: boolean;
}> => props => (
	// eslint-disable-next-line react/display-name
	<ExperienceEditorContext.Consumer>
		{isExperienceEditorActive => (
			<Component {...(props as P)} {...{ isExperienceEditorActive }} />
		)}
	</ExperienceEditorContext.Consumer>
);

export default ExperienceEditorContext;
