import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { Link as RouterLink } from 'react-router-dom';

import {
	isJssField,
	JssLinkType,
	JssPhoneLinkType,
} from 'core/utils/sitecore-utils';

interface IProps {
	field: JssLinkType | JssPhoneLinkType;
	editable?: boolean;
	showLinkTextWithChildrenPresent?: boolean;
	className?: string;
	download?: boolean;
	isHrefSuppressed?: boolean;
	children?: string | React.ReactNode;
	showIconOnly?: boolean;
}

/**
 * React component that turns Sitecore link values that start with / into react-router route links.
 *
 * @note Use children to add Icon not the link text. Also, we want to separate isHrefSuppressed from
 * the rest of the props to exclude it from being pass down to the Link component
 **/
const RoutableSitecoreLink = ({ isHrefSuppressed, ...props }: IProps) => {
	const { field, editable, children, showIconOnly } = props;
	const hasValidHref = field && field.value && field.value.href;
	const isJss = isJssField(field);
	const isEditing = editable && isJss && field && field.editable;

	// only want to apply the routing link if not editing (if editing, need to render editable link value) or
	// if href is not suppressed (e.g. not disabled or onClick event is not present)
	if (hasValidHref && !isEditing && field && !isHrefSuppressed) {
		const value = field.value;

		// determine if a link is a route or not. This logic may not be appropriate for all usages.
		if (value.href.startsWith('/')) {
			return (
				<RouterLink
					to={value.href}
					title={value.title ?? value.text}
					target={value.target}
					className={value.class}
				>
					{/* When use within Cta component, the children is always the Icon component */}
					{showIconOnly ? children : value.text || value.href}
				</RouterLink>
			);
		}
	}

	return <Link {...props} />;
};

export default RoutableSitecoreLink;
