import React from 'react';
import styled from 'styled-components';
import { rotate } from 'core/styles/animations';

const Container = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Main = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	animation: ${rotate} 1.5s infinite linear;
`;

const Icon = styled.i`
	display: inline-flex;
	color: inherit;
	align-items: center;
	justify-content: center;
	transition: 0.3s all ease-in-out;
	width: 100px;
	height: 100px;

	svg {
		width: 100px;
		height: 100px;
	}
`;

export default function LoadingScreen() {
	return (
		<Container>
			<Main>
				<Icon>
					<svg viewBox="0 0 40 40">
						<title>05 Feedback indicators/Spinner</title>
						<g
							id="GL01---Error-Messages"
							stroke="none"
							stroke-width="1"
							fill="none"
							fill-rule="evenodd"
						>
							<g
								id="error-module-desk-pre-error"
								transform="translate(-436.000000, -579.000000)"
							>
								<g id="Group-5" transform="translate(0.000000, -226.000000)">
									<g id="Group" transform="translate(432.000000, 801.000000)">
										<g
											id="Icon-FeedbackIndicator"
											transform="translate(4.000000, 4.000000)"
										>
											<circle
												id="Oval"
												fill="#EBE6F6"
												cx="8"
												cy="8"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#CDC3E8"
												cx="4"
												cy="20"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#AC9BD9"
												cx="8"
												cy="32"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#8B72CA"
												cx="20"
												cy="36"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#5738B4"
												cx="32"
												cy="32"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#3F2CA5"
												cx="36"
												cy="20"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#32269D"
												cx="32"
												cy="8"
												r="4"
											></circle>
											<circle
												id="Oval"
												fill="#171C8F"
												cx="20"
												cy="4"
												r="4"
											></circle>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
				</Icon>
			</Main>
		</Container>
	);
}
