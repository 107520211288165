import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/**
 * Hook to get the latest value of a given key from url search params.
 *
 * @param key - param key
 */
export const useURLSearchParams = (
	key: string,
): [string | undefined, (arg: string) => void] => {
	const history = useHistory();
	const { search } = useLocation();
	const [value, setValue] = React.useState<string | undefined>();

	React.useEffect(() => {
		if (search) {
			const searchParams = new URLSearchParams(search);
			const paramValue = searchParams.get(key) ?? undefined;

			setValue(paramValue);
		}
	}, [search, key]);

	const updateURLSearchParam = (newValue: string) => {
		const lastParams = new URLSearchParams(search);

		lastParams.set(key, newValue);
		history.push({ search: lastParams.toString() });
	};

	return [value, updateURLSearchParam];
};
