import { isWebBrowser } from 'core/utils/global-utils';

export const getLocalStorageItem = (key: string): string => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return '';
	}

	const itemValue = window?.localStorage?.getItem(key) ?? '';

	return itemValue;
};

export const setLocalStorageItem = (key: string, itemValue: string) => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return;
	}
	window?.localStorage?.setItem(key, itemValue);
};

export const removeLocalStorageItem = (key: string) => {
	if (!isWebBrowser || !window?.localStorage) {
		// skip use of localstorage in server side rendering
		return;
	}
	window?.localStorage?.removeItem(key);
};
