import {
	getSessionCookieItem,
	setSessionCookieItem,
	removeSessionCookieItem,
} from './sessionCookie';

const ssoTokenName = 'SSOToken';

export const getSSOToken = (): string => {
	return getSessionCookieItem(ssoTokenName);
};

export const setSSOToken = (tokenValue: string) => {
	setSessionCookieItem(ssoTokenName, tokenValue);
};

export const removeSSOToken = () => {
	removeSessionCookieItem(ssoTokenName);
};
