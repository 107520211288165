import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

// Note: IsFirstSitecoreLayoutAPI is used for the "mock" loader or the LoadingLayout.
// It is used in combination with RouteHandler to see if the layout is the first Sitecore layout
// since it will trigger on every re-route.

const isFirstSitecoreLayoutAPITokenName = 'IsFirstSitecoreLayoutAPI';

export const getIsFirstSitecoreLayout = (): string => {
	return getLocalStorageItem(isFirstSitecoreLayoutAPITokenName);
};

export const setIsFirstSitecoreLayout = (tokenValue: string) => {
	setLocalStorageItem(isFirstSitecoreLayoutAPITokenName, tokenValue);
};

export const removeIsFirstSitecoreLayout = () => {
	removeLocalStorageItem(isFirstSitecoreLayoutAPITokenName);
};

// Note: IsFirstPageLoad is used for DB Skeleton Loader
// This is used inside Layout to determine when the page is loaded on
// the Layout and to start the timer.
// This is set when the user leaves from "/" route to remove the
// skeleton loader configuration and go back to its normal loading time.

const isFirstPageLoadTokenName = 'IsFirstPageLoad';

export const getIsFirstPageLoad = (): string => {
	return getLocalStorageItem(isFirstPageLoadTokenName);
};

export const setIsFirstPageLoad = (tokenValue: string) => {
	setLocalStorageItem(isFirstPageLoadTokenName, tokenValue);
};

// Note: IsSkeletonLoadDone is used for DB Skeleton Loader
// This is mainly used as a timer flag.

const isSkeletonLoadDone = 'IsSkeletonLoadDone';

export const getIsSkeletonLoadDone = (): string => {
	return getLocalStorageItem(isSkeletonLoadDone);
};

export const setIsSkeletonLoadDone = (tokenValue: string) => {
	setLocalStorageItem(isSkeletonLoadDone, tokenValue);
};
