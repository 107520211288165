import {
	getSessionCookieItem,
	setSessionCookieItem,
	removeSessionCookieItem,
} from './sessionCookie';

const refreshTokenKeyName = 'RefreshToken';

export const getRefreshToken = (): string => {
	return getSessionCookieItem(refreshTokenKeyName);
};

export const setRefreshToken = (tokenValue: string) => {
	setSessionCookieItem(refreshTokenKeyName, tokenValue);
};

export const removeRefreshToken = () => {
	removeSessionCookieItem(refreshTokenKeyName);
};
