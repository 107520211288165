export enum EVENT_TRACKERS {
	CLICK_BUTTON = 'click button',
	CLICK_LINK = 'click link',
	CLICK_INPUT = 'click input',
	VIEW = 'view',
	SCREEN_LOAD = 'screen load',
	ON_ERROR = 'on error',
	EDIT = 'edit',
	CLOSE = 'close',
	ERROR = 'error',
	REFRESH = 'refresh',
	SPOUSE_CONTRIBUTIONS = 'spouse contributions',
	RECEIVE_SPOUSE_CONTRIBUTIONS = 'receive spouse contributions',
	INPUT_NAME = 'input name',
	INPUT_MOBILE = 'input mobile',
	INPUT_EMAIL = 'input email',
	BEFORE_SHARING_TOOLTIP = 'before sharing tool tip',
	MESSAGE_OVER_75 = 'message - you are not eligible (over 75)',
	MESSAGE_NO_TFN = 'message - tfn not supplied',
	GO_TO_HOME = 'go to home',
}

export enum EVENT_SECTIONS {
	CONTRIBUTIONS = 'contributions',
}
