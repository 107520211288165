/* eslint-disable @typescript-eslint/camelcase */

import config from 'jss-boilerplate/temp/config.js';

import { GetInsurancePathParams } from 'core/api/client/InsuranceApi';
import {
	ProductNameValues,
	GetInvestmentPerformanceQueryParams,
} from 'core/api/client/ReferenceDataApi';

import api from './api';

const referenceBase = `${config.australianSuperApi?.apiDomain}${config.australianSuperApi?.apiPathReferenceData}`;

export const useGetPaymentTypes = (include?: string) => {
	const additionalParams =
		include && include.length > 0
			? {
					queryParams: { include },
			  }
			: {};

	const {
		data: paymentTypesRef,
		loading: loadingPaymentTypesRef,
		error: errorPaymentTypesRef,
		refetch: refetchPaymentTypesRef,
	} = api.useGetPaymentTypes({
		base: referenceBase,
		...additionalParams,
	});

	return {
		loadingPaymentTypesRef,
		errorPaymentTypesRef,
		refetchPaymentTypesRef,
		paymentTypesRef: paymentTypesRef?.data?.values!,
	};
};

export const useGetPaymentFrequencies = () => {
	const {
		data: paymentFrequencyRef,
		loading: loadingPaymentFrequencyRef,
		error: errorPaymentFrequencyRef,
		refetch: refetchPaymentFrequencyRef,
	} = api.useGetPaymentFrequencies({
		base: referenceBase,
		queryParams: { include: 'supplemental_data' },
	});

	return {
		loadingPaymentFrequencyRef,
		errorPaymentFrequencyRef,
		refetchPaymentFrequencyRef,
		paymentFrequencyRef: paymentFrequencyRef?.data?.values!,
	};
};

export const useGetPaymentMonths = () => {
	const {
		data: paymentMonthsRef,
		loading: loadingPaymentMonthsRef,
		error: errorPaymentMonthsRef,
		refetch: refetchPaymentMonthsRef,
	} = api.useGetPaymentMonths({
		base: referenceBase,
	});

	return {
		loadingPaymentMonthsRef,
		errorPaymentMonthsRef,
		refetchPaymentMonthsRef,
		paymentMonthsRef: paymentMonthsRef?.data?.values!,
	};
};

export const useGetPaymentDays = () => {
	const {
		data: paymentDaysRef,
		loading: loadingPaymentDaysRef,
		error: errorPaymentDaysRef,
		refetch: refetchPaymentDaysRef,
	} = api.useGetPaymentDays({
		base: referenceBase,
	});

	return {
		loadingPaymentDaysRef,
		errorPaymentDaysRef,
		refetchPaymentDaysRef,
		paymentDaysRef: paymentDaysRef?.data?.values!,
	};
};

export const useGetPaymentIndexationFactors = () => {
	const {
		data: paymentIndexationFactorsRef,
		loading: loadingPaymentIndexationFactorsRef,
		error: errorPaymentIndexationFactorsRef,
		refetch: refetchPaymentIndexationFactorsRef,
	} = api.useGetPaymentIndexationFactors({
		base: referenceBase,
	});

	return {
		loadingPaymentIndexationFactorsRef,
		errorPaymentIndexationFactorsRef,
		refetchPaymentIndexationFactorsRef,
		paymentIndexationFactorsRef: paymentIndexationFactorsRef?.data?.values!,
	};
};

export const useGetFinancialYears = (
	accountNumber?: string,
	productName?: string,
) => {
	const {
		data: financialYearsRefResponse,
		loading: isFinancialYearsRefLoading,
		error: financialYearsRefError,
		refetch: refetchFinancialYearsRef,
	} = api.useGetFinancialYears({
		base: referenceBase,
		account_number: accountNumber ?? '',
		queryParams: {
			product_name: productName ?? '',
		} as any,
	});

	return {
		financialYearsRefResponse,
		isFinancialYearsRefLoading,
		financialYearsRefError,
		refetchFinancialYearsRef,
	};
};

export const useGetInsuranceDesigns = (productName?: string) => {
	const {
		data: insuranceDesignRes,
		loading: isInsuranceDesignLoading,
		error: insuranceDesignsError,
		refetch: refetchInsuranceDesigns,
	} = api.useGetInsuranceDesigns({
		base: referenceBase,
		queryParams: {
			product_name: productName as GetInsurancePathParams['product_name'],
		},
	});

	return {
		insuranceDesignRes,
		isInsuranceDesignLoading,
		insuranceDesignsError,
		refetchInsuranceDesigns,
	};
};

export const useGetNominationTypes = () => {
	const {
		data,
		loading: loadingNominationTypesRef,
		error: errorNominationTypesRef,
		refetch: refetchNominationTypesRef,
	} = api.useGetNominationTypes({
		base: referenceBase,
	});

	return {
		loadingNominationTypesRef,
		errorNominationTypesRef,
		refetchNominationTypesRef,
		beneficiaryNominationTypesRef: data,
	};
};

export const useGetRelationshipCodes = (isEditForm?: boolean) => {
	const {
		data,
		loading: loadingRelationshipCodesRef,
		error: errorRelationshipCodesRef,
		refetch: refetchRelationshipCodesRef,
	} = api.useGetRelationshipCodes({
		base: referenceBase,
		queryParams: {
			'non-binding-is-selectable': isEditForm,
		},
	});

	return {
		loadingRelationshipCodesRef,
		errorRelationshipCodesRef,
		refetchRelationshipCodesRef,
		relationshipCodesRef: data,
	};
};

export const useGetInvestmentPerformance = (
	productName?: string,
	queryParams?: GetInvestmentPerformanceQueryParams,
) => {
	const { data, loading, error, refetch } = api.useGetInvestmentPerformance({
		base: referenceBase,
		product_name: productName as ProductNameValues,
		type: 'annual-rates',
		queryParams: queryParams,
	});

	return {
		data,
		loading,
		error,
		refetch,
	};
};
