import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import * as mx from 'core/styles/mixins';
import { IThemeObject } from 'pods/theme';
import { ICONS, cssIcon } from 'core/components/Icon';
import { Icon } from 'core/components/Icon/Icon.style';
import { DESKTOP_BP } from 'src/_AppContainer';

const BORDER_RADIUS = {
	DEFAULT: '8px',
	IN_CARD: '3px',
};

interface IProps {
	hasCloseButton?: boolean;
	type?: string;
	margin?: string;
	theme: IThemeObject;
	optionalIcon?: ICONS | string;
	hasCloseButtonLabel?: boolean;
	isAnimated?: boolean;
}

/**
 * Deterministic styles like this so that `sitecoreRteStyles` can
 * render a visual-parity version of the 'info' notification without
 * hardcoding or other work-arounds.
 */
export const notificationStyles = ({
	hasCloseButton,
	type,
	margin,
	theme,
	optionalIcon,
	hasCloseButtonLabel,
	isAnimated,
}: IProps) => css`
	display: block;
	position: relative;
	padding: 16px 24px 16px 16px;
	border: 1px solid;
	border-radius: ${BORDER_RADIUS.DEFAULT};

	${mx.bp(DESKTOP_BP)`
		padding-top: 20px;
		padding-bottom: 20px;
	`}

	/* No border, modified border radius, when within a Card component */
	.core-component--card & {
		border: none;
		border-radius: ${BORDER_RADIUS.IN_CARD};
	}

	/* Conditional padding to accommodate close button */
	${hasCloseButton &&
		css`
			padding-right: 56px;
		`}

	/* Padding to accommodate icon */
	padding-left: 60px;

	${margin &&
		css`
			margin: ${margin};
		`}

	${hasCloseButtonLabel &&
		css`
			.typography--bodySmall:first-of-type {
				color: ${theme.global.typography.colors.genericDark};
				margin-top: 0;
				padding: 0 4px 0 0;
			}
		`}

	${() => {
		let icon;
		let color;
		let background;

		const themeColours = theme.global.notifications;

		const ERROR: any = [ICONS.CANCEL, themeColours.error];
		const SUCCESS: any = [ICONS.CHECK_CIRCLE, themeColours.success];
		const WARNING: any = [ICONS.WARNING, themeColours.warning];
		const INFO: any = [ICONS.INFO, themeColours.info];
		const LOADING: any = [ICONS.LOADING, themeColours.loading];

		switch (type) {
			case 'error':
				icon = ERROR[0];
				color = ERROR[1].color;
				background = ERROR[1].background;
				break;
			case 'success':
				icon = SUCCESS[0];
				color = SUCCESS[1];
				break;
			case 'warning':
				icon = WARNING[0];
				color = WARNING[1].color;
				background = WARNING[1].background;
				break;
			case 'loading':
				icon = LOADING[0];
				color = LOADING[1].color;
				background = LOADING[1].background;
				break;
			case 'info':
			default:
				icon = INFO[0];
				color = INFO[1].color;
				background = INFO[1].background;
		}

		icon = optionalIcon ?? icon;

		return css`
			background-color: ${background || rgba(color, 0.06)};
			border-color: ${background || rgba(color, 0.06)};

			/* Left border */
			&::before {
				${mx.pseudo}
				background-color: ${color};
				height: 100%;
				left: 0;
				top: 0;
				width: 4px;
				border-radius: ${BORDER_RADIUS.DEFAULT} 0 0 ${BORDER_RADIUS.DEFAULT};

				.core-component--card & {
					border-radius: ${BORDER_RADIUS.IN_CARD} 0 0 ${BORDER_RADIUS.IN_CARD};
				}
			}

			/* Icon */
			&::after {
				${mx.pseudo}
				${mx.square('26px')}
				${mx.alignVerticalCentre}
				left: 24px;
				background: ${cssIcon(icon as ICONS, color)};
				${isAnimated &&
					css`
						top: 26px;
						animation: rotate 2s linear alternate infinite;
					`}
			}

			@keyframes rotate {
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(180deg);
				}
			}
		`;
	}}
`;

export const Wrapper = styled.div<IProps>`
	${props => notificationStyles(props)}
`;

export const CloseButton = styled.button<{
	hasCloseButtonLabel: boolean;
	moveToTop: boolean;
}>`

${mx.square('45px')}

	${({ hasCloseButtonLabel }) =>
		hasCloseButtonLabel &&
		css`
			${mx.square('85px')};

			.typography--caption:first-of-type {
				margin-top: 10px;
			}

			.typography--body {
				margin-bottom: 0;
			}
		`}


	${mx.buttonReset}
	${mx.alignVerticalCentre}
	position: absolute;
	right: 10px;
	cursor: pointer;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding-top: 12px;
	${({ moveToTop }) =>
		moveToTop &&
		css`
			padding-top: 0;
		`}

	&:hover,
	&:focus {
		&:not(:active) {
			${Icon} {
				transform: scale(1.2);
			}
		}
	}

	${Icon} {
		${({ theme }) => css`
			transform: scale(1);
			transition: transform ${theme.variables.baseTransition};
			color: ${theme.global.typography.colors.active};
		`}
	}
`;
