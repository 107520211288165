/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/camelcase */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';

import { useGetPaymentSummary } from 'core/api/client/PaymentApi';

import GetPaymentSummary from './mocks/GetPaymentSummary.mock.json';
import GetPaymentSummaryNoPayments from './mocks/GetPaymentSummaryNoPayments.mock.json';
import GetPaymentSummaryNoLastPayment from './mocks/GetPaymentSummaryNoLastPayment.mock.json';
import GetPaymentSummaryNoUpcomingPayment from './mocks/GetPaymentSummaryNoUpcomingPayment.mock.json';

export default smartMockApi({
	useGetPaymentSummary: {
		live: useGetPaymentSummary,
		mock: ({ account_number }: any) => {
			let payload;

			switch (account_number) {
				case '1':
					payload = GetPaymentSummary;
					break;
				case '2':
					payload = GetPaymentSummaryNoLastPayment;
					break;
				case '3':
					payload = GetPaymentSummaryNoUpcomingPayment;
					break;
				case '4':
					payload = GetPaymentSummaryNoPayments;
					break;
				default:
					payload = GetPaymentSummary;
			}

			return useGetMock(
				{ payload },
				account_number === ''
					? () => {
							throw new Error();
					  }
					: undefined,
			);
		},
	},
});
