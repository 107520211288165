import { useState, useEffect } from 'react';
import { UseGetReturn, UseMutateReturn } from 'restful-react';
import { isStorybook } from 'stories/utils';

export const DEFAULT_MOCK_RESPONSE = isStorybook() ? 0 : 2000; // Ms

// ------------------------------------

const [VARIABLE_MIN, VARIABLE_MAX] = [500, 1500];
const VARIABLE_ROUNDTO = 100;

/**
 * Additional random response time in addition to the base
 * mock response time `DEFAULT_MOCK_RESPONSE`. This is so
 * adjacent loading modules don't all resolve at the same time
 * in mock scenarios (Storybook, Experience Editor) - which looks unnatural.
 */
const getVariableResponseTimeMock = (): number =>
	Math.round(
		(Math.random() * (VARIABLE_MAX - VARIABLE_MIN + 1) + VARIABLE_MIN) /
			VARIABLE_ROUNDTO,
	) * VARIABLE_ROUNDTO;

// ------------------------------------
interface IMockPostProps {
	/** Mock response */
	payload?: object;
	/** Mock error object */
	error?: {
		message: string;
		data: any | null;
	};
	hasError?: boolean;
}

interface IMockProps {
	/** Mock response */
	payload: object;
	/** Mock error object */
	error?: {
		message: string;
		data: any | null;
	};
	/** Is lazy fetching, from the useGet props */
	lazy?: boolean;
	/** Resolve callback, from the useGet props */
	resolve?: (data: any) => any;
	/** Request params, from the useGet props */
	queryParams?: object;
	/** Simulate error for GET method */
	hasError?: boolean;
}

/**
 * Emulates `restful-react`'s `useGet` functionality, by allowing
 *   mock payloads and errors to be passed through.
 *
 * @param responseGenerator - optional generate mock response callback to mock different response based on params, etc.
 */
export const useGetMock = (
	{
		error = {
			message: 'Error: Simulated mock error',
			data: null,
		},
		payload,
		resolve,
		lazy = false,
		queryParams = {},
		hasError,
		...rest
	}: IMockProps,
	responseGenerator: ((props: object) => object) | null = null,
): UseGetReturn<object, object> => {
	const [isLoading, setIsLoading] = useState(!lazy);
	const [isError, setIsError] = useState(false);
	const [data, setData] = useState(payload);

	/**
	 * Mock the refetch method from the Restful-React useGet hook.
	 *
	 * @param props             - useGet props
	 * @param props.queryParams - Request params
	 */
	const refetch = (props: { queryParams?: any } = {}): Promise<void> => {
		setIsLoading(true);
		setIsError(false);
		try {
			// If hasError is true, throw an error and let the catch block handle error scenario
			if (hasError) {
				throw error;
			}

			let resData = payload;

			if (typeof responseGenerator === 'function') {
				// Override response data with the value from responseGenerator
				resData = responseGenerator({ ...rest, ...props });
				setData(resData);
			}

			return new Promise(res => {
				setTimeout(
					() => {
						if (typeof resolve === 'function') {
							resolve(resData);
						}
						setIsLoading(false);
						setIsError(false);
						res();
					},
					// NOTE: if API driven module loading state is finished after EE initialized,
					//   JSS text won't registered correctly to allow CAs to edit therefore
					//   remove the delay in the Sitecore Experience editor env.
					isStorybook() ? getVariableResponseTimeMock() : undefined,
				);
			});
		} catch {
			return new Promise((_, rej) => {
				setTimeout(() => {
					setIsLoading(false);
					setIsError(true);
					rej();
				}, getVariableResponseTimeMock());
			});
		}
	};

	// Emulate `componentDidMount` - init fetch/load on mount
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		// Do not fetch on component mount if is is lazy fetching
		!lazy && refetch({ queryParams, ...rest });
	}, [lazy]);
	/* eslint-enable react-hooks/exhaustive-deps */

	return {
		data: !isLoading && !isError ? data : null,
		loading: isLoading,
		error: isError ? error : null,
		absolutePath: '',
		response: null,
		cancel: () => {
			setIsLoading(false);
			setIsError(false);
		},
		refetch,
	};
};

export const usePostMock = ({
	payload,
	error = {
		message: 'Error: Simulated mock error',
		data: null,
	},
	hasError,
}: IMockPostProps): UseMutateReturn<object, object, object, object, object> => {
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const cancel = () => {
		setIsLoading(false);
		setIsError(false);
	};
	const mutate = (): Promise<any> => {
		setIsLoading(true);

		try {
			// If hasError is true, throw an error and let the catch block handle error scenario
			if (hasError) {
				throw error;
			}

			return new Promise(res => {
				setTimeout(() => {
					setIsLoading(false);
					setIsError(false);
					res(payload);
				}, getVariableResponseTimeMock());
			});
		} catch (error) {
			return new Promise((_, rej) => {
				setTimeout(() => {
					setIsLoading(false);
					setIsError(true);
					rej(error);
				}, getVariableResponseTimeMock());
			});
		}
	};

	return {
		mutate,
		cancel,
		loading: isLoading,
		error: isError ? error : null,
	};
};
