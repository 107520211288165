import range from 'lodash/range';

/**
 * Get page numbers that should be displayed in the Pagination component.
 *
 * @description At maximum five numbers of pagination items to display after reach the threshold,
 *    including the current page number, start page, end page, and most adjacent page numbers.
 * @param current   - the current number that is active
 * @param total     - total number of pages
 * @param threshold - maximum number of the page that could be displayed without shortened
 * @return {number[]} - page numbers to display
 * @example
 * // total=10, current=1
 * [1,2,3,4,10]
 * // total=10, current=4
 * [1,3,4,5,10]
 * // total=10, current=5, threshold = 10
 * [1,2,3,4,5,6,7,8,9,10]
 */
export const getDisplayedPageNumbers = (
	current: number,
	total: number,
	threshold = 5,
) => {
	if (total <= threshold) {
		return range(1, total + 1);
	}

	// edge case when current is near the start
	if (current <= 3) {
		return [1, 2, 3, 4, total];
	}

	// edge case when current is near the end
	if (current >= total - 2) {
		return [1, ...range(total - 3, total + 1)];
	}

	return [1, current - 1, current, current + 1, total];
};
