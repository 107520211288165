/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/camelcase */

import { useGetMock } from 'core/api/mocks';
import { smartMockApi } from 'core/api';

import {
	useGetPaymentTypes,
	useGetPaymentFrequencies,
	useGetPaymentIndexationFactors,
	useGetPaymentMonths,
	useGetPaymentDays,
	useGetFinancialYears,
	useGetInsuranceDesigns,
	useGetNominationTypes,
	useGetRelationshipCodes,
	useGetInvestmentPerformance,
	useGetSuperFunds,
} from 'core/api/client/ReferenceDataApi';

import GetPaymentTypesReference from './mocks/GetPaymentTypesReference.mock.json';
import GetPaymentFrequencyReference from './mocks/GetPaymentFrequencyReference.mock.json';
import GetPaymentMonthsReference from './mocks/GetPaymentMonthsReference.mock.json';
import GetPaymentDaysReference from './mocks/GetPaymentDaysReference.mock.json';
import GetIndexationFactorsReference from './mocks/GetIndexationFactorsReference.mock.json';
import GetFinancialYearsListReference from './mocks/GetFinancialYearsListReference.mock.json';
import GetInsuranceDesignsReference from './mocks/GetInsuranceDesignsReference.mock.json';
import GetBeneficiaryNominationTypes from './mocks/GetBeneficiaryNominationTypes.mock.json';
import GetRelationshipCodes from './mocks/GetRelationshipCodes.mock.json';
import GetInvestmentPerformanceMock from './mocks/GetInvestmentPerformance.mock.json';
import GetSuperFundsApra from './mocks/GetSuperFundsApra.mock.json';

export default smartMockApi({
	useGetPaymentTypes: {
		live: useGetPaymentTypes,
		mock: () => useGetMock({ payload: GetPaymentTypesReference }),
	},
	useGetPaymentFrequencies: {
		live: useGetPaymentFrequencies,
		mock: () => useGetMock({ payload: GetPaymentFrequencyReference }),
	},
	useGetPaymentMonths: {
		live: useGetPaymentMonths,
		mock: () => useGetMock({ payload: GetPaymentMonthsReference }),
	},
	useGetPaymentDays: {
		live: useGetPaymentDays,
		mock: () => useGetMock({ payload: GetPaymentDaysReference }),
	},
	useGetPaymentIndexationFactors: {
		live: useGetPaymentIndexationFactors,
		mock: () => useGetMock({ payload: GetIndexationFactorsReference }),
	},
	useGetFinancialYears: {
		live: useGetFinancialYears,
		mock: () => useGetMock({ payload: GetFinancialYearsListReference }),
	},
	useGetInsuranceDesigns: {
		live: useGetInsuranceDesigns,
		mock: () => useGetMock({ payload: GetInsuranceDesignsReference }),
	},
	useGetNominationTypes: {
		live: useGetNominationTypes,
		mock: () => useGetMock({ payload: GetBeneficiaryNominationTypes }),
	},
	useGetRelationshipCodes: {
		live: useGetRelationshipCodes,
		mock: () => useGetMock({ payload: GetRelationshipCodes }),
	},
	useGetInvestmentPerformance: {
		live: useGetInvestmentPerformance,
		mock: () => useGetMock({ payload: GetInvestmentPerformanceMock }),
	},
	useGetSuperFunds: {
		live: useGetSuperFunds,
		mock: () => useGetMock({ payload: GetSuperFundsApra }),
	},
});
