import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const selectedAccountBalanceKeyName = 'SelectedAccountBalance';

export const getSelectedAccountBalance = (): string | null => {
	const selectedAccountBalance = getLocalStorageItem(
		selectedAccountBalanceKeyName,
	);

	return selectedAccountBalance === '' ||
		typeof selectedAccountBalance === 'undefined'
		? '0'
		: selectedAccountBalance;
};

export const setSelectedAccountBalance = (tokenValue: string) => {
	setLocalStorageItem(selectedAccountBalanceKeyName, tokenValue);
};

export const removeSelectedAccountBalance = () => {
	removeLocalStorageItem(selectedAccountBalanceKeyName);
};
