/* eslint-disable @typescript-eslint/camelcase */

import React from 'react';

import { trackEvent as _trackEvent, ITrackEventProps } from 'core/analytics';
import { useMemberContext } from 'core/sitecore/member';
import { getMemberTrackingProps } from 'core/sitecore/member/utility';

/**
 * A hook to track the Goggle Analytic events in module.
 *
 * @note Provide all event props of the screen load event (if required) in the `baseEventProps`.
 * @param baseEventProps        - base props of the track events
 * @param shouldTrackScreenLoad - track the screen load event
 */
export const useTrackEvent = (
	baseEventProps: Omit<ITrackEventProps, 'event_trigger' | 'event_label'> &
		Partial<Pick<ITrackEventProps, 'event_trigger' | 'event_label'>>,
	shouldTrackScreenLoad = true,
) => {
	const memberData = useMemberContext();
	const memberTrackingProps = getMemberTrackingProps(memberData);

	// a hack to keep the object reference unchanged on each re-renders
	const trackEventProps = React.useRef(baseEventProps);
	const trackMemberProps = React.useRef(memberTrackingProps);

	React.useEffect(() => {
		if (shouldTrackScreenLoad) {
			// track the screen load event
			_trackEvent({
				event_trigger: 'screen load',
				event_label: 'view',
				...trackMemberProps.current,
				...trackEventProps.current,
			});
		}
	}, [shouldTrackScreenLoad]);

	/**
	 * Track event method with additional event props to add/override the base props provide to the hook.
	 */
	const trackEvent = (additionalEventProps: Partial<ITrackEventProps>) =>
		_trackEvent({
			...trackMemberProps.current,
			...trackEventProps.current,
			...(additionalEventProps as ITrackEventProps),
		});

	return { trackEvent };
};
