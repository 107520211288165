import scBp from 'styled-components-breakpoint';
import { resolveBp } from '@deloitte-digital-au/dd-breakpoint-container';
import { css } from 'styled-components';

import * as vr from 'core/styles/variables';

// ------------------------------------
// BREAKPOINTS

// for js-driven media-queries, so we have query parity
// for `bp` export; currently `bp` limited to mobile-first only queries
export const bp = query => scBp(query);
export const bpc = (query, bp) => (...args) =>
	resolveBp(query, bp) && css(...args);

// ------------------------------------
// UTILS

export const keyboardOnly = styles => css`
	[data-whatinput='keyboard'] & {
		${styles}
	}
`;

// ------------------------------------
// ELLIPSIS

export const ellipsis = css`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

// ------------------------------------
// FORM (CHECKBOX)

export const tick = css`
	width: 6px;
	height: 11px;
	border-right: 2px solid ${({ theme }) => theme.global.form.radio.active};
	border-bottom: 2px solid ${({ theme }) => theme.global.form.radio.active};
	transform: rotate(45deg);
`;

// ------------------------------------
// SIZE SHORTCUTS

export const square = size => css`
	width: ${size};
	height: ${size};
`;

export const rectangle = (w, h) => css`
	width: ${w};
	height: ${h};
`;

export const circle = size => css`
	${square(size)}
	border-radius: 50%;
`;

// ------------------------------------
// ACCESSIBILITY

// Passable mimic of default browser focus styles, to apply to non-anchor elements
// Ref: https://ghinda.net/article/mimic-native-focus-css/
export const mimicFocus = css`
	${keyboardOnly`
		outline: 5px auto Highlight;

		/* WebKit gets its native focus styles. */
		@media (-webkit-min-device-pixel-ratio: 0) {
			outline-color: -webkit-focus-ring-color;
			outline-style: auto;
		}
	`}
`;

// Display outline when tab focus only
export const tabFocus = css`
	/* Remove outline when focus by click,  */
	&:focus,
	& > *:focus {
		outline: none;
	}

	&:focus > * {
		${mimicFocus}
	}
`;

// ------------------------------------
// PSEUDO

export const pseudo = css`
	content: '';
	position: absolute;
`;

// ------------------------------------
// VISUALLY HIDDEN

export const visuallyhidden = css`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	white-space: nowrap;
`;

export const undovisuallyhidden = css`
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
	white-space: inherit;
`;

// ------------------------------------
// RESETS

export const buttonReset = css`
	appearance: none;
	border: none;
	background-color: transparent;
	padding: 0;
`;

export const listReset = css`
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		padding: 0;

		/* Undo manual list markers set by global list styles */
		&::before {
			content: unset;
		}
	}
`;

// ------------------------------------
// ABSOLUTE CENTRE POSITIONING

export const alignVerticalCentre = css`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
`;

export const alignHorizontalCentre = css`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
`;

export const alignCentre = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

// ------------------------------------
// POSITION (Z-INDEX)

/**
 * Mixin for setting z-index.
 *
 * @note Separating 'bottom', 'middle' and 'top' by increments of 100 to leave rooms for adjustment.
 * @param {'middle'|'top'} position - Position along z-axis, 'top' will give 200 as base z-index, 'middle' for 100, by default 'bottom' will yield 0.
 * @param {number} firstAdjustment  - Adjustment for stacking between the three basic position, will increment the z-index by 10*firstAdjustment.
 * @param {number} secondAdjustment - Adjustment for further stacking between the adjusted position, will increment the z-index by the number of secondAdjustment.
 * @example
 * zIndex('middle', 2, 1) // z-index: 121
 * zIndex('value other than top and middle') // z-index: 0
 */
export const zIndex = (
	position = 'bottom',
	firstAdjustment = 0,
	secondAdjustment = 0,
) => {
	let base;

	switch (position) {
		case 'middle':
			base = vr.zIndexBase.middle;
			break;
		case 'top':
			base = vr.zIndexBase.top;
			break;
		default:
			base = vr.zIndexBase.bottom;
			break;
	}

	return css`
		z-index: ${base + firstAdjustment * 10 + secondAdjustment};
	`;
};
