import isArray from 'lodash/isArray';
import queryString from 'query-string';
import deepMerge from 'deepmerge';
import { BREAKPOINTS } from '@deloitte-digital-au/dd-breakpoint-container';

import { localTheme, getThemeHelpers } from 'pods/theme';

import {
	PAYMENTS_PRODUCT_NAME,
	SITECORE_PAGE_MODE,
} from '../constants/constants';

/**
 * @param {Function} cb - Callback.
 * @returns {boolean}
 */
export const callbackExists = cb => !!cb && typeof cb === 'function';

/**
 * Safely executes a callback function.
 *
 * @param {Function} cb - Callback.
 * @param {Object} params - Callback parameters.
 * @returns {Function|null}
 */
export const executeCallback = (cb, ...params) =>
	callbackExists(cb) ? cb(...params) : null;

/**
 * Takes a number in bytes and returns a formatted string in KB, MB, etc as relevant.
 * REF: Correct way to convert size in bytes to KB, MB, GB in JavaScript
 * (https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript).
 *
 * @param {number} fileSize
 * @returns {string}
 */
export function formatBytes(fileSize) {
	if (typeof fileSize !== 'number' || fileSize === 0) return '0 Bytes';

	const k = 1024;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

	const i = Math.floor(Math.log(fileSize) / Math.log(k));

	return `${parseFloat((fileSize / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
}

/**
 * Site-wide currency converter instance.
 * `Intl.NumberFormat` configured for `en-AU` and `AUD` currency.
 * @param {Intl.NumberFormatOptions} options
 */
export const setCurrencyFormatter = (options = {}) =>
	new Intl.NumberFormat('en-AU', {
		style: 'currency',
		currency: 'AUD',
		...options,
	});

/**
 * Converts a number into a formatted currency string.
 *
 * @param {number | string} amount
 * @param {Intl.NumberFormatOptions} options
 * @returns {string} Formatted currency string.
 */
export const formatCurrency = (amount, options = {}) => {
	const currencyFormatter = setCurrencyFormatter(options);
	// TODO: Revisit this method, this method needs to return empty string, zero (0) or null
	// if it receives unsupported data types as argument. After implementing that,
	// need to make sure that there is no grave impacts to other components or services that
	// utilizes this method.

	return currencyFormatter.format(parseFloat(amount));
};

/**
 * Format value/amount for positive/negative
 *
 * @param {val} num
 * @returns {obj} {type, amount}
 * type {string} to get if it's positive, negative or default value
 * amount - value without the special char
 */
export const isPositiveOrNegativeValue = val => {
	const isPositive = Math.sign(val || 0);
	const amount = formatCurrency(Math.abs(val).toFixed(2));

	return {
		type:
			isPositive === 1 ? 'positive' : isPositive === 0 ? 'default' : 'negative',
		amount,
	};
};

/**
 * Formats number to currency, but strips-out and returns
 * currency symbol separately for cleaner rendering.
 *
 * @param {Intl.NumberFormatOptions} options
 * @param {number} num - Number to be formatted.
 * @returns {Object} Object containing formatted currency string, and associated currency symbol.
 */
export const getCurrencyFormat = (num, options = {}) => {
	const currencyFormatter = setCurrencyFormatter(options);
	// Converts number to formatted currency
	let currencyString = formatCurrency(num, options);
	// Separates formatted string into its distinct parts
	const currencyFormatParts = currencyFormatter.formatToParts(currencyString);
	// Extracts the currency symbol (e.g. '$')
	let currencySymbol = currencyFormatParts.find(obj => obj.type === 'currency');
	currencySymbol = currencySymbol?.value;

	// Removes the currency symbol from the original string
	// (as it will be rendered separately alongside the currencyString)
	currencyString = currencyString.replace(currencySymbol, '');

	return {
		amount: currencyString,
		symbol: currencySymbol,
	};
};

/**
 * Formats a number into a percentage with N number decimal points.
 *
 * @param {number} percentage - The number to  be converted.
 * @param {number}  decimalPoints - The number of decimal points to be included in percentage. Default is 2.
 * @returns {string} Formatted percentage string.
 */

export const formatPercentage = (percentage, decimalPoints = 2) => {
	return typeof percentage === 'number'
		? `${percentage.toFixed(decimalPoints)}%`
		: '';
};

/**
 * Runs true if running inside web browser instead of nodejs server
 */
export const isWebBrowser = typeof window !== 'undefined';

/**
 * Add an ordinal suffix to a number
 * Source: https://bit.ly/2neWfJ2
 *
 * @param {number | string} num - The number or string where ordinal should be base. Default is 1.
 * @returns {string} Formatted string with ordinal suffix. e.g. 1st
 */

export const toOrdinalSuffix = (num = 1) => {
	const int = parseInt(num),
		digits = [int % 10, int % 100],
		ordinals = ['st', 'nd', 'rd', 'th'],
		oPattern = [1, 2, 3, 4],
		tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];

	return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
		? int + ordinals[digits[0] - 1]
		: int + ordinals[3];
};

/**
 * Replace character(s) on a string within a specific position
 *
 * @param {number} position - The number of characters to be replace. Default is 0
 * @param {string} stringValue - The raw string.
 * @param {char} mask - The new character to take in place on the raw string. Default is '*'
 * @returns {string} Formatted mask string. e.g. ****456.
 */

export const formatStringToMask = (
	position = 0,
	stringValue = '',
	mask = '*',
) => {
	const maskToUse = mask.repeat(position);

	return stringValue.replace(new RegExp(`^.{${position}}`, 'g'), maskToUse);
};

/**
 * Get the description of the given code from the reference data array.
 *
 * @param {string} code - given code that should be used to find the reference description
 * @param {{ code: string, description: string }[]} referenceData - a list of reference data
 */
export const mapCodeToDescription = (code, referenceData = []) => {
	if (!isArray(referenceData)) return '';

	return (
		referenceData.find(data => data.code.toUpperCase() === code?.toUpperCase())
			?.description ?? ''
	);
};

/**
 * Return masked email
 *
 * @param {string} email - given email to mask
 */
export const maskEmail = email => {
	const maskedEmail = email.replace(
		/^(.)(.*)(.@.*)$/,
		(_, a, b, c) => a + b.replace(/./g, '*') + c,
	);

	return maskedEmail;
};

/**
 * Return masked mobile number
 *
 * @param {string} mobile number with no spaces - given mobile number to mask
 */
export const maskMobile = mobile => {
	const maskedMobile = mobile
		.replace('+61', '0')
		.replace(/ /g, '')
		.replace(/\d(?=\d{3})/g, '*');

	const x = maskedMobile.match(/(\S{0,4})(\S{0,3})(\S{0,6})/);

	const formattedMaskedMobile = `${x[1]} ${x[2]} ${x[3]}`;

	return formattedMaskedMobile;
};

/**
 * Return masked password (whole string)
 *
 * @param {string} password to mask
 */
export const maskPassword = password => {
	return password.replace(/./g, '·');
};

/**
 * Checks if input was email (note: this is just a flag, not for validation)
 *
 * @param {string} value to see if email
 */
export const checkIfEmailFormat = formValue => {
	const regex = new RegExp(
		/^(?!.{121})([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
	);

	return regex.test(formValue);
};

/**
 * Parse JSON safely
 *
 * @param {string} str
 */
export const parseJSONSafely = str => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return {};
	}
};

/**
 * This method returns the product classification label from Member Context Data.
 * @param {string} productClassification - product classification from API.
 * @returns {string} - This returns a string relevant to the product classification.
 */
export const getProductClassificationLabel = productClassification => {
	// NOTE: Labels here should come from sitecore field EA05,
	// but right now the field is limited to one value that is why it is being hard coded.
	const transformClassication =
		productClassification === 'Choice Income'
			? 'Pension'
			: productClassification;

	const pcLabels = {
		accumulation: 'Super',
		pension: 'Choice Income',
		ttr: 'TTR Income',
	};

	return pcLabels[transformClassication.toLowerCase()] ?? '';
};

/**
 * This method returns true if local storage key is invalid.
 * @param {string} localStorageKey - product classification from API.
 */
export const isInvalidLocalStorage = localStorageKey => {
	return (
		typeof localStorageKey === 'undefined' ||
		!localStorageKey ||
		localStorageKey === '' ||
		localStorageKey === null
	);
};

/**
 * @description Generic recursive function to get all value via key in an object
 * @param {Object} obj - object to be searched
 * @param {string} keyToFind - name of the key
 * @returns {Array} - contains all values with key equal to `keyToFind` from the object `obj`
 *
 * Source: https://stackoverflow.com/questions/54857222/find-all-values-by-specific-key-in-a-deep-nested-object
 */
export const findAllByKey = (obj, keyToFind) => {
	return Object.entries(obj).reduce(
		(acc, [key, value]) =>
			key === keyToFind
				? acc.concat(value)
				: typeof value === 'object'
				? acc.concat(findAllByKey(value, keyToFind))
				: acc,
		[],
	);
};

/**
 * Check if windows property exist and determine current URL host name
 * This method is made to get current host name instead of the environment variable layoutServiceHost
 */
export const getCurrentUrlHostname =
	typeof window !== 'undefined'
		? `https://${window?.location?.hostname}`
		: 'https://xp0.sc';

/**
 * This method returns 'accum' or 'choice income' depending on what productName is passed
 */
export const getProductNameGAType = productName => {
	return productName === PAYMENTS_PRODUCT_NAME.ACCUMULATION
		? 'accum'
		: 'choice income';
};

/**
 * This method returns a boolean if sitecore experience editor is in edit or preview mode
 */
export const isInSitecorePreviewOrEditMode = () => {
	// get the url from current tab
	const windowsUrl = isWebBrowser ? window.location.search : '';
	// parse the query params from the url
	const urlParamsObj = queryString.parse(windowsUrl);
	// get param value of sc_mode
	const myParam = urlParamsObj?.sc_mode;
	// urlParams.get('sc_mode');

	if (!myParam) {
		return false;
	}

	// check whether its within sitecore experience editor scope
	return SITECORE_PAGE_MODE.includes(myParam.toLowerCase()) ? true : false;
};

/**
 * This method is a mock from the _AppContainer on getting the theme.
 * This is used for unit test files that needs ThemeProvider
 */
export const getThemeProviderObject = () => {
	const THEME_NAME = 'theme-light';
	const appThemeObject = localTheme;
	const activeTheme =
		appThemeObject.themes.find(themeObj => themeObj.id === THEME_NAME) ?? {};

	activeTheme.global = deepMerge(
		appThemeObject.global,
		activeTheme.global || {},
	);
	activeTheme.variables = deepMerge(
		appThemeObject.variables,
		activeTheme.variables || {},
	);

	const themeHelpers = getThemeHelpers(activeTheme);

	const theme = {
		...activeTheme,
		...themeHelpers,
		// `styled-components-breakpoint` package settings
		breakpoints: BREAKPOINTS,
	};

	return theme;
};
