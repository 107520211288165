/**
 * @description An array containing all DB components that have API calls
 */
export const DbAPIComponents = {
	DbAccountSnapshot: 'DbAccountSnapshot',
	DbBeneficiaries: 'DbBeneficiaries',
	DbBPAY: 'DbBPAY',
	DbFinancialAdviserModule: 'DbFinancialAdviserModule',
	DbHeroBanner: 'DbHeroBanner',
	DbInsuranceCover: 'DbInsuranceCover',
	DbInvestments: 'DbInvestments',
	DbLatestContributionModule: 'DbLatestContributionModule',
	DbPaymentInformation: 'DbPaymentInformation',
};
