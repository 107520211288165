import {
	getLocalStorageItem,
	setLocalStorageItem,
	removeLocalStorageItem,
} from './localStorage';

const usernameKeyName = 'Username';

export const getUsername = (): string => {
	return getLocalStorageItem(usernameKeyName);
};

export const setUsername = (tokenValue: string) => {
	setLocalStorageItem(usernameKeyName, tokenValue);
};

export const removeUsername = () => {
	removeLocalStorageItem(usernameKeyName);
};
