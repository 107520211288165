import { isWebBrowser } from 'core/utils/global-utils';

export const getSessionStorageItem = (key: string): string => {
	if (!isWebBrowser || !window?.sessionStorage) {
		// skip use of sessionStorage in server side rendering
		return '';
	}

	const itemValue = window?.sessionStorage?.getItem(key) ?? '';

	return itemValue;
};

export const setSessionStorageItem = (key: string, itemValue: string) => {
	if (!isWebBrowser || !window?.sessionStorage) {
		// skip use of sessionStorage in server side rendering
		return;
	}
	window?.sessionStorage?.setItem(key, itemValue);
};

export const removeSessionStorageItem = (key: string) => {
	if (!isWebBrowser || !window?.sessionStorage) {
		// skip use of sessionStorage in server side rendering
		return;
	}
	window?.sessionStorage?.removeItem(key);
};
