import React from 'react';

/**
 * A hook for handle the toggle of multiple elements by its Ids.
 */
export const useToggleById = (
	initialValue = '',
): [string, React.Dispatch<string>] => {
	const [currentId, setCurrentId] = React.useState(initialValue);

	const handleToggle = (targetId = '') =>
		// Reset the currentId when the targetId is the same as current, i.e toggle off
		targetId === currentId ? setCurrentId('') : setCurrentId(targetId);

	return [currentId, handleToggle];
};
